import axios from 'axios';
import {
  GET_HRDASHBOARD_FAILURE,
  GET_HRDASHBOARD_LOADING,
  GET_HRDASHBOARD_SUCCESS,
  GET_REPORT_LOADING,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  GET_SEARCH_REPORT_LOADING,
  GET_SEARCH_REPORT_FAILURE,
  GET_SEARCH_REPORT_SUCCESS,
  RESET_REPORT
} from '../actions/actions';

const initialState = {
  councilMap: [],
  loading: false,
  error: '',
  success: false,
  reportData: null,
  results: {},
  searchResults: {}

  // totalCounts: { totalEc: 0, totalDc: 0, totalEmp: 0 }, // Add this line

  // data : null
};

const hrDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HRDASHBOARD_LOADING:
      return { ...state, loading: true, success: false };
    case GET_HRDASHBOARD_SUCCESS:
      return { ...state, councilMap: action.payload.details, success: true, loading: false };
    case GET_HRDASHBOARD_FAILURE:
      return { ...state, loading: false, success: false, error: action.payload.error };

    // case GET_REPORT_LOADING:
    //   return { ...state, loading: true, success: false, error: '' };
    // case GET_REPORT_SUCCESS:
    //   return { ...state, loading: false, success: true, reportData: action.payload.details };
    // case GET_REPORT_FAILURE:
    //   return { ...state, loading: false, success: false, error: action.payload.error };

    case GET_REPORT_LOADING:
      return { ...state, loading: true, error: null };
    case GET_REPORT_SUCCESS:
      return { ...state, loading: false, results: action.payload.details };
    case GET_REPORT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case GET_SEARCH_REPORT_LOADING:
      return { ...state, loading: true, error: null };
    case GET_SEARCH_REPORT_SUCCESS:
      // console.log('DATA ADDED');
      return { ...state, loading: false, searchResults: action.payload.details };
    case GET_SEARCH_REPORT_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case RESET_REPORT:
      // console.log('RESET done');
      return {
        ...state,
        loading:false,
        searchResults:{}
        // initialState
      };

    default:
      return state;
  }
};

export default hrDashboardReducer;
