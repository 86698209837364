import React, { useContext, useEffect, useState } from 'react';

//mui
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  InputAdornment,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//assests
import filterIcon from '../../assets/icons/filterIconMarket.svg';
import filterSearchIcon from '../../assets/icons/filterSearchIcon.svg';

//scss
import s from './filter.module.scss';
import { MyContext } from '../../context/MyContext';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import DateFnsUtils from '@date-io/date-fns';

const CarPoolFilter = ({
  isMobile,
  userData,
  setFilteredData,
  page,
  value,
  destLocationsData,
  deptLocationsData,
  finalData,
  filterData,
  setCurrentIndex,
  selectedSortOption
}) => {
  const { marketplaceCarPoolFilterBody, setMarketplaceCarPoolFilterBody } = useContext(MyContext);
  const [priceRange, setPriceRange] = React.useState([0, 10000]);
  const [selectedChipsProperty, setSelectedChipsProperty] = React.useState([]);
  const [totalNumberPassenger, settotalNumberPassenger] = React.useState([]);
  const [minPrice, setMinPrice] = React.useState(priceRange[0]);
  const [maxPrice, setMaxPrice] = React.useState(priceRange[1]);
  const [status, setStatus] = useState('active');
  const [searchMode, setSearchMode] = useState(false);
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const handleDateChange = (e) => {
    const date = e.target.value;
    // setSelectedDate(date);
    if ((date < getTodayISOString() || date > getMaxDateISOString()) && date !== '') {
      setSelectedDate(getTodayISOString());
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        preferredDepartDate: getTodayISOString()
      });
    } else {
      setSelectedDate(date);
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        preferredDepartDate: date
      });
    }
    setCalendarOpen(false);
  };
  const getTodayISOString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function getMaxDateISOString() {
    const now = new Date();
    now.setMonth(now.getMonth() + 6);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // const today = new Date(); // Get today's date
  // const maxDate = new Date();
  // maxDate.setMonth(maxDate.getMonth() + 6);
  // today.setHours(0, 0, 0, 0);

  const useStyles = makeStyles((theme) => ({
    dateInput: {
      '& .MuiInput-underline:before': {
        borderBottom: 'none' // Removes the default underline
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none' // Removes underline on hover
      },
      '& .MuiInput-underline:after': {
        borderBottom: 'none' // Removes underline when input is focused
      },
      disabledDay: {
        backgroundColor: '#f0f0f0', // light background for disabled dates
        color: '#c0c0c0'
      }
    },
    filters: {
      '& .MuiTypography-body1': {
        fontSize: '16px'
      }
    }
  }));

  const classes = useStyles();

  const [expanded, setExpanded] = useState({
    budget: false,
    propertyType: false,
    rooms: false,
    localities: false,
    departureLocation: false,
    destinationLocation: false,
    departureTime: false,
    departureDate: false
  });

  const [selectedCheckbox, setSelectedCheckbox] = useState('');
  const [timeRange, setTimeRange] = useState({ start: '', end: '' });

  const handleCheckboxChangeTime = (value) => {
    if (selectedCheckbox === value) {
      setSelectedCheckbox('');
      setTimeRange({ start: '', end: '' });
    } else {
      // Set the selected checkbox
      setSelectedCheckbox(value);

      // Set time ranges based on the selected checkbox
      switch (value) {
        case '06-12':
          setTimeRange({ start: '06:00:00', end: '12:00:00' });
          break;
        case '12-18':
          setTimeRange({ start: '12:00:00', end: '18:00:00' });
          break;
        case '18-20':
          setTimeRange({ start: '18:00:00', end: '20:00:00' });
          break;
        case 'after-20':
          setTimeRange({ start: '20:00:00', end: '' });
          break;
        default:
          setTimeRange({ start: '', end: '' });
          break;
      }
    }
  };

  useEffect(() => {
    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      startTime: timeRange.start,
      endTime: timeRange.end
    });
  }, [timeRange]);

  const [deptartLocations, setDepartLoc] = useState(deptLocationsData);

  const [destLocations, setDestLoc] = useState(destLocationsData);

  useEffect(() => {
    setDepartLoc(deptLocationsData);
    setDestLoc(destLocationsData);
  }, [deptLocationsData, destLocationsData]);

  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const handleChange = (event) => {
    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      carPoolType: ['DRIVER_POST', 'PASSENGER_POST'],
      departLocations: [],
      destLocations: [],
      status: 'AVAILABLE',
      minCost: 0,
      maxCost: 10000,
      // departDateTime: [],
      preferredDepartDate: '',
      totalSeats: [],
      startTime: '',
      endTime: ''
    });

    setStatus(event.target.value);
    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      status: event.target.value === 'active' ? 'AVAILABLE' : 'UNAVAILABLE'
    });

    setMaxPrice(priceRange[1]);
    setMinPrice(priceRange[0]);
    setSelectedChipsProperty([]);
    setSelectedDeptartLocations([]);
    setSelectedDestLocations([]);
  };

  useEffect(() => {
    if (selectedDate === null) {
      setMarketplaceCarPoolFilterBody({ ...marketplaceCarPoolFilterBody, preferredDepartDate: '' });
    }
  }, [selectedDate]);

  const defaultMaxValue = 10000;

  const handleMinPriceChange = (event) => {
    const value = event.target.value;
    let parsedValue = parseFloat(value);

    // Check if parsed value is NaN or negative
    if (isNaN(parsedValue) || parsedValue < 0) {
      parsedValue = 0;
    }

    // Ensure min value is less than or equal to max value
    if (parsedValue > maxPrice) {
      parsedValue = maxPrice;
    }

    setMinPrice(parsedValue);
  };

  const handleMaxPriceChange = (event) => {
    const value = event.target.value;
    let parsedValue = parseFloat(value);

    // Check if parsed value is NaN, negative, or exceeds the default max value
    if (isNaN(parsedValue) || parsedValue < 0 || parsedValue > defaultMaxValue) {
      parsedValue = defaultMaxValue;
    }

    // Ensure max value is greater than or equal to min value
    if (parsedValue < minPrice) {
      parsedValue = minPrice;
    }

    setMaxPrice(parsedValue);
  };

  const handlePriceChange = (event, newPriceRange) => {
    setPriceRange(newPriceRange);
    setMinPrice(newPriceRange[0]);
    setMaxPrice(newPriceRange[1]);
  };

  const handleNewPriceChange = (event, newPriceRange) => {
    setPriceRange(newPriceRange);
    setMinPrice(newPriceRange[0]);
    setMaxPrice(newPriceRange[1]);
    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      maxCost: priceRange[1],
      minCost: priceRange[0]
    });
  };

  const handleChipClick = (type, value) => {
    // Handle chip click to add or remove filters
    if (type === 'property') {
      const index = selectedChipsProperty.indexOf(value);
      const newChips = [...selectedChipsProperty];

      if (index === -1) {
        newChips.push(value);
      } else {
        newChips.splice(index, 1);
      }

      setSelectedChipsProperty(newChips);

      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        totalSeats: newChips.length ? newChips : []
      });
    } else if (type === 'rooms') {
      const index = totalNumberPassenger.indexOf(value);
      const newChips = [...totalNumberPassenger];

      if (index === -1) {
        newChips.push(value);
      } else {
        newChips.splice(index, 1);
      }

      settotalNumberPassenger(newChips);
    }
  };

  const totalDepartureTimes = [
    { label: '12:01 - 18:00', value: '12:01 - 18:00' },
    { label: '18:00 - 20:00', value: '18:00 - 20:00' },
    { label: 'After 20:00', value: 'After 20:00' }
  ];

  const totalPassengers = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' }
  ];

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(amount);
  };

  const [selectedDateTime, setSelectedDateTime] = useState('');

  const handleChangeDateTime = (event) => {
    setSelectedDateTime(event.target.value);
    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      departDateTime: [event.target.value]
    });
  };

  const applyFilters = () => {
    let filteredData = [...userData];

    // Apply status filter
    filteredData = filteredData?.filter((item) => {
      if (status === 'active') {
        return item?.status === 'AVAILABLE';
      } else if (status === 'inactive') {
        return item?.status !== 'AVAILABLE';
      }
      return true;
    });

    // Apply price range filter
    filteredData = filteredData?.filter((item) => {
      const itemPrice = item?.price || 0;
      return itemPrice >= minPrice && itemPrice <= maxPrice;
    });

    // Apply property type filter
    if (selectedChipsProperty.length > 0) {
      filteredData = filteredData?.filter((item) => {
        const combinedPropertyType = (item?.propertyType || '').toUpperCase().replace('/', '');
        const result = selectedChipsProperty.some((selectedType) => {
          const includes =
            (item?.propertyType || '').toUpperCase().includes(selectedType.toUpperCase()) ||
            selectedType.toUpperCase().includes((item?.propertyType || '').toUpperCase());

          return includes;
        });

        return result;
      });
    }

    // Apply number of rooms filter
    // Apply number of rooms filter
    if (totalNumberPassenger.length > 0) {
      filteredData = filteredData?.filter((item) => {
        const moreThan5Selected = totalNumberPassenger.includes('more than 3');
        const hasBedroomsDefined = item?.numberOfBedrooms !== undefined;

        if (moreThan5Selected && hasBedroomsDefined) {
          return (
            parseInt(item?.numberOfBedrooms) > 3 ||
            item?.numberOfBedrooms === 'more than 3' ||
            totalNumberPassenger.includes(item?.numberOfBedrooms.toString())
          );
        } else {
          return (
            hasBedroomsDefined && totalNumberPassenger.includes(item?.numberOfBedrooms.toString())
          );
        }
      });
    }

    // Set the filtered data in the parent component's state
    setFilteredData(filteredData);
  };
  const [selectedDeptartLocations, setSelectedDeptartLocations] = useState([]);
  const [selectedDestLocations, setSelectedDestLocations] = useState([]);

  useEffect(() => {
    setStatus('active');
    setPriceRange([0, 10000]);
    setSelectedChipsProperty([]);
    setSelectedDeptartLocations([]);
    setSelectedDestLocations([]);
    setMinPrice(0);
    setMaxPrice(10000);
    setSearchMode(false);
    setExpanded({
      budget: false,
      propertyType: false,
      rooms: false,
      localities: false
    });

    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      carPoolType: ['DRIVER_POST', 'PASSENGER_POST'],
      departLocations: [],
      destLocations: [],
      status: 'AVAILABLE',
      minCost: 0,
      maxCost: 10000,

      preferredDepartDate: '',
      startTime: '',
      endTime: '',
      totalSeats: []
    });
  }, [value]);

  useEffect(() => {
    setSelectedChipsProperty([]);
    settotalNumberPassenger([]);
    setMaxPrice(priceRange[1]);
    setMinPrice(priceRange[0]);
    setExpanded({
      budget: false,
      propertyType: false,
      rooms: false,
      localities: false,
      departureLocation: false,
      destinationLocation: false,
      departureTime: false,
      departureDate: false
    });

    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      // carPoolType: ['DRIVER_POST', 'PASSENGER_POST'],
      departLocations: [],
      destLocations: [],
      minCost: 0,
      maxCost: 10000,

      preferredDepartDate: '',
      totalSeats: [],
      startTime: '',
      endTime: ''
    });

    setCurrentIndex(0);
  }, [status]);

  // useEffect to apply filters when component mounts or filters change
  useEffect(() => {
    applyFilters();
  }, [
    status,
    minPrice,
    maxPrice,
    selectedChipsProperty,
    totalNumberPassenger,
    userData[0],
    userData.length,
    page,
    finalData,
    filterData.length,
    value
  ]);

  const handleCheckboxChange = (checked, value, setSelectedItems) => {
    setSelectedItems((prevSelected) => {
      if (checked) {
        return [...prevSelected, value];
      } else {
        return prevSelected.filter((item) => item !== value);
      }
    });
  };

  useEffect(() => {
    if (selectedSortOption === 'Passengers') {
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        maxCost: 0,
        minCost: 10000
      });
      setMarketplaceCarPoolFilterBody({
        ...marketplaceCarPoolFilterBody,
        totalSeats: []
      });
    }
  }, [selectedSortOption]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDeptartLocations, setFilteredDeptartLocations] = useState(deptartLocations);

  const [searchDestTerm, setSearchDestTerm] = useState('');
  const [filteredDestLocations, setFilteredDestLocations] = useState(destLocations);

  useEffect(() => {
    const filtered = deptartLocations?.filter((location) =>
      location?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredDeptartLocations(filtered);
  }, [searchTerm, deptartLocations]);

  useEffect(() => {
    const filtered = destLocations?.filter((location) =>
      location?.toLowerCase().includes(searchDestTerm?.toLowerCase())
    );
    setFilteredDestLocations(filtered);
  }, [searchDestTerm, destLocations]);

  const handleCheckboxChangeLoc = (locationName) => {
    if (selectedDeptartLocations.includes(locationName)) {
      setSelectedDeptartLocations(
        selectedDeptartLocations?.filter((name) => name !== locationName)
      );
    } else {
      setSelectedDeptartLocations([...selectedDeptartLocations, locationName]);
    }

    const updatedDeptLocations = selectedDeptartLocations?.includes(locationName)
      ? selectedDeptartLocations.filter((selectedLocality) => selectedLocality !== locationName)
      : [...selectedDeptartLocations, locationName];

    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      departLocations: updatedDeptLocations?.length ? updatedDeptLocations : []
    });
  };

  const handleCheckboxChangeDestLoc = (locationName) => {
    if (selectedDestLocations?.includes(locationName)) {
      setSelectedDestLocations(selectedDestLocations?.filter((name) => name !== locationName));
    } else {
      setSelectedDestLocations([...selectedDestLocations, locationName]);
    }

    const updatedDeptLocations = selectedDestLocations?.includes(locationName)
      ? selectedDestLocations?.filter((selectedLocality) => selectedLocality !== locationName)
      : [...selectedDestLocations, locationName];

    setMarketplaceCarPoolFilterBody({
      ...marketplaceCarPoolFilterBody,
      destLocations: updatedDeptLocations.length ? updatedDeptLocations : []
    });
  };

  const renderSelectedFilters = () => {
    if (
      selectedChipsProperty.length === 0 &&
      totalNumberPassenger.length === 0 &&
      selectedDeptartLocations.length === 0 &&
      selectedDate == null
    ) {
      return null;
    }

    return (
      <div style={{ width: isMobile ? '100%' : '252px' }}>
        <Typography variant="h6" style={{ marginTop: '10px', fontWeight: 'bold' }}>
          Selected Filters
        </Typography>
        {/* Render selected property type filters */}

        {/* {selectedDate !== null && (
          <div>
            <Typography
              variant="body2"
              style={{ fontSize: '12px', color: 'black', fontWeight: '500' }}
            >
              Selected Date
            </Typography>
            <div>
              <Chip
                key={selectedDate.toString()}
                label={`${String(selectedDate?.getDate()).padStart(2, '0')}/${String(
                  selectedDate?.getMonth() + 1
                ).padStart(2, '0')}/${selectedDate?.getFullYear()}`}
                className="ml-1 mt-1"
                style={{
                  backgroundColor: '#ebeef2',
                  color: '#205072',
                  border: `1px solid #205072`,
                  height: '25px',
                  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                  transition: 'box-shadow 0.3s ease-in-out'
                }}
                onDelete={() => setSelectedDate(null)}
              />
            </div>
          </div>
        )} */}

        {selectedChipsProperty.length > 0 && (
          <div>
            <Typography
              variant="body2"
              style={{ fontSize: '12px', color: 'black', fontWeight: '500' }}
            >
              Selected Seats
            </Typography>
            <div>
              {selectedChipsProperty.map((selectedType) => (
                <Chip
                  key={selectedType}
                  label={selectedType}
                  className="ml-1 mt-1"
                  style={{
                    backgroundColor: '#ebeef2',
                    color: '#205072',
                    border: `1px solid #205072`,
                    height: '25px',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                    transition: 'box-shadow 0.3s ease-in-out'
                  }}
                  onDelete={() => handleChipClick('property', selectedType)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Render selected rooms filters */}
        {totalNumberPassenger.length > 0 && (
          <div className="mt-2">
            <Typography
              variant="body2"
              style={{ fontSize: '12px', color: 'black', fontWeight: '500' }}
            >
              Select Seats
            </Typography>
            <div>
              {totalNumberPassenger.map((selectedRoom) => (
                <Chip
                  key={selectedRoom}
                  className="ml-1 mt-1"
                  style={{
                    backgroundColor: '#ebeef2',
                    color: '#205072',
                    border: `1px solid #205072`,
                    height: '25px',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                    transition: 'box-shadow 0.3s ease-in-out'
                  }}
                  label={`${selectedRoom} BHK`}
                  onDelete={() => handleChipClick('rooms', selectedRoom)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Render selected localities filters */}
        {selectedDeptartLocations.length > 0 && (
          <div className="mt-2">
            <Typography
              variant="body2"
              style={{ fontSize: '12px', color: 'black', fontWeight: '500' }}
            >
              Selected Departure Location
            </Typography>
            <div className="d-flex flex-wrap">
              {selectedDeptartLocations.map((selectedLocality) => (
                <Tooltip title={selectedLocality} placement="top">
                  <Chip
                    key={selectedLocality}
                    className="ml-1 mt-1"
                    style={{
                      maxWidth: 'calc(100% - 10px)',
                      backgroundColor: '#ebeef2',
                      color: '#205072',
                      border: `1px solid #205072`,
                      height: '25px',
                      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                      transition: 'box-shadow 0.3s ease-in-out'
                    }}
                    label={selectedLocality}
                    onDelete={() => handleCheckboxChangeLoc(selectedLocality)}
                  />
                </Tooltip>
              ))}
            </div>
          </div>
        )}

        {selectedDestLocations.length > 0 && (
          <div className="mt-2">
            <Typography
              variant="body2"
              style={{ fontSize: '12px', color: 'black', fontWeight: '500' }}
            >
              Selected Destination Location
            </Typography>
            <div className="d-flex flex-wrap">
              {selectedDestLocations.map((selectedLocality) => (
                <Tooltip title={selectedLocality} placement="top">
                  <Chip
                    key={selectedLocality}
                    className="ml-1 mt-1"
                    style={{
                      maxWidth: 'calc(100% - 10px)',
                      backgroundColor: '#ebeef2',
                      color: '#205072',
                      border: `1px solid #205072`,
                      height: '25px',
                      boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                      transition: 'box-shadow 0.3s ease-in-out'
                    }}
                    label={selectedLocality}
                    onDelete={() => handleCheckboxChangeDestLoc(selectedLocality)}
                  />
                </Tooltip>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`bg-white ${!isMobile && 'col-4'} mb-2 pb-2 ${s.carPoolFilterContainer}`}>
      <div
        className="d-flex pt-2"
        style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}
      >
        <div className="px-2 py-1">
          <img src={filterIcon} alt="filterIcon" style={{ width: '34px', height: '34px' }} />
        </div>
        <div>
          <p
            className="lead-font-weight my-3"
            style={{
              color: '#282D30',
              fontSize: '15px',
              fontWeight: '600'
            }}
          >
            Filter By
          </p>
        </div>
      </div>
      {renderSelectedFilters()}
      <div className="px-2" style={{ width: isMobile ? '100%' : '252px' }}>
        <div className="px-1 py-2">
          {' '}
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={status}
              onChange={handleChange}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label={
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    Active
                  </Typography>
                }
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label={
                  <Typography
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    Inactive
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.departureDate}
          onChange={handleChangeAccordion('departureDate')}
          disabled={status === 'inactive'}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                fontSize: '13px',
                color: 'black',
                fontWeight: 550
              }}
            >
              Departure Date
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={300} display="flex" flexDirection="column" alignItems="center">
              <TextField
                autoComplete="off"
                id="date"
                value={selectedDate}
                type="date"
                // className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                fullWidth
                inputProps={{ min: getTodayISOString() }}
                onChange={handleDateChange}
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.departureTime}
          onChange={handleChangeAccordion('departureTime')}
          disabled={status === 'inactive'}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              style={{
                color: 'black',
                fontWeight: 550
              }}
              className={`${classes.filters}`}
            >
              Departure Time
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box width={300} display="flex" flexDirection="column" alignItems="flex-start">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCheckbox === '06-12'}
                    onChange={() => handleCheckboxChangeTime('06-12')}
                  />
                }
                label="06:00 - 12:00"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCheckbox === '12-18'}
                    onChange={() => handleCheckboxChangeTime('12-18')}
                  />
                }
                label="12:00 - 18:00"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCheckbox === '18-20'}
                    onChange={() => handleCheckboxChangeTime('18-20')}
                  />
                }
                label="18:00 - 20:00"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedCheckbox === 'after-20'}
                    onChange={() => handleCheckboxChangeTime('after-20')}
                  />
                }
                label="After 20:00"
              />
            </Box>
          </AccordionDetails>
        </Accordion>

        {selectedSortOption !== 'Passengers' && (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded.budget}
            onChange={handleChangeAccordion('budget')}
            disabled={status === 'inactive'}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: 'black',
                  fontWeight: 550
                }}
              >
                Cost
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={300} display="flex" flexDirection="column" alignItems="center">
                <div className="d-flex justify-content-between" style={{ width: '100%' }}>
                  <Typography
                    variant="body2"
                    className="text-left pl-10"
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    {formatCurrency(minPrice)}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="text-right pr-10"
                    style={{
                      fontSize: '14px',
                      color: 'black',
                      fontWeight: 550
                    }}
                  >
                    {formatCurrency(maxPrice)}
                  </Typography>
                </div>
                <Slider
                  value={priceRange}
                  onChange={handlePriceChange}
                  onChangeCommitted={handleNewPriceChange}
                  aria-labelledby="range-slider"
                  valueLabelDisplay="off"
                  min={0}
                  max={10000}
                  step={500}
                  style={{ color: '#205072' }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        {selectedSortOption !== 'Passengers' && (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded.propertyType}
            onChange={handleChangeAccordion('propertyType')}
            disabled={status === 'inactive'}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                style={{
                  fontSize: '14px',
                  color: 'black',
                  fontWeight: 550
                }}
              >
                Available Seats
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box width={300} display="flex" flexDirection="column" alignItems="center">
                <div style={{ marginTop: '2px', width: '100%' }}>
                  {totalPassengers?.map((option) => (
                    <Chip
                      key={option.value}
                      label={option.label}
                      color={selectedChipsProperty.includes(option.value) ? 'primary' : 'default'}
                      onClick={() => handleChipClick('property', option.value)}
                      className="ml-2 mt-2"
                      style={{
                        backgroundColor: selectedChipsProperty.includes(option.value)
                          ? '#205072'
                          : 'white',
                        color: selectedChipsProperty.includes(option.value) ? 'white' : '#205072',
                        border: `1px solid #205072`,
                        height: '25px',
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                        transition: 'box-shadow 0.3s ease-in-out'
                      }}
                    />
                  ))}
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.departureLocation}
          onChange={handleChangeAccordion('departureLocation')}
          disabled={status === 'inactive'}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <strong>Departure Location</strong>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width={200}>
              <TextField
                label="Search Location"
                variant="standard"
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="pt-2"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span onClick={() => setSearchMode(false)} style={{ cursor: 'pointer' }}>
                        <img src={filterSearchIcon} alt="searchIcon" className="pb-1" />
                      </span>
                    </InputAdornment>
                  ), inputProps: {
                    style: {
                      marginBottom: '12px'
                    }
                  }
                }}
              />

              {filteredDeptartLocations.map((location, index) => (
                <ListItem
                  key={index}
                  dense
                  button
                  onClick={() => handleCheckboxChangeLoc(location)}
                >
                  <Checkbox
                    edge="start"
                    checked={selectedDeptartLocations.includes(location)}
                    tabIndex={-1}
                    disableRipple
                  />
                  <ListItemText primary={location} />
                </ListItem>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>

        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          expanded={expanded.destinationLocation} // Updated accordion state key
          onChange={handleChangeAccordion('destinationLocation')} // Updated accordion state key
          disabled={status === 'inactive'}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <strong>Destination Location</strong>
          </AccordionSummary>
          <AccordionDetails>
            <Box display="flex" flexDirection="column" width={200}>
              {/* Search Box */}
              <TextField
                label="Search Location"
                value={searchDestTerm}
                onChange={(e) => setSearchDestTerm(e.target.value)}
                className="pt-2"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span onClick={() => setSearchMode(false)} style={{ cursor: 'pointer' }}>
                        <img src={filterSearchIcon} alt="searchIcon" className="pb-1" />
                      </span>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      marginBottom: '12px'
                    }
                  }
                }}
              />

              {/* List of Checkboxes */}
              {filteredDestLocations.map(
                (
                  location,
                  index // Using index as the key
                ) => (
                  <ListItem
                    key={index}
                    dense
                    button
                    onClick={() => handleCheckboxChangeDestLoc(location)}
                  >
                    <Checkbox
                      edge="start"
                      checked={selectedDestLocations.includes(location)}
                      tabIndex={-1}
                      disableRipple
                    />
                    <ListItemText primary={location} />
                  </ListItem>
                )
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export default CarPoolFilter;
