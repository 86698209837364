import cns from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
//mui
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Step,
  Stepper,
  Typography,
  makeStyles,
  IconButton,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

//scss
import s from './marketplace.module.scss';

//other imports
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';

//assests
import fileimage from '../../assets/icons/fileimage.png';

//actions
import {
  addPostMarketPlace,
  loadingPost,
  tabSwitch,
  updatePostMarketPlaceRentPost
} from '../../redux/actionCreators/marketplace.actionCreator';
import { getSingleMarketPostById } from '../../redux/actionCreators/post.actionCreator';
import { useMediaQuery } from 'react-responsive';

const CustomSwitch = withStyles({
  switchBase: {
    color: '#fff', // Default color for the switch
    '&$checked': {
      color: '#38A3A5' // Color when switch is checked (Yes)
    },
    '&$checked + $track': {
      backgroundColor: '#38A3A5' // Background color when switch is checked (Yes)
    }
  },
  checked: {},
  track: {}
})(Switch);

const AddPostForm = () => {
  const history = useHistory();

  const loading = useSelector((state) => state.marketPlace.loading);
  const error = useSelector((state) => state.marketPlace.error);

  const [buttonIsValid, setButtonIsValid] = useState(false);
  const [steps, setSteps] = useState(['Basic Details', 'Property Details', 'Photos']);
  const [activeStep, setActiveStep] = useState(0);
  const [suitableFor, setSuitableFor] = useState('FAMILY');
  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const [selectedDate, setSelectedDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleOpenCalendar = () => {
    setCalendarOpen(true);
  };
  const handleDateChange = (e) => {
    const today = getTodayISOString();
    const maxDate = getMaxDateISOString();
    const selectedNewDate = e.target.value;
    if (selectedNewDate < today && selectedNewDate !== '') {
      setSelectedDate(today);
    } else if (selectedNewDate > maxDate && selectedNewDate !== '') {
      setSelectedDate(today);
    } else {
      setSelectedDate(selectedNewDate);
    }

    setCalendarOpen(false);
  };
  const handleClick = (e) => {
    if (!calendarOpen) {
      handleOpenCalendar();
    }
  };
  const getTodayISOString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  function getMaxDateISOString() {
    const now = new Date();
    now.setMonth(now.getMonth() + 6);
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const today = new Date(); // Get today's date
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 6);
  today.setHours(0, 0, 0, 0);
  const formatDate = (dateArray) => {
    if (!dateArray || dateArray.length !== 7) {
      return '---'; // Return a default value if the date is not in the expected format
    }

    const dateObject = new Date(...dateArray);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based, so add 1
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const [rentPost, setrentPost] = useState({
    postType: '',
    lookingOrLending: '',
    propertyType: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    propertyDesc: '',
    apartmentSociety: '',
    furnishingType: '',
    furnishingDetails: '',
    propertyArea: '',
    // pincode: '',
    otherRooms: '', // not in backend
    location: '',
    area: '',
    price: '',
    availableFromDate: selectedDate,
    parkingAvailable: false,
    postedOn: localISOTime,
    activeFlag: true,
    securityDeposit: '',
    transactionType: '',
    title: '',
    details: '',
    isAvailable: false,
    suitableFor: ''
  });

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getSingleMarketPostById(id));
    }
  }, [id]);

  const postToEdit = useSelector((state) => state.marketPlace.postById);

  const [isEditingMode, setIsEditingMode] = useState(false);
  useEffect(() => {
    // If the post data is available, update the local state
    if (postToEdit.length > 0 && id && postToEdit[0].rentPost) {
      const parseDate = (dateArray) => {
        const [year, month, day, hours, minutes] = dateArray;
        const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
        // Construct a Date object using the provided values

        const date = new Date(year, month - 1, day + 1, hours, minutes + timezoneOffsetInMinutes);
        const dateOnlyString = date.toISOString().split('T')[0];
        return dateOnlyString;
      };
      setrentPost({
        ...postToEdit[0]?.rentPost,
        lookingOrLending: postToEdit[0]?.rentPost?.isLookingOrLending,
        area: postToEdit[0]?.rentPost?.flatAddress ?? postToEdit[0]?.rentPost?.area,
        parkingAvailable: postToEdit[0]?.rentPost?.carParkingAvailable ?? false,
        suitableFor: postToEdit[0]?.rentPost?.suitableFor ?? ''
      });
      setSuitableFor(postToEdit[0]?.rentPost?.suitableFor ?? 'FAMILY');
      setSelectedDate(parseDate(postToEdit[0]?.rentPost?.availableFromDate));
      if (postToEdit[0]?.images) {
        setFiles(
          postToEdit[0].images?.map((image) => {
            image.preview = image.fileName;
            return image;
          })
        );
      }

      setIsEditingMode(true);
      setSteps((prev) => prev.filter((step) => 'Photos' != step));
    }
  }, [postToEdit]);
  const [files, setFiles] = useState([]);

  const useStyles = makeStyles((theme) => ({
    radioButtonColor: {
      '& .MuiRadio-colorPrimary.Mui-checked': {
        color: '#38A3A5 !important'
      }
    },
    stepper: {
      backgroundColor: '#42AE9F',
      padding: theme.spacing(3, 0, 5),
      borderRadius: '8px'
    },
    stepLabel: {
      textAlign: 'right'
    },
    stepIcon: {
      color: '#42AE9F',

      '&.active': {
        color: '#42AE9F'
      }
    },
    bottomDateLine: {
      '& .MuiInputBase-root': {
        '&:before': {
          borderBottom: 'none',
          cursor: 'none'
        }
      }
    }
  }));
  const classes = useStyles();
  const stepValidForContinue = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        const trimmedTitle = rentPost?.title.trim();
        const startsWithSpace = rentPost?.title.startsWith(' ');
        const startsWithSpaceLoc = rentPost?.location.startsWith(' ');
        const startsWithSpaceArea = rentPost?.area.startsWith(' ');
        const trimmedArea = rentPost?.area.trim();
        const trimmedLocation = rentPost?.location.trim();
        return (
          rentPost?.lookingOrLending?.length > 0 &&
          rentPost?.propertyType?.length > 0 &&
          trimmedTitle?.length >= 6 &&
          !startsWithSpace &&
          trimmedTitle?.length <= 50 &&
          rentPost?.price >= 1000 &&
          trimmedLocation?.length >= 3 &&
          !startsWithSpaceLoc &&
          trimmedLocation?.length <= 20 &&
          trimmedArea?.length >= 5 &&
          !startsWithSpaceArea &&
          trimmedArea?.length <= 50 &&
          rentPost?.price <= 100000 &&
          (rentPost?.lookingOrLending === 'LOOKING' ||
            (rentPost?.securityDeposit && parseInt(rentPost?.securityDeposit) <= 100000)) > 0
        );

      case 1:
        const trimmedDesc = rentPost?.propertyDesc.trim();
        return (
          (rentPost.numberOfBedrooms > 0 || rentPost.numberOfBedrooms1 > 3) &&
          (rentPost.numberOfBathrooms > 0 || rentPost.numberOfBathrooms1 > 3) &&
          trimmedDesc?.length >= 50 &&
          trimmedDesc?.length <= 400 &&
          selectedDate &&
          (rentPost.lookingOrLending === 'LOOKING' ||
            (rentPost.propertyArea > 0 &&
              rentPost.propertyArea <= 100000 &&
              !isNaN(rentPost.propertyArea))) &&
          rentPost.furnishingType.length > 0 &&
          (rentPost.lookingOrLending === 'LOOKING' || rentPost.parkingAvailable !== undefined)
        );

      case 2:
        return files.length > 0;

      default:
        return true;
    }
  };
  const handleRemovePhoto = (indexToRemove) => {
    const updatedFiles = files.filter((_, index) => index !== indexToRemove);
    setFiles(updatedFiles);
  };
  const handleNext = async () => {
    const isStepValid = stepValid(activeStep);

    if (isStepValid) {
      try {
        if (activeStep === steps.length - 1) {
          rentPost.flatAddress = rentPost.area;

          if (rentPost.numberOfBedrooms === '> 3')
            rentPost.numberOfBedrooms = rentPost.numberOfBedrooms1;
          if (rentPost.numberOfBathrooms === '> 3')
            rentPost.numberOfBathrooms = rentPost.numberOfBathrooms1;

          rentPost.availableFromDate = new Date(selectedDate);
          rentPost.suitableFor = suitableFor;
          rentPost.title = rentPost.title.trim();
          rentPost.location = rentPost.location.trim();
          rentPost.area = rentPost.area.trim();
          if (rentPost.postId) {
            setrentPost({ ...rentPost, id: rentPost.postId });
            const promises = [];
            // Dispatch update action
            await dispatch(updatePostMarketPlaceRentPost(rentPost.postId, rentPost, promises));
          } else {
            // Dispatch add action
            await dispatch(addPostMarketPlace('FLAT_RENTING', rentPost, files));
          }

          if (!loading) {
            setActiveStep((prevActiveStep) => 0);
            history.push('/marketplace/market-place-buy-sell-post');
            setFiles([]);
            // Reset rentPost state
            setrentPost({
              lookingOrLending: 'LENDING',
              propertyType: '',
              numberOfBedrooms: '',
              numberOfBathrooms: '',
              propertyDesc: '',
              area: '',
              furnishingType: '',
              furnishingDetails: '',
              propertyArea: '',
              // pincode: '',
              otherRooms: '',
              location: '',
              price: 0,
              availableFromDate: selectedDate,
              parkingAvailable: false,
              postedOn: localISOTime,
              activeFlag: true,
              securityDeposit: 0,
              transactionType: '',
              title: '',
              details: '',
              isAvailable: false,
              suitableFor: suitableFor
            });
          }
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } catch (error) {
        // Handle error
        dispatch(loadingPost());
        console.error('Error:', error);
        // Optionally, display an error message to the user
        toast.error('An error occurred. Please try again later.');
      }
    } else {
      toast.error('Fill Required Fields');
    }
  };

  const handleBack = () => {
    // Clear validation errors for the current step
    clearValidationErrors(activeStep);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const clearValidationErrors = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        setValidationErrors({
          ...validationErrors,
          lookingOrLending: '',
          propertyType: '',
          price: '',
          title: '',
          securityDeposit: ''
        });
        break;
      case 1:
        setValidationErrors({
          ...validationErrors,
          location: '',
          area: '',
          // pincode: '',
          apartmentSociety: ''
        });
        break;
      case 2:
        setValidationErrors({
          ...validationErrors,
          numberOfBedrooms1: '',
          propertyDesc: '',
          numberOfBathrooms1: '',
          numberOfMembers1: '',
          furnishingType: '',
          parkingAvailable: '',
          // propertyArea: ''
          suitableFor: ''
        });
        break;
      case 3:
        // Assuming files validation is not step-specific
        setValidationErrors({
          ...validationErrors
          // Clear any file-related validation errors
        });
        break;
      default:
        break;
    }
  };

  const validationObj = {
    postType: '',
    lookingOrLending: '',
    propertyType: '',
    // numberOfMembers: '',
    numberOfMembers1: '',
    numberOfBedrooms: '',
    numberOfBedrooms1: '',
    numberOfBathrooms: '',
    numberOfBathrooms1: '',
    propertyDesc: '',
    apartmentSociety: '',
    furnishingType: '',
    furnishingDetails: '',
    propertyArea: '',
    // pincode: '',
    otherRooms: '', // not in backend
    location: '',
    area: '',
    price: '',
    availableFromDate: '',
    parkingAvailable: '',
    postedOn: '',
    activeFlag: '',
    securityDeposit: '',
    transactionType: '',
    title: '',
    details: '',
    isAvailable: '',
    suitableFor: ''
  };
  const [validationErrors, setValidationErrors] = useState(validationObj);
  const keyValue = {
    propertyType: 'Property Type',
    numberOfMembers1: 'Number of Members',
    numberOfBedrooms: 'Number of Bedrooms',
    numberOfBedrooms1: 'Number of Bedrooms',
    numberOfBathrooms: 'Number of Bathrooms',
    numberOfBathrooms1: 'Number of Bathrooms',
    propertyDesc: 'Property Description',
    propertyArea: 'Property Area',
    // pincode: 'Pincode',
    otherRooms: 'Other Rooms', // not in backend
    location: 'City',
    area: 'Area',
    apartmentSociety: 'Apartment',
    availableFromDate: 'Available Date',
    price: 'Price',
    title: 'Title',
    securityDeposit: 'Security Deposit',
    suitableFor: 'Suitable For'
  };

  const handleInputChange = (fieldName, event) => {
    let updatedValue = event.target.value;

    if (fieldName === 'isLookingOrLending') {
      const resetFields = {
        propertyType: '',
        numberOfMembers: '',
        numberOfBedrooms: '',
        numberOfBathrooms: '',
        numberOfBedrooms1: '',
        numberOfBathrooms: '',
        numberOfBathrooms1: '',
        propertyDesc: '',
        apartmentSociety: '',
        furnishingType: '',
        furnishingDetails: '',
        propertyArea: '',
        // pincode: '',
        otherRooms: '', // not in backend
        location: '',
        area: '',
        price: '',
        availableFromDate: selectedDate,
        parkingAvailable: false,
        postedOn: localISOTime,
        securityDeposit: '',
        transactionType: '',
        title: '',
        details: '',
        isAvailable: false,
        suitableFor: '',
        activeFlag: true
      };

      setrentPost({ ...rentPost, lookingOrLending: event.target.value, ...resetFields });
    }

    if (fieldName === 'numberOfBedrooms') {
      setrentPost((prev) => ({ ...prev, numberOfBedrooms1: '' }));
      setValidationErrors((prev) => ({ ...prev, numberOfBedrooms1: '' }));
    }
    if (fieldName === 'numberOfBathrooms') {
      setrentPost((prev) => ({ ...prev, numberOfBathrooms1: '' }));
      setValidationErrors((prev) => ({ ...prev, numberOfBathrooms1: '' }));
    }
    if (fieldName === 'numberOfMembers') {
      setrentPost((prev) => ({ ...prev, numberOfMembers1: '' }));
      setValidationErrors((prev) => ({ ...prev, numberOfMembers1: '' }));
    }
    // Update validation errors
    if (updatedValue.length > 0) {
      setValidationErrors({ ...validationErrors, [fieldName]: '' });
    } else if (updatedValue.length <= 0) {
      setValidationErrors({
        ...validationErrors,
        [fieldName]: `${keyValue[fieldName]} is Required`
      });
    }
    if (fieldName === 'title') {
      const trimmedValue = updatedValue.trim(); // Remove leading and trailing spaces
      const startsWithSpace = updatedValue.startsWith(' ');

      // Check if the title starts with spaces
      if (startsWithSpace) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Title should not start with empty spaces'
        });
      } else if (!/^[a-zA-Z0-9]/.test(trimmedValue)) {
        // Check if the trimmed title starts with a non-alphanumeric character
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Title should start with a letter or number'
        });
      } else if (trimmedValue.length > 0 && trimmedValue.length <= 5) {
        // Check if the trimmed length is less than or equal to 5 characters
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Property Title should be at least 6 characters'
        });
      } else if (trimmedValue.length > 50) {
        // Check if the trimmed length exceeds 50 characters
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Property Title should not be more than 50 characters'
        });
      } else {
        // Clear validation errors for this field if all checks pass
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: ''
        }));
      }

      // Always update the state with the original value, even if there are errors
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }

    if (fieldName === 'price') {
      if (!/^[0-9]*$/.test(updatedValue.charAt(0))) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Only numeric characters are allowed'
        });
        return;
      }
      updatedValue = updatedValue.replace(/\D/g, '');
      if (!/^[0-9]*$/.test(updatedValue)) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Only numeric characters are allowed'
        });
        return;
      }

      if (updatedValue.length > 0) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: ''
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: `${keyValue[fieldName]} is Required`
        });
      }

      // Update the state with the modified value
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }
    if (fieldName === 'propertyArea' && parseFloat(updatedValue) > 10000) {
      // toast.warn('Property Area should not be greater than 10,000 sq-ft');
      // return;
      setValidationErrors({
        ...validationErrors,
        [fieldName]: 'Property Area should not be greater than 10,000 sq-ft'
      });
    }

    if (fieldName === 'propertyArea') {
      if (updatedValue.trim() === '') {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: ''
        });
        setrentPost((prev) => ({
          ...prev,
          [fieldName]: updatedValue
        }));
        return;
      }
      if (!/^[0-9]*(\.[0-9]{0,4})?$/.test(updatedValue)) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]:
            'Please enter numeric characters only, with up to four digits after the decimal point.'
        });
        return;
      }

      if (updatedValue <= 0) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Property Area should be greater than 0'
        });
      }
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }

    if (fieldName === 'securityDeposit') {
      if (!/^[0-9]*$/.test(updatedValue.charAt(0))) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Only numeric characters are allowed'
        });
        return;
      }
      updatedValue = updatedValue.replace(/\D/g, '');
      if (!/^[0-9]*$/.test(updatedValue)) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Only numeric characters are allowed'
        });
        return;
      }
      const numericValue = parseInt(updatedValue, 10);

      // Validate the numericValue
      if (fieldName === 'securityDeposit') {
        if (numericValue < 1) {
          setValidationErrors({
            ...validationErrors,
            [fieldName]: 'Security Deposit should be greater than 1'
          });
          // Do not update the state and return
          return;
        } else if (numericValue > 100000) {
          setValidationErrors({
            ...validationErrors,
            [fieldName]: 'Security Deposit should be less than or equal to ₹100000'
          });
        }
      }
      if (updatedValue.length > 0) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: ''
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: `${keyValue[fieldName]} is Required`
        });
      }

      if (fieldName === 'securityDeposit' && parseInt(updatedValue) > 100000) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Security Deposit should be less than or equal to ₹100000'
        });
      }
      // Update the state with the modified value
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }

    if (fieldName === 'location') {
      const trimmedValue = updatedValue.trim(); // Remove leading and trailing spaces
      const startsWithSpace = updatedValue.startsWith(' ');

      // Check if the location starts with spaces
      if (startsWithSpace) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Location should not start with empty spaces'
        });
      } else if (!/^[a-zA-Z\s]+$/.test(trimmedValue)) {
        // Ensure the location contains only letters and spaces
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Location should contain only letters and spaces'
        });
      } else if (trimmedValue.length > 0 && trimmedValue.length < 3) {
        // Check if the length is less than 3 characters (ignoring spaces)
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Location should be at least 3 characters'
        });
      } else if (trimmedValue.length > 20) {
        // Check if the length exceeds 20 characters (ignoring spaces)
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Location should not be more than 20 characters'
        });
      } else {
        // Clear validation errors for this field if all checks pass
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: ''
        }));
      }

      // Always update the state with the original value, even if there are errors
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }

    if (fieldName === 'area') {
      const trimmedValue = updatedValue.trim(); // Remove leading and trailing spaces
      const startsWithSpace = updatedValue.startsWith(' ');

      // Check if the area starts with spaces
      if (startsWithSpace) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Area should not start with empty spaces'
        });
      } else if (!/^[a-zA-Z0-9\s]+$/.test(trimmedValue)) {
        // Ensure the area contains only letters and spaces
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Area should contain only letters, numbers, and spaces'
        });
      } else if (trimmedValue.length > 0 && trimmedValue.length < 5) {
        // Check if the length is less than 5 characters (ignoring spaces)
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Area should be at least 5 characters'
        });
      } else if (trimmedValue.length > 50) {
        // Check if the length exceeds 50 characters (ignoring spaces)
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Area should not be more than 50 characters'
        });
      } else {
        // Clear validation errors for this field if all checks pass
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [fieldName]: ''
        }));
      }

      // Always update the state with the original value, even if there are errors
      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue
      }));
    }

    if (fieldName === 'price' && parseInt(updatedValue) < 1000) {
      setValidationErrors({
        ...validationErrors,
        [fieldName]: 'Price should be greater than 1000'
      });
      // Do not update the state and return
      // return;
    }

    if (fieldName === 'price' && parseInt(updatedValue) > 100000) {
      // toast.warn('Price should be less than or equal to ₹100000');
      setValidationErrors({
        ...validationErrors,
        [fieldName]: 'Price should be less than or equal to ₹100000'
      });
      // return;
    }
    if (fieldName === 'price') {
      if (updatedValue > 100000) {
        setValidationErrors({
          ...validationErrors,
          price: `${keyValue[fieldName]} should be less than or equal to ₹100000`
        });
      }
    }
    if (fieldName === 'numberOfBedrooms1') {
      if (updatedValue <= 3) {
        setValidationErrors({
          ...validationErrors,
          numberOfBedrooms1: `${keyValue[fieldName]} should be greater than 3`
        });
      }
      if (updatedValue > 99) {
        setValidationErrors({
          ...validationErrors,
          numberOfBedrooms1: `Number of Bedrooms should be less than 100`
        });
        return;
      }
    }
    if (fieldName === 'numberOfBathrooms1') {
      if (updatedValue <= 3) {
        setValidationErrors({
          ...validationErrors,
          numberOfBathrooms1: `${keyValue[fieldName]} should be greater than 3`
        });
      }
      if (updatedValue > 99) {
        setValidationErrors({
          ...validationErrors,
          numberOfBathrooms1: `Number of Bathrooms should be less than 100`
        });
        // toast.warn('Number of Bathrooms should be less than 100');
        return;
      }
    }
    if (fieldName === 'numberOfMembers1') {
      if (updatedValue <= 3) {
        setValidationErrors({
          ...validationErrors,
          numberOfMembers1: `${keyValue[fieldName]} should be greater than 3`
        });
      }
      if (updatedValue > 99) {
        setValidationErrors({
          ...validationErrors,
          numberOfMembers1: `Number of Members should be less than 100`
        });
        // toast.warn('Number of Memebers should be less than 100');
        return;
      }
    }

    if (fieldName === 'propertyDesc') {
      const trimmedDescValue = updatedValue.trim();

      if (/^[^\w\d]/.test(trimmedDescValue)) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Description cannot start with a special character'
        });
        return;
      }
      if (trimmedDescValue?.length > 0 && trimmedDescValue?.length < 50) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Property Description should be at least 50 characters'
        });
      } else if (trimmedDescValue?.length > 400) {
        setValidationErrors({
          ...validationErrors,
          [fieldName]: 'Property Description should not be more than 400 characters'
        });
      } else {
        // Clear validation errors for this field if no errors
        setValidationErrors({
          ...validationErrors,
          [fieldName]: undefined
        });
      }

      setrentPost((prev) => ({
        ...prev,
        [fieldName]: updatedValue // Use the original value (not trimmed) to update the state
      }));
    }

    setrentPost((prev) => ({
      ...prev,
      [fieldName]: updatedValue
    }));
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const sellSteps = ['Photos'];

    if (rentPost.lookingOrLending === 'LENDING' && !steps.includes('Photos')) {
      setSteps((prev) => [...prev, ...sellSteps]);
    } else if (rentPost.lookingOrLending == 'LOOKING') {
      setSteps((prev) => prev.filter((step) => !sellSteps.includes(step)));
    }
    if (isEditingMode) {
      setSteps((prev) => prev.filter((step) => 'Photos' != step));
    }
  }, [rentPost.lookingOrLending]);

  const handlePropertyTypeToggle = (propertyType) => () => {
    setrentPost({
      ...rentPost,
      propertyType: propertyType
    });
  };
  const handleOtherRoomsChange = (room) => () => {
    setrentPost({
      ...rentPost,
      otherRooms: room
    });
  };

  const onDrop = (acceptedFiles) => {
    const nonExistingFiles = acceptedFiles.filter(
      (acceptedFile) => !files.some((file) => file.name === acceptedFile.name)
    );

    if (files.length + nonExistingFiles.length > 20) {
      toast.error('You can upload a maximum of 20 photos.');
      return;
    }

    // Calculate the total size of files currently in the state
    const totalSizeInBytes = files.reduce((total, file) => total + file.size, 0);

    const newFiles = nonExistingFiles
      .filter((file) => {
        // Check if adding the file would exceed the total size limit
        if (totalSizeInBytes + file.size > 20 * 1048576) {
          // 20 MB limit
          toast.error(`File ${file.name} exceeds the total size limit.`);
          return false; // Exclude this file from the newFiles array
        }
        return true; // Include this file in the newFiles array
      })
      .map((file) => {
        if (file.size <= 1048576) {
          return Object.assign(file, { preview: URL.createObjectURL(file) });
        } else {
          toast.error(`File ${file.name} is larger than 1 MB.`);
          return null;
        }
      })
      .filter((file) => file !== null);

    // Add new files to the files array if they meet the size criteria
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    imageRef.current.value = null;
  };

  const fetchData = async (imgUrl, fileType) => {
    try {
      const uniqueKey = Date.now() + Math.floor(Math.random());
      const match = imgUrl.match(/\/images\/([^/]+)_\d+\.(.+)/);
      const fileName = match[1] + '_' + uniqueKey + '.' + match[2];

      const response = await axios.get(imgUrl, {
        responseType: 'arraybuffer',
        transformRequest: [
          (data, headers) => {
            delete headers['Authorization'];
            return data;
          }
        ]
      });

      // Create a File object from the array buffer data
      const file = new File([response.data], fileName, { type: fileType });
      return file;
    } catch (error) {
      console.error('Error fetching image:', error);
      throw error; // Rethrow the error for handling in the calling function
    }
  };
  const stepValid = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        if (rentPost.lookingOrLending.length <= 0) {
          setValidationErrors({
            ...validationErrors,
            lookingOrLending: 'Looking or Lending is Required'
          });
        }
        if (rentPost.propertyType.length <= 0) {
          setValidationErrors({
            ...validationErrors,
            propertyType: 'Property Type is Required'
          });
        }
        if (rentPost.price <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, price: 'Price is Required' };
          });
        }
        if (rentPost.title.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, title: 'Title is Required' };
          });
        }
        if (rentPost.location.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, location: 'City is Required' };
          });
        }
        if (rentPost.securityDeposit <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, securityDeposit: 'Security Deposit is Required' };
          });
        }
        if (rentPost.area.length <= 0) {
          setValidationErrors((prev) => {
            return { ...prev, area: 'Preferred Area is Required' };
          });
        }

        return (
          rentPost.lookingOrLending.length > 0 &&
          rentPost.propertyType.length > 0 &&
          rentPost.title.trim().length >= 5 &&
          rentPost.title.trim().length <= 50 &&
          rentPost.price >= 1000 &&
          rentPost.location.trim().length >= 3 &&
          rentPost.location.trim().length <= 20 &&
          rentPost.area.trim().length >= 5 &&
          rentPost.area.trim().length <= 50 &&
          rentPost.price <= 100000 &&
          (rentPost.lookingOrLending === 'LOOKING' ||
            (rentPost.securityDeposit && parseInt(rentPost.securityDeposit) <= 100000)) > 0
        );

      case 1:
        if (rentPost.numberOfBedrooms == '> 3' && !rentPost.numberOfBedrooms1) {
          setValidationErrors((prev) => ({
            ...prev,
            numberOfBedrooms1: 'Number of Bedrooms is Required'
          }));
        }
        if (rentPost.propertyDesc.length <= 0) {
          setValidationErrors((prev) => ({
            ...prev,
            propertyDesc: 'Property Descrition is Required'
          }));
        }
        if (rentPost.numberOfBathrooms == '> 3' && !rentPost.numberOfBathrooms1) {
          setValidationErrors((prev) => ({
            ...prev,
            numberOfBathrooms1: 'Number of Bathrooms is Required'
          }));
        }

        if (rentPost.furnishingType.length <= 0) {
          setValidationErrors((prev) => ({
            ...prev,
            furnishingType: 'Furnishing Type is Required'
          }));
        }
        if (!selectedDate) {
          setValidationErrors((prev) => ({
            ...prev,
            availableFromDate: 'Please select a date'
          }));
          // toast.error('Please select a date'); // Display error message
          return;
        }
        return (
          (rentPost.numberOfBedrooms > 0 || rentPost.numberOfBedrooms1 > 3) &&
          (rentPost.numberOfBathrooms > 0 || rentPost.numberOfBathrooms1 > 3) &&
          rentPost.propertyDesc.length >= 50 &&
          rentPost.propertyDesc.length <= 400 &&
          selectedDate &&
          rentPost.furnishingType.length > 0 &&
          (rentPost.lookingOrLending === 'LOOKING' || rentPost.parkingAvailable !== undefined)
        );

      case 3:
        return files.length > 0;

      default:
        return true;
    }
  };
  const imageRef = useRef(null);

  //button validation err-830
  useEffect(() => {
    const isStepValid = stepValidForContinue(activeStep);
    if (isStepValid) {
      setButtonIsValid(false);
    } else {
      setButtonIsValid(true);
    }
  }, [activeStep, rentPost, files, selectedDate]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024,
    multiple: true,
    onClick: () => imageRef.current.click()
  });
  const formatOption = (option) => {
    switch (option) {
      case 'FURNISHED':
        return 'Furnished';
      case 'SEMI_FURNISHED':
        return 'Semi Furnished';
      case 'UNFURNISHED':
        return 'Unfurnished';
      default:
        return 'Unknown Option';
    }
  };
  const handleHomeClick = () => {
    dispatch(tabSwitch(1)).then(() => {
      history.push('/marketplace/market-place-buy-sell-post');
    });
  };
  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="container mt-1 d-flex">
            <Grid container spacing={3}>
              <Grid item xs={12} className="my-0 py-0">
                <Grid item xs={12} className="my-0 py-1">
                  <Grid container spacing={1} style={{ marginTop: '10px' }}>
                    <Grid item>
                      <b style={{ fontWeight: '650', fontSize: '18px' }}>
                        Please select an option to continue
                      </b>
                      <FormControl style={{ marginTop: '9px' }}>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          value={rentPost.lookingOrLending}
                          onChange={(event) => handleInputChange('isLookingOrLending', event)}
                          className={classes.radioButtonColor}
                          name="radio-buttons-group"
                          row
                          disabled={isEditingMode}
                        >
                          <FormControlLabel
                            value="LOOKING"
                            control={<Radio color="primary" disabled={isEditingMode} />}
                            label={
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  color: '#19191A',
                                  fontWeight: 550
                                }}
                              >
                                Seeking a place
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value="LENDING"
                            control={<Radio color="primary" disabled={isEditingMode} />}
                            label={
                              <Typography
                                style={{
                                  fontSize: '14px',
                                  color: '#19191A',
                                  fontWeight: 550
                                }}
                              >
                                Rent out your Space
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {rentPost.lookingOrLending && (
                    <div
                      style={{
                        borderBottom: '1px solid #DADADA',
                        marginBottom: '5px',
                        paddingTop: '10px'
                      }}
                    ></div>
                  )}
                </Grid>
              </Grid>

              {rentPost.lookingOrLending && (
                <Grid item xs={12}>
                  <h6 className={s.labels}>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>
                    {rentPost.lookingOrLending === 'LENDING'
                      ? 'Vibe of your Space'
                      : 'Pick your vibe'}
                  </h6>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant={rentPost.propertyType === 'APARTMENT' ? 'contained' : 'outlined'}
                        color="primary"
                        size="small"
                        className={s.selectButtons}
                        onClick={handlePropertyTypeToggle('APARTMENT')}
                        style={{
                          color: rentPost.propertyType === 'APARTMENT' ? 'white' : '#205072',
                          borderColor: '#38A3A5',
                          backgroundColor:
                            rentPost.propertyType === 'APARTMENT' ? '#38A3A5' : 'inherit',
                          borderRadius: '51px'
                        }}
                      >
                        <span style={{ fontSize: '11px', fontWeight: 550 }}>Apartment</span>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          rentPost.propertyType === 'INDEPENDENT_FLOOR' ? 'contained' : 'outlined'
                        }
                        color="primary"
                        size="small"
                        className={s.selectButtons}
                        onClick={handlePropertyTypeToggle('INDEPENDENT_FLOOR')}
                        style={{
                          borderColor: '#38A3A5',
                          color:
                            rentPost.propertyType === 'INDEPENDENT_FLOOR' ? 'white' : '#205072',
                          borderRadius: '51px',
                          backgroundColor:
                            rentPost.propertyType === 'INDEPENDENT_FLOOR' ? '#38A3A5' : 'inherit'
                        }}
                      >
                        <span style={{ fontSize: '11px', fontWeight: 550 }}>
                          {' '}
                          Independent Floor
                        </span>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          rentPost.propertyType === 'FULL_RESIDENCE' ? 'contained' : 'outlined'
                        }
                        color="primary"
                        size="small"
                        className={s.selectButtons}
                        onClick={handlePropertyTypeToggle('FULL_RESIDENCE')}
                        style={{
                          borderColor: '#38A3A5',
                          color: rentPost.propertyType === 'FULL_RESIDENCE' ? 'white' : '#205072',
                          borderRadius: '51px',
                          backgroundColor:
                            rentPost.propertyType === 'FULL_RESIDENCE' ? '#38A3A5' : 'inherit'
                        }}
                      >
                        <span style={{ fontSize: '11px', fontWeight: 550 }}> Full Residence</span>
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        variant={rentPost.propertyType === 'PG' ? 'contained' : 'outlined'}
                        color="primary"
                        size="small"
                        className={s.selectButtons}
                        onClick={handlePropertyTypeToggle('PG')}
                        style={{
                          borderColor: '#38A3A5',
                          color: rentPost.propertyType === 'PG' ? 'white' : '#205072',
                          borderRadius: '51px',
                          backgroundColor: rentPost.propertyType === 'PG' ? '#38A3A5' : 'inherit'
                        }}
                      >
                        <span style={{ fontSize: '11px', fontWeight: 550 }}>PG</span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {rentPost.lookingOrLending && (
                <Grid item xs={12} md={12} className="my-0 py-0">
                  <div className="form-group">
                    <label for="city-form">
                      <span className="text-danger" style={{ fontSize: '11px' }}>
                        *{' '}
                      </span>
                      {rentPost.lookingOrLending === 'LENDING'
                        ? 'Listing Title'
                        : 'Requirement Title'}
                    </label>

                    <div className={`${isMobile ? 'w-100' : 'w-100'}`}>
                      <input
                        value={rentPost.title}
                        onChange={(event) => handleInputChange('title', event)}
                        id="city-form"
                        autoComplete="off"
                        type="text"
                        placeholder={
                          rentPost.lookingOrLending === 'LENDING'
                            ? 'Capture Attention for your space'
                            : 'Capture Attention for your requirements'
                        }
                        className={cns(
                          s.formC,
                          validationErrors.title.length > 0
                            ? ' is-invalid form-control'
                            : 'form-control'
                        )}
                      />
                    </div>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      {' '}
                      {validationErrors.title}
                    </span>
                  </div>
                </Grid>
              )}

              {rentPost.lookingOrLending && (
                <Grid item xs={12} md={6} className="my-0 py-0">
                  <div className="form-group">
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>
                    <label for="city-form1" className={s.labels}>
                      {/* {rentPost.lookingOrLending === 'LENDING' ? 5 : 4}.  */}
                      City
                    </label>
                    <div className={`position-relative ${isMobile ? ' w-100' : ' w-100'} `}>
                      <input
                        value={rentPost.location}
                        onChange={(event) => handleInputChange('location', event)}
                        id="city-form1"
                        type="text"
                        autoComplete="off"
                        // maxLength={20}
                        placeholder="Enter City Name"
                        className={cns(
                          s.formC,
                          validationErrors.location.length > 0
                            ? ' is-invalid form-control'
                            : 'form-control'
                        )}
                      />
                    </div>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      {' '}
                      {validationErrors.location}
                    </span>
                  </div>
                </Grid>
              )}
              {rentPost.lookingOrLending && (
                <Grid item xs={12} md={6} className="my-0 py-0">
                  <div>
                    <div className="form-group">
                      <label for="city-form2" className={s.labels}>
                        <span className="text-danger" style={{ fontSize: '11px' }}>
                          *{' '}
                        </span>
                        {rentPost.lookingOrLending === 'LENDING' ? 'Area' : 'Preferred Area'}
                      </label>
                      <div className={`position-relative ${isMobile ? ' w-100' : ' w-100'} `}>
                        <input
                          value={rentPost.area}
                          onChange={(event) => handleInputChange('area', event)}
                          id="city-form2"
                          type="text"
                          placeholder="Enter Area"
                          autoComplete="off"
                          className={cns(
                            s.formC,
                            validationErrors.area.length > 0
                              ? ' is-invalid form-control'
                              : 'form-control'
                          )}
                        />
                      </div>
                      <span className="text-danger" style={{ fontSize: '11px' }}>
                        {' '}
                        {validationErrors.area}
                      </span>
                    </div>
                    {/* )} */}
                  </div>
                </Grid>
              )}
              {rentPost.lookingOrLending && (
                <Grid item xs={12} md={6} className="my-0 py-0">
                  <div className="form-group">
                    <label for="city-form">
                      <span className="text-danger" style={{ fontSize: '11px' }}>
                        *
                      </span>
                      {rentPost.lookingOrLending === 'LENDING'
                        ? ' Ask for Monthly Rent'
                        : ' Monthly Budget to Live the Dreams'}
                    </label>

                    <div className={cns('position-relative', `${isMobile ? 'w-100' : 'w-100'}`)}>
                      <span
                        className="position-absolute"
                        style={{ left: '10px', top: '26%', transform: 'translate(-50 %, -50 %)' }}
                      >
                        &#x20B9;
                      </span>{' '}
                      <input
                        value={rentPost.price}
                        id="city-form"
                        onChange={(event) => handleInputChange('price', event)}
                        type="text"
                        autoComplete="off"
                        placeholder="Enter Amount"
                        className={cns(
                          s.formC,
                          'px-5',
                          validationErrors.price.length > 0
                            ? ' is-invalid form-control'
                            : 'form-control'
                        )}
                      />
                    </div>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      {' '}
                      {validationErrors.price}
                    </span>
                  </div>
                </Grid>
              )}

              {rentPost.lookingOrLending === 'LENDING' && (
                <Grid item xs={12} md={6} className="my-0 py-0">
                  <div className="form-group">
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>
                    <label for="city-form">Security Deposit</label>
                    <div className={cns('position-relative', `${isMobile ? 'w-100' : 'w-100'}`)}>
                      <span
                        className="position-absolute"
                        style={{ left: '10px', top: '27%', transform: 'translate(-50 %, -50 %)' }}
                      >
                        &#x20B9;
                      </span>{' '}
                      <input
                        value={rentPost.securityDeposit}
                        id="city-form"
                        onChange={(event) => handleInputChange('securityDeposit', event)}
                        type="text"
                        placeholder="Enter Security Amount"
                        autoComplete="off"
                        className={cns(
                          s.formC,
                          validationErrors.securityDeposit.length > 0
                            ? ' is-invalid form-control px-5'
                            : 'form-control px-5'
                        )}
                      />
                    </div>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      {' '}
                      {validationErrors.securityDeposit}
                    </span>
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
        );

      case 1:
        return (
          <div className="container mt-1">
            <Grid container spacing={3}>
              <Grid item md={12} lg={6}>
                <div>
                  <p style={{ fontWeight: '510' }}>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>{' '}
                    Bedrooms {rentPost.lookingOrLending === 'LENDING' ? 'Available' : 'Required'}
                  </p>
                  <div className="d-flex align-items-center mt-1">
                    {[1, 2, 3, '> 3'].map((option) => (
                      <Button
                        key={option}
                        variant={rentPost.numberOfBedrooms === option ? 'contained' : 'outlined'}
                        color="primary"
                        size="small"
                        className={s.selectButtons}
                        onClick={() =>
                          handleInputChange('numberOfBedrooms', { target: { value: option } })
                        }
                        style={{
                          color: rentPost.numberOfBedrooms === option ? 'white' : '#205072',
                          borderColor: '#38A3A5',
                          borderRadius: '51px',
                          marginRight: '5px',
                          minWidth: '30px',
                          marginBottom: '2px',
                          fontWeight: '550',
                          backgroundColor:
                            rentPost.numberOfBedrooms === option ? '#38A3A5' : 'inherit'
                        }}
                      >
                        {option}
                      </Button>
                    ))}

                    {rentPost.numberOfBedrooms === '> 3' && (
                      <input
                        onChange={(e) =>
                          handleInputChange('numberOfBedrooms1', {
                            target: { value: e.target.value }
                          })
                        }
                        value={rentPost.numberOfBedrooms1}
                        placeholder="Enter"
                        type="number"
                        className={cns(
                          s.formC2,
                          validationErrors.numberOfBedrooms1.length > 0
                            ? ' is-invalid form-control w-25'
                            : 'form-control w-25'
                        )}
                      />
                    )}
                  </div>
                  <span className="text-danger" style={{ fontSize: '11px' }}>
                    {' '}
                    {validationErrors.numberOfBedrooms1}
                  </span>
                </div>
              </Grid>
              <Grid item md={12} lg={6} className="pe-0 me-0">
                <p style={{ fontWeight: '510' }}>
                  {' '}
                  <span className="text-danger" style={{ fontSize: '11px' }}>
                    *
                  </span>{' '}
                  Bathrooms {rentPost.lookingOrLending === 'LENDING' ? 'Available' : 'Required'}
                </p>
                <div className="d-flex align-items-center mt-1">
                  {[1, 2, 3, '> 3'].map((option) => (
                    <Button
                      size="small"
                      className={s.selectButtons}
                      key={option}
                      variant={rentPost.numberOfBathrooms === option ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() =>
                        handleInputChange('numberOfBathrooms', { target: { value: option } })
                      }
                      style={{
                        color: rentPost.numberOfBathrooms === option ? 'white' : '#205072',
                        borderColor: '#38A3A5',
                        borderRadius: '51px',
                        marginRight: '5px',
                        minWidth: '30px',
                        marginBottom: '2px',
                        fontWeight: '550',
                        backgroundColor:
                          rentPost.numberOfBathrooms === option ? '#38A3A5' : 'inherit'
                      }}
                    >
                      {option}
                    </Button>
                  ))}
                  {rentPost?.numberOfBathrooms === '> 3' && (
                    <input
                      onChange={(e) =>
                        handleInputChange('numberOfBathrooms1', {
                          target: { value: e.target.value }
                        })
                      }
                      value={rentPost?.numberOfBathrooms1}
                      placeholder="Enter"
                      type="number"
                      className={cns(
                        s.formC2,
                        validationErrors?.numberOfBathrooms1.length > 0
                          ? ' is-invalid form-control w-25'
                          : 'form-control w-25'
                      )}
                    />
                  )}
                </div>
                <span className="text-danger" style={{ fontSize: '11px' }}>
                  {' '}
                  {validationErrors?.numberOfBathrooms1}
                </span>
              </Grid>

              {rentPost?.lookingOrLending === 'LENDING' && (
                <Grid item xs={12} md={12}>
                  <label for="city-form"> Property Area</label>
                  <div className={`position-relative ${isMobile ? 'w-100' : 'w-50'}`}>
                    <input
                      value={rentPost?.propertyArea}
                      onChange={(event) => handleInputChange('propertyArea', event)}
                      id="city-form"
                      type="text"
                      placeholder="Enter"
                      autoComplete="off"
                      className={cns(s.formC, 'form-control',s.inputWithSuffix)}
                      style={{paddingRight:'40px'}}
                    />{' '}
                   <span className={s.positionSquareFoot}>&nbsp;ft²</span>
                  </div> 
                  <span className="text-danger" style={{ fontSize: '11px' }}>
                    {' '}
                    {validationErrors?.propertyArea}
                  </span>
                </Grid>
              )}
              <Grid item xs={12} md={12}>
                <label for="city-form">
                  <span className="text-danger" style={{ fontSize: '11px' }}>
                    *{' '}
                  </span>
                  Property Description
                </label>

                <div className={`${isMobile ? 'w-100' : 'w-100'}`}>
                  <textarea
                    rows={3}
                    value={rentPost?.propertyDesc}
                    onChange={(event) => handleInputChange('propertyDesc', event)}
                    id="city-form"
                    autoComplete="off"
                    type="text"
                    placeholder="Enter Property Description"
                    className={cns(
                      s.textAreaStyle,
                      validationErrors?.propertyDesc?.length > 0
                        ? ' is-invalid form-control'
                        : 'form-control'
                    )}
                  />
                </div>
                <span className="text-danger" style={{ fontSize: '11px' }}>
                  {' '}
                  {validationErrors?.propertyDesc}
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={7}>
                <div>
                  <h6 className={s.labels} role="heading" aria-level="2">
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>
                    {rentPost?.lookingOrLending === 'LOOKING' ? 'Furnishing Ask' : 'Furnishing'}
                  </h6>
                  {['FURNISHED', 'SEMI_FURNISHED', 'UNFURNISHED'].map((option) => (
                    <Button
                      size="small"
                      className={s.selectButtons}
                      key={option}
                      variant={rentPost.furnishingType === option ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() =>
                        handleInputChange('furnishingType', { target: { value: option } })
                      }
                      style={{
                        color: rentPost.furnishingType === option ? 'white' : '#205072',
                        borderColor: '#38A3A5',
                        borderRadius: '51px',
                        marginRight: '5px',
                        marginBottom: '2px',
                        fontSize: '12px',
                        fontWeight: '550',
                        backgroundColor: rentPost.furnishingType === option ? '#38A3A5' : 'inherit'
                      }}
                    >
                      {' '}
                      <span style={{ fontSize: '11px' }}>{formatOption(option)}</span>
                    </Button>
                  ))}
                </div>
                <span className="text-danger" style={{ fontSize: '11px' }}>
                  {' '}
                  {validationErrors.furnishingType}
                </span>
              </Grid>
              {rentPost.lookingOrLending === 'LENDING' && (
                <Grid item xs={12} sm={12} md={5}>
                  <div>
                    <h6 className={s.labels}>Parking Available</h6>
                    <div className={s.toggleSwitchWrapper}>
                      <span className={s.toggleSwitchLabel}>
                        {rentPost.parkingAvailable ? 'Yes' : 'No'}
                      </span>
                      <CustomSwitch
                        checked={rentPost.parkingAvailable}
                        onChange={() =>
                          handleInputChange('parkingAvailable', {
                            target: { value: !rentPost.parkingAvailable }
                          })
                        }
                      />
                    </div>
                  </div>
                </Grid>
              )}

              <Grid item md={12} lg={6}>
                <div>
                  <h6 className={s.labels}>
                    <span className="text-danger" style={{ fontSize: '11px' }}>
                      *{' '}
                    </span>{' '}
                    {rentPost.lookingOrLending === 'LENDING'
                      ? 'Available Date'
                      : 'Desired Move-In Date'}
                  </h6>
                  <TextField
                    autoComplete="off"
                    id="date"
                    type="date"
                    className={classes.textField}
                    value={selectedDate}
                    onChange={(e) => handleDateChange(e)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ min: getTodayISOString() }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div>
                  <h6 className={s.labels}>
                    {rentPost.lookingOrLending === 'LENDING' && (
                      <span className="text-danger" style={{ fontSize: '11px' }}>
                        *{' '}
                      </span>
                    )}
                    {rentPost.lookingOrLending === 'LENDING' && 'Suitable For'}
                  </h6>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="suitableFor"
                      name="suitableFor"
                      value={suitableFor}
                      onChange={(e) => setSuitableFor(e.target.value)}
                    >
                      <FormControlLabel
                        value="FAMILY"
                        control={<Radio />}
                        label={
                          rentPost.lookingOrLending === 'LENDING' ? 'Family' : 'Family Friendly'
                        }
                        style={{ marginRight: '10px' }}
                      />
                      <FormControlLabel
                        value="BACHELORS"
                        control={<Radio />}
                        label={
                          rentPost.lookingOrLending === 'LENDING'
                            ? 'Bachelors'
                            : 'Bachelors Friendly'
                        }
                        style={{ marginRight: '10px' }}
                      />

                      {rentPost.lookingOrLending === 'LENDING' && (
                        <FormControlLabel
                          value="ANY"
                          control={<Radio />}
                          label="Any"
                          style={{ marginRight: '10px' }}
                        />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </div>
        );
      case 2:
        return (
          <div className="container mt-3">
            <h6 className={cns('fw-b', s.labels)}>Add photos of your property?</h6>
            <div className="container d-flex justify-content-center">
              <div className="row">
                {files?.map((file, index) => (
                  <div className="col-lg-6 d-flex justify-content-center">
                    <img
                      src={file.preview}
                      className="my-2"
                      height={'180px'}
                      width={'100%'}
                      alt="abc"
                    />
                    <IconButton
                      onClick={() => handleRemovePhoto(index)}
                      className={s.crossButton}
                      color="secondary"
                      aria-label="delete"
                      style={{ height: 'fit-content' }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                ))}
                <div
                  className={
                    'col-md-12  d-flex justify-content-center ' +
                    (files.length > 0 ? 'col-lg-6' : 'col-lg-12')
                  }
                >
                  <div
                    className={'h-100 my-2 w-100' + (files.length === 0 && ' px-5')}
                    style={{
                      background: '#F0F9FF',
                      textAlign: 'center',
                      width: '100%'
                    }}
                  >
                    <img src={fileimage} alt="123" />
                    <h6
                      className={'fw-b' + (files.length <= 0 && ' mb-2')}
                      style={{
                        marginTop: '5px',
                        marginBottom: '0px',
                        padding: '0px',
                        color: '#38A3A5'
                      }}
                    >
                      + Add Photos
                    </h6>
                    <div
                      {...getRootProps()}
                      style={{ cursor: 'pointer', padding: '5px', borderRadius: '10px' }}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="body2">Drag and drop your photos here</Typography>
                      <Typography variant="body2">
                        Upto 20 photos (1 MB each) | Max size 20 MB | Formats: jpeg, jpg, png, webp,
                        raw, bmp, gif, avif, jfif, svg image
                      </Typography>
                    </div>
                    <Button
                      onClick={(e) => {
                        imageRef.current.click();
                      }}
                      className={cns(files.length <= 0 && 'mt-2', s.selectButtons)}
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{ backgroundColor: 'white', borderColor: '#407EC4' }}
                    >
                      Upload photos
                    </Button>
                    <input
                      multiple
                      onChange={(e) => onDrop(Array.from(e.target.files))}
                      ref={imageRef}
                      className="d-none"
                      type="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
      default:
    }
  };
  // const { isMobile, width } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return (
    <div className={window.innerWidth < 1200 && window.innerWidth > 900 ? 'm-5' : 'my-3'}>
      <Grid container spacing={1}>
        {isMobile && (
          <Grid item xs={12} sm={12} md={7} lg={4}>
            {/* Right side - Stepper */}
            {
              <h6 className="fw-b mt-4">
                <Link
                  style={{ cursor: 'pointer', textDecoration: 'none', color: 'black' }}
                  onClick={handleHomeClick}
                >
                  Home
                </Link>
                {' > '}
                <span>Add Post{' > '}</span>
                <span>{steps[activeStep]}</span>
              </h6>
            }
            <Paper className="h-75 p-0 m-0 ">
              <Stepper activeStep={activeStep} orientation="horizontal" connector={false}>
                <Step className="mb-5">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="position-relative">
                      <CircularProgress
                        variant="determinate"
                        value={(activeStep / (steps.length - 1)) * 100}
                        style={{
                          color: '#48ABAC', // Greenish color for progress
                          boxShadow: 'inset 0 0 1px 6px #d1d1d1',
                          borderRadius: '50%',
                          height: '80px',
                          width: '80px'
                        }}
                      />
                      <div
                        className="fw-b"
                        style={{
                          color: 'black',
                          position: 'absolute',
                          top: '47%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          zIndex: 1
                        }}
                      >
                        <span style={{ fontSize: '13px' }}>
                          {activeStep} of {steps.length}
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <span
                        className="fw-b"
                        style={{
                          color: '#616061',
                          marginLeft: '20px',
                          fontWeight: '500',
                          fontSize: '15px'
                        }}
                      >
                        {steps[activeStep]}
                      </span>
                      <div
                        className="fw-b"
                        style={{
                          color: '#616061',
                          marginLeft: '32px',
                          fontWeight: '500',
                          fontSize: '12px'
                        }}
                      >
                        {activeStep < steps.length - 1 && `Next: ${steps[activeStep + 1]}`}
                      </div>
                    </div>
                  </div>
                </Step>
              </Stepper>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} sm={12} md={7} lg={8}>
          {/* Left side - Form */}
          {!isMobile && (
            <h6 className="fw-b mt-4">
              <Link
                style={{ cursor: 'pointer', textDecoration: 'none', color: '#19191A99' }}
                as={Link}
                to={'/marketplace/market-place-buy-sell-post'}
              >
                Home
              </Link>
              {' > '}
              <span style={{ color: '#19191A99' }}>
                Add Post
                {rentPost?.lookingOrLending === 'LOOKING'
                  ? '- Seeking'
                  : rentPost?.lookingOrLending === 'LENDING'
                  ? '- Renting'
                  : ''}
                {' > '}
              </span>
              <span style={{ color: '#38A3A5' }}>{steps[activeStep]}</span>
            </h6>
          )}
          <Paper style={{ padding: '10px', height: '100%', width: '100%' }}>
            <form>
              {getStepContent(activeStep)}
              <div style={{ marginTop: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={s.selectButtons1}
                  style={{
                    marginLeft: '20px',
                    backgroundColor: buttonIsValid ? '#DADADA' : '#38A3A5',
                    color: 'white'
                  }}
                  onClick={handleNext}
                  disabled={loading || buttonIsValid}
                  // disabled={loading || isContinueButtonDisabled}
                >
                  <span className={s.selectButtons2}>
                    {activeStep === steps.length - 1
                      ? rentPost?.postId
                        ? 'Update Post'
                        : 'Add Post'
                      : 'Continue'}
                  </span>
                  {loading && (
                    <Spinner
                      animation="border"
                      variant="light"
                      style={{ marginLeft: '5px' }}
                      size="sm"
                    />
                  )}
                </Button>
                {activeStep !== 0 ? (
                  <Button onClick={handleBack} className={cns(s.selectButtons3, 'mx-2')}>
                    Back
                  </Button>
                ) : (
                  <Button className={cns(s.selectButtons3, 'mx-2')} onClick={handleHomeClick}>
                    Back
                  </Button>
                )}
              </div>
            </form>
          </Paper>
        </Grid>
        {!isMobile && (
          <Grid item sm={12} md={5} lg={4}>
            {/* Right side - Stepper */}
            <Paper className="w-100 p-0" style={{ maxHeight: '40%', marginTop: '46px' }}>
              <Stepper activeStep={activeStep} orientation="vertical" connector={false}>
                {steps.map((step, idx) => (
                  <Step key={idx} className={idx < steps.length - 1 ? 'mb-5' : 'mb-3'}>
                    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                      {/* Text before the step circle */}
                      <div style={{ color: '#000000', fontSize: '13px', marginRight: '8px' }}>
                        Step {idx + 1}
                      </div>

                      {/* Step circle */}
                      <div className="d-flex flex-column position-relative">
                        <div
                          style={
                            activeStep == idx
                              ? {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #48ABAC',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                              : activeStep > idx
                              ? {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #48ABAC',
                                  color: 'white',
                                  display: 'flex',
                                  backgroundColor: '#48ABAC',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                              : {
                                  width: '24px',
                                  height: '24px',
                                  borderRadius: '50%',
                                  border: '3px solid #CDCCCB',
                                  color: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginRight: '8px'
                                }
                          }
                        >
                          <svg
                            width="13"
                            height="10"
                            viewBox="0 0 13 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 5.8L4.14286 9L12 1"
                              stroke="white"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        {idx != steps.length - 1 && (
                          <div
                            style={{
                              position: 'absolute',
                              top: '100%',
                              height: '42px',
                              borderLeft: '2px solid #E3E2E2',
                              right: '60%'
                            }}
                          ></div>
                        )}
                      </div>
                      <span
                        className="fw-bold"
                        style={{
                          color: '#616061',
                          fontWeight: '500',
                          fontSize: '13px'
                        }}
                      >
                        {step}
                      </span>
                    </div>
                  </Step>
                ))}
              </Stepper>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AddPostForm;
