import React, { useState, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Checkbox,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tab,
  Tabs,
  TablePagination,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  getSearchReport,
  getDownloadReport,
  getResetReport
} from '../../redux/actionCreators/hrdashboard.actionCreator';
import { getCertiTechTypes } from '../../redux/actionCreators/profile/certification.actionCreator';
import Group from '../../assets/icons/Group 1000004347 (1).png';
import teammeeting from '../../assets/icons/teammeeting.png';
import Tooltip from '@material-ui/core/Tooltip';

import { getProfileByEmail } from '../../helpers';
import s from './hrDashboard.module.scss';
import { toast } from 'react-toastify';
import Frame from '../../assets/icons/Frame 13.png';
import Download from '../../assets/icons/downloadIcon.svg';
import eraser from '../../assets/icons/eraser.png';


const useStyles = makeStyles((theme)=>({

  selectIcon: {
    // marginRight: theme.spacing(-1.2),
    left: '14px'
  },
  menuPaper: {
    maxHeight: 200,
    overflowY: 'auto'
  }
}));
const HRDashboardFile = ({ ...props }) => {
  const [state, setState] = useState({
    councilType: '',
    activeTab: 0, // Default to 'View Report'
    councils: [],
    councilList: [],
    startDate: null,
    endDate: null,
    reportType: [],
    techType: [],
    totalCounts: {
      totalEc: props.councilMap?.['EC']?.length || 0,
      totalDc: props.councilMap?.['DC']?.length || 0,
      totalEmp: props.totalUsers
    },
    // currentPage: 0,
    isTableRendered: false,
    isActive: true
  });

  const classes = useStyles();

  // useEffect(() => {

  //   setState((prevState) => ({
  //     ...prevState,

  //     reportType: state.activeTab === 1 ? [] : '', // Reset as array for Download, string for View

  //   }));
  // }, [state.activeTab]);

  const [downloadDisabled, setDownloadDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(props.loading);
  const [activeTab, setActiveTab] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getPaginatedData = (data) =>
    data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const getTable = (data, columns, renderRow) => {
    return (
      <>
   
        {( isLoading) ? (
          // 'loading......'
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '250px',
              alignItems: 'center'
            }}
          >
            <CircularProgress
              variant="determinate"
              style={{
                color: '#48ABAC',
                boxShadow: 'inset 0 0 1px 6px #d1d1d1',
                borderRadius: '50%',
                height: '30px',
                width: '30px'
              }}
            />
          </div>
        ) : state.isTableRendered ? (
          data?.length !== 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{getPaginatedData(data).map(renderRow)}</TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                classes={{
                  selectIcon: classes.selectIcon
                }}
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null,
                    PaperProps: {
                      className: classes.menuPaper
                    }
                  }
                }}
              />
            </TableContainer>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column}>{column}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <div className={s.emptyTable}>No Records Found</div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : null}
      </>
    );
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      totalCounts: {
        totalEc: props.councilMap?.['EC']?.length || 0,
        totalDc: props.councilMap?.['DC']?.length || 0,
        totalEmp: props.totalUsers
      }
    }));
  }, [props.councilMap, props.totalUsers]);

  useEffect(() => {
    if (state.reportType.includes('CERTIFICATE')) {
      props.getCertiTechTypes();
    }
  }, [state.reportType]);

  // const handlePageClick = (page) => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     currentPage: page
  //   }));
  // };

  const handleCouncilTypeChange = (e) => {
    const selectedCouncilType = e?.target?.value;
    // console.log(e.target.value)
    // props.getCouncilList(selectedCouncilType);
    setState({
      ...state,
      councilType: selectedCouncilType,
      councilList: props.councilMap[selectedCouncilType] || [],
      councils: [],
      isTableRendered: false
    });
  };

  const handleCouncilChange = (event) => {
    if (!event?.target) {
      return;
    }

    const { value } = event.target;
    const councilValues = Object.values(state?.councilList || []); // Fallback for `councilList`

    if (value.includes('select-all')) {
      setState((prevState) => ({
        ...prevState,
        councils:
          prevState.councils.length === councilValues.length
            ? [] // If all selected, deselect all
            : councilValues // select all
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        councils: value // Update selected councils
      }));
    }
    setState((prev) => ({
      ...prev,
      isTableRendered: false
    }));
  };

  const handleStartDateChange = (date) => {
    setState((prev) => ({ ...prev, isTableRendered: false, startDate: date }));
    validateDates(date, state.endDate); // Validate on start date change
  };

  const handleEndDateChange = (date) => {
    setState((prev) => ({ ...prev, isTableRendered: false, endDate: date }));
    validateDates(state.startDate, date); // Validate on end date change
  };

  const validateDates = (startDate, endDate) => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (end < start) {
        // toast.error('End date cannot be before start date.');
        return false;
      } else if (start.getTime() === end.getTime()) {
        // toast.warning('Start date and end date cannot be same.');
        return false;
      }
    }
    return true;
  };
  // console.log(state.reportType);
  const handleReportTypeChange = (e) => {

    setState((prev) => ({
      ...prev,
      reportType: e.target.value,
      isTableRendered: e.target.value.length > prev.reportType.length ? false : prev.isTableRendered,
    }));
    setActiveTab(0);
  };
  const handleSearch = () => {
    const { councils, startDate, endDate, reportType, techType, isActive } = state;
   
    //  if all required fields are filled
    if (councils.length && reportType.length && startDate && endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
      if (validateDates(start, end)) {
        if (reportType.includes('CERTIFICATE') && (!techType || techType.length === 0)) {
          toast.warning('Please fill all required fields before viewing the report.');
          return;
        }
        let techParam = [];
        if (techType.length !== props.techTypes.length) techParam = techType;

        // to clear previous table state before fetching new data
        setState((prevState) => ({
          ...prevState,
          isTableRendered: false // Reset rendering state
        }));
        setIsLoading(true);
        // Fetch new report data
        props
          .getSearchReport(councils, startDate, endDate, reportType, techParam, isActive)
          .then(() => {
            // Render table after successful data fetch
            setState((prevState) => ({
              ...prevState,
              isTableRendered: true
            }));
            setPage(0);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching report:', error);
            toast.error('Failed to fetch the report. Please try again.');
            setIsLoading(false);
          });
         
      } else {
        // If dates are invalid, don't proceed further
        if (end < start) {
          toast.error('End date cannot be before start date.');
          // return false;
        } else if (start.getTime() === end.getTime()) {
          toast.warning('Start date and end date cannot be same.');
          // return false;
        }
        return;
      }
    } else {
      toast.warning('Please fill all required fields before viewing the report.');
    }
  };

  //  disable the button when date validation fails
  const isButtonDisabled = !(
    (state.startDate && state.endDate)
    // &&  validateDates(state.startDate, state.endDate)
  );

  const handleDownload = () => {
    const { councils, startDate, endDate, reportType, techType, isActive } = state;

    if (reportType.length && startDate && endDate) {
      let start = new Date(startDate);
      let end = new Date(endDate);
      if (validateDates(start, end)) {
        let techParam = [];
        if (techType.length != props.techTypes.length) techParam = techType;
        setDownloadDisabled(true);
        props
          .getDownloadReport(
            councils,
            startDate,
            endDate,
            reportType,
            techParam,
            isActive,
            true // true for downloading
          )
          .then(() => {
            setDownloadDisabled(false);
          })
          .catch(() => {
            setDownloadDisabled(false);
          });
      } else {
        if (end < start) {
          toast.error('End date cannot be before start date.');
          // return false;
        } else if (start.getTime() === end.getTime()) {
          toast.warning('Start date and end date cannot be same.');
          // return false;
        }
      }
    } else {
      toast.warning('Please fill all fields before downloading the report');
    }
  };
  // console.log(downloadDisabled)

  // const handleTabChange = (event, newValue) => {
  //   props.getResetReport();
  //   setState((prevState) => ({
  //     ...prevState,
  //     // activeTab: newValue,
  //     reportType: newValue === 1 ? [] : '', // Reset reportType explicitly
  //     currentPage: 0
  //   }));
  // };
  const handleTabChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState
      // currentPage: 0 // Reset current page on tab change
    }));
    setPage(0);
    setActiveTab(newValue);
  };

  const getProfileByEmail = (email) => {
    const userEmail = localStorage.getItem('email');
    let url = '';

    if (userEmail === email) {
      url = `${window.location.origin}/#/profile/`;
    } else {
      url = `${window.location.origin}/#/profile/${email}`;
    }

    // Open the URL in a new tab
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderTable = (type) => {
    const searchResults = props.searchResults;
    const anniversaries = searchResults?.anniversaries;
    const awards = searchResults?.awards;
    const certificates = searchResults?.certificates;
    const skills = searchResults?.skills;
    // console.log(searchResults);
    // console.log(props.loading)
   
    switch (type) {
      case 'ANNIVERSARY':
        return getTable(
          anniversaries,
          ['Name', 'EC Tech', 'DC Tech', 'Anniversary Date (Exp.)'],
          (anniversary) => (
            <TableRow key={anniversary.empCode || Math.random()}>
              <TableCell>
                <Tooltip title="View Profile" arrow>
                  <a
                    onClick={() => getProfileByEmail(anniversary.email)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    {anniversary.name}
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell>{anniversary.ecTech}</TableCell>
              <TableCell>{anniversary.dcTech}</TableCell>
              <TableCell>
                {anniversary.anniversaryDate.join('-')} ({anniversary.totalExperience} months)
              </TableCell>
            </TableRow>
          )
        );

      case 'AWARDS':
        return getTable(awards, ['Name', 'EC Tech', 'DC Tech', 'Recognitions'], (award) => (
          <TableRow key={award.empCode + Math.floor(Math.random() * 10000)}>
            <TableCell>
              <Tooltip title="View Profile" arrow>
                <a
                  onClick={() => getProfileByEmail(award.email)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer'
                  }}
                >
                  {award.name}
                </a>
              </Tooltip>
            </TableCell>
            <TableCell>{award.ecTech}</TableCell>
            <TableCell>{award.dcTech}</TableCell>
            <TableCell>{award.recTypeName}</TableCell>
          </TableRow>
        ));

      case 'CERTIFICATE':
        return getTable(
          certificates,
          ['Name', 'EC Tech', 'DC Tech', 'Certificate Details'], // Removed 'Certificate Icon' column
          (certificate) => (
            <TableRow key={certificate.empCode + Math.floor(Math.random() * 10000)}>
              <TableCell>
                <Tooltip title="View Profile" arrow>
                  <a
                    onClick={() => getProfileByEmail(certificate.email)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    {certificate.name}
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell>{certificate.certificate.ecTech}</TableCell>
              <TableCell>{certificate.certificate.dcTech}</TableCell>
              <TableCell>
                <div className={s.certificateContainer}>
                  <div className={s.certificateRow}>
                    <strong>Certificate Name:</strong>
                    <span className={s.certificateValue}>{certificate.certificate.name}</span>
                  </div>
                  <div className={s.certificateRow}>
                    <strong>Provider:</strong>
                    <span className={s.certificateValue}>{certificate.certificate.provider}</span>
                  </div>
                  <div className={s.certificateRow}>
                    <strong>Issue Date:</strong>
                    <span className={s.certificateValue}>
                      {certificate.certificate.issueDate.join('-')}
                    </span>
                  </div>
                  <div className={s.certificateRow}>
                    <strong>Technology:</strong>
                    <span className={s.certificateValue}>{certificate.certificate.tech.name}</span>
                  </div>
                  <p className={`${s.certificateRow} ${s.certLevel}`}>
                    {certificate.certificate.certificateLevel
                      ? certificate.certificate.certificateLevel.charAt(0).toUpperCase() +
                        certificate.certificate.certificateLevel.slice(1).toLowerCase()
                      : ''}
                  </p>
                </div>
              </TableCell>
            </TableRow>
          )
        );
      case 'SKILLS':
        return getTable(
          skills,
          ['Name', 'Designation', 'EC Tech', 'DC Tech', 'Skills'],
          (skills) => (
            <TableRow key={skills.empCode + Math.floor(Math.random() * 10000)}>
              <TableCell>
                <Tooltip title="View Profile" arrow>
                  <a
                    onClick={() => getProfileByEmail(skills.email)}
                    style={{
                      background: 'none',
                      border: 'none',
                      cursor: 'pointer'
                    }}
                  >
                    {skills.name}
                  </a>
                </Tooltip>
              </TableCell>
              <TableCell>{skills.designation}</TableCell>
              <TableCell>{skills.ecTech}</TableCell>
              <TableCell>{skills.dcTech}</TableCell>
              <TableCell>{skills.skills}</TableCell>
            </TableRow>
          )
        );
      default:
        return null;
    }
  };

  return (
    <div className={s.dashboardContainer}>
      {/* Title */}
      <div className={s.titleContainer}>
        <h2>HR Dashboard</h2>
      </div>

      {/* Stats Section */}
      <div className={s.statsContainer}>
        {/* Total EC */}
        <div className={s.statItem}>
          <div style={{ position: 'relative', top: '1px', left: '-10px' }}>
            <img src={Group} alt="group" />
          </div>
          <div className={s.statDetails}>
            <span className={s.statValue}>{state.totalCounts.totalEc}</span>
            <span className={s.statLabel}>EC</span>
          </div>
        </div>

        <div className={s.separator}></div>

        {/* Total DC */}
        <div className={s.statItem}>
          <div style={{ position: 'relative', top: '1px', left: '-10px' }}>
            <img src={Group} alt="group" />
          </div>
          <div className={s.statDetails}>
            <span className={s.statValue}>{state.totalCounts.totalDc}</span>
            <span className={s.statLabel}>DC</span>
          </div>
        </div>

        <div className={s.separator}></div>

        {/* Total Employees */}
        <div className={s.statItem}>
          <div style={{ position: 'relative', top: '1px', left: '-10px' }}>
            <img src={teammeeting} alt="teammeeting" />
          </div>
          <div className={s.statDetails}>
            <span className={s.statValue}>{state.totalCounts.totalEmp}</span>{' '}
            <span className={s.statLabel}>Employees</span>
          </div>
        </div>
      </div>
      {/* 
      <div className={s.tabContainer}>
        <Tabs
          value={state.activeTab}
          onChange={handleTabChange}
          className={s.customTabs}
          TabIndicatorProps={{ style: { display: 'none' } }} // Remove default indicator
        >
          <Tab label="View Report" className={state.activeTab === 0 ? s.activeTab : ''} />
          <Tab label="Download Report" className={state.activeTab === 1 ? s.activeTab : ''} />
        </Tabs>
      </div> */}

      {/* First Row */}
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-between',
          marginBottom: '20px',
          marginTop: '20px',
          gap: '40px'
        }}
      >
        {/* Council Type */}
        {/* Council Type Container */}
        <div
          style={{
            // border: '1px solid #ccc',
            // borderRadius: '4px',
            // backgroundColor: '#f9f9f9',
            padding: '10px',
            paddingLeft: '20px',
            paddingRight: '25px',
            paddingTop: '6px',
            paddingBottom: '3px',
            marginBottom: '5px'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <label className={s.councilTypeLabel2}>Council Type: </label>
            <div style={{ display: 'flex', gap: '10px', marginLeft: '10px' }}>
              <label>
                <input
                  type="radio"
                  name="councilType"
                  value="EC"
                  checked={state.councilType === 'EC'}
                  onChange={handleCouncilTypeChange}
                />
                EC
              </label>
              <label>
                <input
                  type="radio"
                  name="councilType"
                  value="DC"
                  checked={state.councilType === 'DC'}
                  onChange={handleCouncilTypeChange}
                />
                DC
              </label>
            </div>
          </div>
        </div>

        {/* Select Council */}
        <div>
          <TextField
            label="Select Councils"
            select
            value={state.councils}
            onChange={handleCouncilChange}
            variant="outlined"
            InputProps={{
              className: s.selectCouncilsDropdown,
              style: { width: '250px' }
            }}
            InputLabelProps={{
              className: s.councilTypeLabel // Style for label
            }}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => {
                if (selected.length === 0) return 'Select Councils';
                if (selected.length === Object.values(state?.councilList || {}).length) {
                  return 'All Councils Selected';
                }
                return selected.map((item) => ` ${item}`).join(',\n');
              },
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    width: '230px',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word'
                  }
                }
              }
            }}
          >
            <MenuItem value="select-all">
              <Checkbox
                checked={state.councils.length === Object.values(state?.councilList || {}).length}
                indeterminate={
                  state.councils.length > 0 &&
                  state.councils.length < Object.values(state?.councilList || {}).length
                }
              />
              <ListItemText primary="Select All" />
            </MenuItem>

            {Object.entries(state?.councilList || {}).map(([key, name]) => (
              <MenuItem
                key={key}
                value={name}
                style={{ whiteSpace: 'normal', wordBreak: 'break-word' }} // Wrap text
              >
                <Checkbox checked={state.councils.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </TextField>
        </div>

        {/* Report Type */}
        <div>
          <TextField
            select
            label="Report Type"
            value={state.reportType}
            onChange={handleReportTypeChange}
            variant="outlined"
            InputProps={{
              className: s.reportTypeDropdown,
              style: { width: '250px' }
            }}
            InputLabelProps={{
              className: s.reportTypeLabel
            }}
            SelectProps={{
              multiple: true,
              renderValue: (selected) => (Array.isArray(selected) ? selected.join(', ') : selected)
            }}
          >
            {['AWARDS', 'ANNIVERSARY', 'CERTIFICATE', 'SKILLS'].map((type) => (
              <MenuItem key={type} value={type}>
                {!state.isTableRendered || state.isTableRendered ? (
                  <>
                    <Checkbox checked={state.reportType.indexOf(type) > -1} />
                    <ListItemText primary={type} />
                  </>
                ) : (
                  type
                )}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* Second Row */}
      <div
        style={{
          display: 'flex',
          // justifyContent: 'space-between',
          alignItems: props.isMobile ? 'flex-start' : 'center',
          gap: '8px',
          flexDirection: props.isMobile ? 'column' : 'row'
        }}
      >
        {/* Date Pickers */}
        <div
          style={{ display: 'flex', gap: '5px', flexDirection: props.isMobile ? 'column' : 'row' }}
        >
          <TextField
            label="Start Date"
            variant="outlined"
            autoComplete="off"
            id="start-date"
            value={state.startDate}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              className: s.datePicker,
              max: new Date().toISOString().split('T')[0]
            }}
            InputProps={{
              style: { width: '200px', height: '38px', padding: '0 8px', fontSize: '14px' }
            }}
            onChange={(e) => handleStartDateChange(e.target.value)}
          />
          <TextField
            label="End Date"
            variant="outlined"
            autoComplete="off"
            id="end-date"
            value={state.endDate}
            type="date"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              className: s.datePicker,
              max: new Date().toISOString().split('T')[0]
            }}
            InputProps={{
              style: { width: '200px', height: '38px', padding: '0 8px', fontSize: '14px' }
            }}
            onChange={(e) => handleEndDateChange(e.target.value)}
          />
        </div>

        {/* Select Tech Type and Select Technology */}
        {state.reportType.includes('CERTIFICATE') && (
          <div>
            <TextField
              select
              label="Select Certificate Technology"
              value={state.techType}
              InputProps={{
                className: s.techType,
                style: { width: '248px', height: '40px' }
              }}
              InputLabelProps={{
                className: s.techTypeLabel // Style for label
              }}
              onChange={(e) => {
                const { value } = e.target;
                if (value.includes('select-all')) {
                  setState((prevState) => ({
                    ...prevState,
                    techType:
                      prevState.techType?.length === props.techTypes.length
                        ? []
                        : props.techTypes.map((option) => option.name)
                  }));
                } else {
                  setState((prevState) => ({
                    ...prevState,
                    techType: value
                  }));
                }
              }}
              fullWidth
              variant="outlined"
              SelectProps={{
                multiple: true,
                renderValue: (selected) => {
                  if (selected.length === 0) return 'Select Technology';
                  if (selected.length === props.techTypes.length) {
                    return 'All Technologies Selected';
                  }
                  return selected.map((item) => ` ${item}`).join(',\n');
                },
                MenuProps: {
                  PaperProps: {
                    style: {
                      maxHeight: 160,
                      width: '200px',
                      width: '200px',
                      overflowWrap: 'break-word',
                      wordBreak: 'break-word'
                    }
                  }
                }
              }}
            >
              <MenuItem value="select-all">
                <Checkbox
                  checked={state.techType?.length === props.techTypes.length}
                  indeterminate={
                    state.techType?.length > 0 && state.techType.length < props.techTypes.length
                  }
                />
                <ListItemText primary="Select All" />
              </MenuItem>

              {props.techTypes
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((option) => (
                  <MenuItem
                    key={option.techTypeId}
                    value={option.name}
                    style={{ whiteSpace: 'normal', wordBreak: 'break-word' }}
                  >
                    <Checkbox checked={state.techType?.indexOf(option.name) > -1} />
                    <ListItemText primary={option.name} />
                  </MenuItem>
                ))}
            </TextField>
          </div>
        )}

        {/* Search/Download Icon */}
        <div>
          {state.isTableRendered === true ? (
            <Tooltip title={downloadDisabled ? 'Download disabled' : 'Download'} arrow>
              <div
                onClick={!downloadDisabled ? handleDownload : undefined}
                className={`${s.downloadButton} ${downloadDisabled ? s.disabledIcon : ''}`}
                style={{
                  cursor: downloadDisabled ? 'not-allowed' : 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <img
                  src={Download}
                  alt="download-icon"
                  height={16}
                  width={16}
                  style={{
                    opacity: downloadDisabled ? 0.5 : 1
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={'Search'} arrow>
            <div
              onClick={!isButtonDisabled ? handleSearch : null} 
              className={`${s.icon} ${isButtonDisabled ? s.disabled : ''}`} 
              style={{
                cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                opacity: isButtonDisabled
              }}
            >
              <img src={Frame} alt="search-img" height={33} width={33} />
            </div>
              </Tooltip>
          )}
        </div>

        {/* Clear All Button */}
        <div>
          <div>
            <Tooltip title="Clear All" arrow>
              <div
                className={s.clearButton}
                onClick={() => {
                  setState({
                    ...state,
                    councilType: '',
                    councils: [],
                    councilList: [],
                    reportType: [],
                    startDate: '',
                    endDate: '',
                    techType: [],
                    isTableRendered: false
                  });
                }}
                style={{
                  cursor:
                    state.councilType ||
                    state.councils.length > 0 ||
                    state.reportType.length > 0 ||
                    state.startDate ||
                    state.endDate ||
                    state.techType.length > 0
                      ? 'pointer'
                      : 'not-allowed',
                  opacity:
                    state.councilType ||
                    state.councils.length > 0 ||
                    state.reportType.length > 0 ||
                    state.startDate ||
                    state.endDate ||
                    state.techType.length > 0
                }}
              >
                <img src={eraser} alt="eraser-img" height={22} width={22} />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      {/* </div> */}

      {/* Tabs Section */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {state.reportType.map((type, index) => (
          <Tab key={index} label={type} />
        ))}
      </Tabs>
      <div className={s.tableContent}>{renderTable(state.reportType[activeTab])}</div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.hrDashboardReducer.loading,
    error: state.hrDashboardReducer.error,
    success: state.hrDashboardReducer.success,
    techTypes: state.certificationReducer.techTypes,
    searchResults: state.hrDashboardReducer.searchResults
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getDownloadReport, getSearchReport, getResetReport, getCertiTechTypes },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HRDashboardFile);
