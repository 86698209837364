import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import {
  GET_POST_LOADING,
  GET_POST_SUCCESS,
  GET_POST_FAILURE,
  UPDATE_POST_BY_ID_LOADING,
  UPDATE_POST_BY_ID_SUCCESS,
  DELETE_POST_LOADING,
  DELETE_POST_SUCCESS,
  DELETE_POST_FAILURE,
  REFRESH_POST_BY_ID_SUCCESS,
  GET_POST_BY_ID_FAILURE,
  GET_POST_BY_ID_LOADING,
  GET_POST_BY_ID_SUCCESS,
  GET_SEARCHED_POST_LOADING,
  GET_SEARCHED_POST_SUCCESS,
  GET_SEARCHED_POST_FAILURE,
  GET_REFRESH_POST_LOADING,
  GET_MARKET_POST_BY_ID_LOADING,
  REFRESH_MARKET_POST_BY_ID,
  REFRESH_MARKET_POST_BY_ID_SUCCESS,
  DELETE_POST_SUCCESS_HASHTAG
} from '../actions/actions';

export const getFilteredPosts = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_LOADING,
      payload: {
        pageNum: data.pageNum
      }
    });

    let params = `pageNumber=${data.pageNum}&postsFilterType=${data.postsFilterType}&postTypeId=${
      'postType' in data ? data.postType : 0
    }&clubId=${'clubId' in data ? data.clubId : 0}&userId=${localStorage.getItem('email')}`;

    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts/post-filter?' + params, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const posts = response.data;
        setTimeout(() => {
          dispatch({
            type: GET_POST_SUCCESS,
            payload: {
              posts: posts,
              pageNum: data.pageNum,
              postTypeId: -1
            }
          });
        }, 1000);
      })
      .catch((error) => {
        dispatch({
          type: GET_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPosts = (data) => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_LOADING,
      payload: {
        pageNum: data.pageNum
      }
    });

    let params = `pageNum=${data.pageNum}`;
    if ('postType' in data) {
      params += '&&postType=' + data.postType;
    }

    if ('clubId' in data) {
      params += '&&clubId=' + data.clubId;
    } else if ('tagType' in data) {
      params += '&&tagId=' + data.tagType;
    }

    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts?' + params, {
        headers: {
          accept: '*/*'
        }
      })
      .then((response) => {
        const posts = response.data;
        setTimeout(() => {
          dispatch({
            type: GET_POST_SUCCESS,
            payload: {
              posts: posts,
              pageNum: data.pageNum,
              postTypeId: -1
            }
          });
        }, 1000);
      })
      .catch((error) => {
        dispatch({
          type: GET_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getSearchedPosts = (searchValue, type = 'gembook') => {
  const baseUrl =
    type === 'gembook' ? process.env.REACT_APP_GEMBOOK_SVC_URL : process.env.REACT_APP_MARKETPLACE_URL;
  return (dispatch) => {
    dispatch({
      type: GET_SEARCHED_POST_LOADING
    });
    axios
      .get(`${baseUrl}/search/post/`, {
        headers: {
          accept: '*/*'
        },
        params: {
          post: searchValue
        }
      })
      .then((response) => {
        const posts = response.data;
        dispatch({
          type: GET_SEARCHED_POST_SUCCESS,
          payload: {
            posts: posts
          }
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SEARCHED_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPost = ({ postId }) => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_BY_ID_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts/by-id', {
        headers: {
          accept: '*/*'
        },
        params: {
          postId: parseInt(postId)
        }
      })
      .then((res) => {
        const post = res.data;
        setTimeout(() => {
          dispatch({
            type: GET_POST_BY_ID_SUCCESS,
            payload: {
              post: post
            }
          });
        }, 1000);
      })
      .catch((error) => {
        dispatch({
          type: GET_POST_BY_ID_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getPostById = ({ postId }) => {
  return (dispatch) => {
    dispatch({
      type: GET_POST_BY_ID_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts/by-id', {
        headers: {
          accept: '*/*'
        },
        params: {
          postId: parseInt(postId)
        }
      })
      .then((res) => {
        const post = res.data;
        dispatch(refreshPostById({ post: post }));
      })
      .catch(() => {});
  };
};

export const updatePostById = (data, ids, pathname) => {
  return (dispatch) => {
    const toastId = React.createRef();
    const hashtag = (window.location.href.match(/\/tags\/([^/]+)/) || [])[1] || null;
    const formData = new FormData();
    formData.append('postData', JSON.stringify(data.postData));
    Array.from(data.files).forEach((file) => {
      formData.append('files', file);
    });

    ids.clubId && (data.tagId ? formData.append('tagId', data.tagId) : formData.append('tagId', 0));

    dispatch({
      type: UPDATE_POST_BY_ID_LOADING
    });
    axios
      .put(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts', formData, {
        headers: {
          accept: '*/*'
        },
        onUploadProgress: (p) => {
          const progress = p.loaded / p.total;

          // check if we already displayed a toast
          if (toastId.current === null && progress < 1) {
            toastId.current = toast('Uploading...', {
              progress: progress
            });
          } else {
            toast.update(toastId.current, { progress });
          }
        }
      })
      .then((res) => {
        toast.update(toastId.current, { autoClose: 1000 });
        toast.success('Post Edited!');

        dispatch({
          type: UPDATE_POST_BY_ID_SUCCESS,
          payload: {
            post: res.data.postContent
          }
        });
        let params = { pageNum: 0 };
        if (ids.clubId && pathname != '/dashboard') params.clubId = ids.clubId;
        ids.paramsPostId
          ? dispatch(getPostById({ postId: res.data.postId }))
          : hashtag
          ? dispatch(getSearchedPosts(hashtag))
          : dispatch(getPosts(params));
      });
  };
};

export const refreshPostById = ({ post }) => {
  return (dispatch) => {
    dispatch({
      type: REFRESH_POST_BY_ID_SUCCESS,
      payload: {
        post: post
      }
    });
  };
};

export const deletePost = (data) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_POST_LOADING
    });
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/posts', {
        headers: {
          'Content-Type': 'multipart/form-data',
          accept: '*/*'
        },
        params: {
          postId: parseInt(data)
        }
      })
      .then(() => {
        dispatch({
          type: DELETE_POST_SUCCESS,
          payload: {
            postId: data
          }
        });
        dispatch({
          type: DELETE_POST_SUCCESS_HASHTAG,
          payload: {
            postId: data
          }
        });

        toast.success('Post Deleted Succesfully');
      })
      .catch((error) => {
        dispatch({
          type: DELETE_POST_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Error: ' + error.message);
      });
  };
};

export const getRefreshPost = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REFRESH_POST_LOADING
    });
    return axios.get(`${process.env.REACT_APP_GEMBOOK_SVC_URL}/posts/refresh-post`, {
      headers: {
        accept: '*/*'
      }
    });
  };
};

//MARKETPLACE

export const getMarketPostById = (postId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MARKET_POST_BY_ID_LOADING
    });
    axios
      // .get(process.env.REACT_APP_MARKETPLACE_URL + `/getPost/post-id?postId=${parseInt(postId)}`, {
      .get(process.env.REACT_APP_MARKETPLACE_URL + `/getPost?postId=${parseInt(postId)}`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        const post = res;
        dispatch(refreshMarketPostById({ post: post }));
      })
      .catch(() => {});
  };
};

export const getSingleMarketPostById = (postId) => {
  return (dispatch) => {
    dispatch({
      type: GET_MARKET_POST_BY_ID_LOADING
    });
    axios
      .get(process.env.REACT_APP_MARKETPLACE_URL + `/getPost?postId=${parseInt(postId)}`, {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        const post = res;
        dispatch(refreshMarketPostBy({ post: post }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const refreshMarketPostById = ({ post }) => {
  return (dispatch) => {
    dispatch({
      type: REFRESH_MARKET_POST_BY_ID_SUCCESS,
      payload: {
        post: post.data
      }
    });
  };
};
export const refreshMarketPostBy = ({ post }) => {
  return (dispatch) => {
    dispatch({
      type: REFRESH_MARKET_POST_BY_ID,
      payload: {
        post: post
      }
    });
  };
};
