import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import s from './marketplace.module.scss';
import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  InputLabel,
  Select,
  MenuItem,
  Breadcrumbs,
  FormHelperText
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPostCarPool,
  loadingPost,
  tabSwitch,
  updateCarPoolPost
} from '../../redux/actionCreators/marketplace.actionCreator';
import axios from 'axios';
import { currentValidationSchema } from '../helpers/marketplaceHelper';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getSingleMarketPostById } from '../../redux/actionCreators/post.actionCreator';

const AddCarPoolForm = () => {
  const useStyles = makeStyles((theme) => ({
    radioButtonColor: {
      '& .MuiRadio-colorPrimary.Mui-checked': {
        color: '#38A3A5 !important'
      }
    },
    paper: {
      padding: theme.spacing(2),
      background: 'white',
      height: '100%'
    },
    formControlRoot: {
      '&.MuiFormControl-root': {
        display: 'flex'
      }
    },
    details: {
      '& .MuiFormControl-marginNormal': {
        marginTop: '0px'
      }
    },

    textFields: {
      '& .MuiOutlinedInput-root': {
        height: '3.1876em !important',
        marginBottom: theme.spacing(2)
      },
      '& .MuiFormControl-root': {}
    },
    label: {
      marginBottom: theme.spacing(1),
      '& .MuiFormLabel-root': {
        fontWeight: 600
      },
      color: 'black'
    },
    formContainer: {
      width: '85%',
      margin: '0 3rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      }
    },
    breadCrums: {
      '& .MuiTypography-body1': {
        lineHeight: 'none',
        fontWeight: 700
      }
    },
    selectedChip: {
      backgroundColor: '#38A3A5',
      color: 'white'
    }
  }));

  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getSingleMarketPostById(id));
    }
  }, [id]);

  const postToEdit = useSelector((state) => state.marketPlace.postById);
  const classes = useStyles();
  const history = useHistory();
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [step, setStep] = useState(1);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [selectedSeat, setSelectedSeat] = useState(null);
  const [formData, setFormData] = useState({
    carPoolType: '',
    departLocation: '',
    destLocation: '',
    departDateTime: selectedDateTime,
    totalSeats: '',
    costPerSeat: '',
    vehicleInfo: '',
    additionalInfo: '',
    createDate: localISOTime,
    updateDate: localISOTime
  });

  const [initialValues1, setInitialValues1] = useState({
    carPoolType: '',
    departLocation: '',
    destLocation: '',
    departDateTime: selectedDateTime,
    totalSeats: '',
    costPerSeat: '',
    vehicleInfo: '',
    additionalInfo: '',
    createDate: localISOTime,
    updateDate: localISOTime
  });
  const defaultLocations = [
    {
      value: '8th Floor, Anant Raj Techpark, IT Park Rd, Panchkula, Haryana 134109',
      label:
        '8th Floor, Anant Raj Techpark, IT Park Rd, Panchkula, Haryana 134109(Panchkula Office)'
    },
    {
      value: 'Plot No. 119, Udyog Vihar Phase-I, Sector-20, Gurugram, Haryana 122016',
      label:
        'Plot No. 119, Udyog Vihar Phase-I, Sector-20, Gurugram, Haryana 122016(Gurugram Office)'
    },
    {
      value: '1497, HSR Layout Urban Vault, 19th Main Road, Sector 1, Bangalore, Karnataka 560102',
      label:
        '1497, HSR Layout Urban Vault, 19th Main Road, Sector 1, Bangalore, Karnataka 560102(Bangalore Office)'
    },
    {
      value: 'The Hive – Corporate Capital, Financial District, Nanakramguda, Hyderabad 500032',
      label:
        'The Hive – Corporate Capital, Financial District, Nanakramguda, Hyderabad 500032(Hyderabad Office)'
    },
    {
      value: 'others',
      label: 'Others'
    }
  ];

  const [departureLocations, setDepartureLocations] = useState(defaultLocations);
  const [destLocations, setDestLocations] = useState(defaultLocations);
  const [queryDeparture, setQueryDeparture] = useState('');
  const [queryDest, setQueryDest] = useState('');
  const [selectedDeparture, setSelectedDeparture] = useState('');
  const [selectedDestination, setSelectedDestination] = useState('');

  const accessToken =
    'pk.eyJ1IjoiZGV2MTA5MyIsImEiOiJjbHk0MDk1dWwwY2trMnFzYTVqNDY1M3ZoIn0.J4yizmaLujewA4xBPwWFYw';

  // Function to fetch locations from Mapbox API
  const fetchLocations = async (input, setLocations) => {
    if (!input) return;

    const endpoint = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      input
    )}.json?access_token=${accessToken}&autocomplete=true&limit=5`;

    try {
      const response = await fetch(endpoint);
      const data = await response.json();

      const fetchedLocations = data.features.map((feature) => ({
        value: feature.place_name,
        label: feature.place_name
      }));

      setLocations([
        ...fetchedLocations,
        { value: 'others', label: 'Others' } // Always add "Others"
      ]);
    } catch (error) {
      console.error('Error fetching locations:', error);
      setLocations(defaultLocations);
    }
  };
  useEffect(() => {
    if (queryDeparture) {
      fetchLocations(queryDeparture, setDepartureLocations);
    } else {
      setDepartureLocations(defaultLocations);
    }
  }, [queryDeparture]);

  useEffect(() => {
    if (queryDest) fetchLocations(queryDest, setDestLocations);
    else setDestLocations(defaultLocations);
  }, [queryDest]);

  // const [selectedLocation, setSelectedLocation] = useState('');
  // const [selectedDestLocation, setSelectedDestLocation] = useState('');
  const [isOtherDepartureSelected, setIsOtherDepartureSelected] = useState(false);
  const [isOtherDestSelected, setIsOtherDestSelected] = useState(false);

  var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = new Date(Date.now() - tzoffset).toISOString().slice(0, -1);
  const viewportHeight = window.innerHeight;
  const viewportWidth = window.innerWidth;
  const getTodayISOString = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(now.getDate()).padStart(2, '0');
    const hours = '00';
    const minutes = '00';
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const dateTimeConvert = (departDateTimeArray) => {
    if (!Array.isArray(departDateTimeArray) || departDateTimeArray.length !== 5) {
      return '';
    }

    const [year, month, day, hour, minute] = departDateTimeArray;

    // Create a new Date object using the array values
    const departDate = new Date(Date.UTC(year, month - 1, day, hour, minute));

    // Returns a string in "YYYY-MM-DDTHH:MM" format
    return departDate.toISOString().slice(0, 16);
  };

  useEffect(() => {
    if (postUrl && postToEdit.length > 0 && postToEdit[0].carPool) {
      setInitialValues1({
        carPoolType: postToEdit[0]?.carPool?.carPoolType,
        title: postToEdit[0]?.carPool?.title,
        departLocation: postToEdit[0]?.carPool?.departLocation,
        destLocation: postToEdit[0]?.carPool?.destLocation,

        departDateTime: dateTimeConvert(postToEdit[0].carPool.departDateTime),
        totalSeats: postToEdit[0]?.carPool?.totalSeats,
        costPerSeat: postToEdit[0]?.carPool.costPerSeat,
        vehicleInfo: postToEdit[0]?.carPool.vehicleInfo ? postToEdit[0]?.carPool.vehicleInfo : '',
        additionalInfo: postToEdit[0]?.carPool.additionalInfo
          ? postToEdit[0]?.carPool.additionalInfo
          : '',
        otherDepartureLocation: postToEdit[0]?.carPool.otherDepartureLocation,
        otherDestLocation: postToEdit[0]?.carPool.otherDestLocation,
        status: postToEdit[0]?.carPool.status,
        createDate: localISOTime,
        updateDate: localISOTime
      });
      if (postToEdit[0]?.carPool.departLocation) {
        const selectedValue = postToEdit[0]?.carPool.departLocation;
        const filteredLocations = departureLocations.filter(
          (location) => location.value !== selectedValue
        );
        setDepartureLocations([
          ...filteredLocations,
          {
            value: postToEdit[0]?.carPool.departLocation,
            label: postToEdit[0]?.carPool.departLocation
          }
        ]);
      }
      if (postToEdit[0]?.carPool.destLocation) {
        const selectedDestValue = postToEdit[0]?.carPool.destLocation;
        const filteredDestLocations = destLocations.filter(
          (location) => location.value !== selectedDestValue
        );
        setDestLocations([
          ...filteredDestLocations,
          {
            value: postToEdit[0]?.carPool.destLocation,
            label: postToEdit[0]?.carPool.destLocation
          }
        ]);
      }
      setIsEditingMode(true);
      setSelectedSeat(postToEdit[0]?.carPool.totalSeats);
    }
  }, [postToEdit]);

  useEffect(() => {
    if (initialValues1.carPoolType === 'DRIVER_POST') {
      setStep(2);
    } else if (initialValues1.carPoolType === 'PASSENGER_POST') {
      setStep(3);
    }
  }, [initialValues1.carPoolType]);

  const fetchData = async (imgUrl, fileType) => {
    try {
      const uniqueKey = Date.now() + Math.floor(Math.random());
      const match = imgUrl.match(/\/images\/([^/]+)_\d+\.(.+)/);
      const fileName = match[1] + '_' + uniqueKey + '.' + match[2];

      const response = await axios.get(imgUrl, {
        responseType: 'arraybuffer',
        transformRequest: [
          (data, headers) => {
            delete headers['Authorization'];
            return data;
          }
        ]
      });

      const file = new File([response.data], fileName, { type: fileType });
      return file;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {}, [formData]);

  const postUrl =
    window.location.href.includes('/car-pool-add-post/') && window.location.href.split('/').pop();
  const handleHomeClick = () => {
    dispatch(tabSwitch(2)).then(() => {
      history.push('/marketplace/market-place-buy-sell-post');
    });
  };

  const handleSubmit = async (values, { resetForm, setErrors, setSubmitting, setFieldTouched }) => {
    let request = { ...values };
    if (request.departLocation === 'others') {
      request.departLocation = request.otherDepartureLocation;
    }
    if (request.destLocation === 'others' && request.otherDestLocation) {
      request.destLocation = request.otherDestLocation;
    }
    if (postUrl) {
      try {
        await dispatch(updateCarPoolPost(postUrl, request));
        resetForm();
        setSelectedSeat(null);
        setErrors({});
        history.push('/marketplace/market-place-buy-sell-post');
      } catch (error) {
        dispatch(loadingPost());
        toast.error('An error occurred. Please try again later.');
      }
    } else {
      try {
        await dispatch(addPostCarPool('CARPOOL', request));
        resetForm();
        setSelectedSeat(null);
        setErrors({});
        history.push('/marketplace/market-place-buy-sell-post');
      } catch (error) {
        dispatch(loadingPost());
        toast.error('An error occurred. Please try again later.');
        console.error('Error adding post:', error);
      }
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            className={`${classes.breadCrums} mb-0 ml-5 mt-5`}
          >
            <Link
              onClick={handleHomeClick}
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: '500'
              }}
            >
              Home
            </Link>
            <Typography
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: '#38A3A5',
                fontWeight: '500'
              }}
            >
              Add post
            </Typography>
            {step === 2 && (
              <Typography
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: '#38A3A5',
                  fontWeight: '500'
                }}
              >
                Publish a ride
              </Typography>
            )}
            {step === 3 && (
              <Typography
                style={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: '#38A3A5',
                  fontWeight: '500'
                }}
              >
                Find a ride
              </Typography>
            )}
          </Breadcrumbs>
        </div>
        <div className={`${classes.formContainer}  mt-4`}>
          <Paper className={`${classes.paper} pt-2`}>
            <Grid container spacing={1} style={{ marginTop: '10px' }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Formik
                  initialValues={initialValues1}
                  validationSchema={currentValidationSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}
                  validateOnMount={false}
                >
                  {({
                    isSubmitting,
                    values,
                    errors,
                    touched,
                    handleChange,
                    resetForm,
                    setValues,
                    setFieldValue,
                    setFieldTouched,
                    setErrors
                  }) => {
                    return (
                      <Form>
                        <b style={{ fontWeight: '650', fontSize: '18px' }}>
                          Please select an option to continue
                        </b>
                        <FormControl
                          style={{ marginTop: '9px', marginBottom: '5px' }}
                          className={classes.formControlRoot}
                        >
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="carPoolType"
                            row
                            value={values.carPoolType}
                            onChange={(e) => {
                              handleChange(e);

                              setValues({
                                carPoolType: e.target.value,
                                title: '',
                                departLocation: ' ',
                                destLocation: ' ',
                                departDateTime: '',
                                totalSeats: '',
                                costPerSeat: '',
                                otherDepartureLocation: '',
                                otherDestLocation: '',
                                vehicleInfo: '',
                                additionalInfo: '',
                                status: 'AVAILABLE',
                                createDate: localISOTime,
                                updateDate: localISOTime
                              });
                              setSelectedSeat(null);

                              if (e.target.value === 'DRIVER_POST') {
                                setStep(2);
                              } else if (e.target.value === 'PASSENGER_POST') {
                                setStep(3);
                              }
                            }}
                            disabled={isEditingMode}
                          >
                            <FormControlLabel
                              value="DRIVER_POST"
                              control={<Radio color="primary" disabled={isEditingMode} />}
                              label={
                                <Typography className={classes.breadCrums}>
                                  Publish a ride
                                </Typography>
                              }
                              disabled={isEditingMode}
                            />
                            <FormControlLabel
                              value="PASSENGER_POST"
                              control={<Radio color="primary" disabled={isEditingMode} />}
                              label={<Typography>Find a ride</Typography>}
                              disabled={isEditingMode}
                            />
                          </RadioGroup>
                        </FormControl>

                        {step === 2 && (
                          <div className="text">
                            <InputLabel className={`${classes.label} ${s.inputLabel}`}>
                              Post Title <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <TextField
                              name="title"
                              value={values.title}
                              id="title"
                              onChange={handleChange}
                              onBlur={(e) => {
                                setFieldTouched('title', true);
                              }}
                              placeholder="Capture Attention for your ride"
                              variant="outlined"
                              fullWidth
                              className={` ${classes.textFields} ${s.fieldMargin} ${s.field} `}
                              InputProps={{
                                style: { cursor: 'text', paddingLeft: '10px', borderRadius: '6px' }
                              }}
                              error={errors.title && touched.title}
                            />

                            {errors.title && touched.title && (
                              <FormHelperText
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '-14px',
                                  marginBottom: '13px'
                                }}
                                error
                              >
                                {errors.title}
                              </FormHelperText>
                            )}
                            <Grid container spacing={3}>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label} id="departLocation-label">
                                  Departure <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.departLocation && touched.departLocation}
                                >
                                  {' '}
                                  {/* Departure Dropdown */}
                                  <Autocomplete
                                    freeSolo
                                    filterOptions={(options) => options}
                                    options={departureLocations}
                                    getOptionLabel={(option) =>
                                      typeof option === 'string' ? option : option.label
                                    }
                                    onChange={(e, value) => {
                                      const selectedValue =
                                        typeof value === 'string' ? value : value?.value || '';
                                      setErrors({});
                                      setSelectedDeparture(selectedValue);
                                      setIsOtherDepartureSelected(selectedValue === 'others');
                                      setValues((prev) => ({
                                        ...prev,
                                        departLocation: selectedValue,
                                        otherDepartureLocation: ''
                                      }));
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="departLocation-label"
                                        error={errors.departLocation && touched.departLocation}
                                        label="Select Departure"
                                        // placeholder="Select Departure"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setQueryDeparture(e.target.value);
                                        }}
                                      />
                                    )}
                                    onBlur={() => {
                                      setFieldTouched('departLocation', true);
                                      if (!queryDeparture) setDepartureLocations(defaultLocations);
                                    }}
                                    onFocus={() => {
                                      setDepartureLocations(defaultLocations);
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            viewportWidth <= 300
                                              ? '30vh'
                                              : viewportHeight <= 667
                                              ? '30vh'
                                              : '209px',
                                          overflowY: 'auto'
                                        }
                                      }
                                    }}
                                  />
                                  {isOtherDepartureSelected && (
                                    <TextField
                                      label="Other Departure Location"
                                      variant="outlined"
                                      fullWidth
                                      name="otherDepartureLocation"
                                      value={values.otherDepartureLocation}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={() => setFieldTouched('otherDepartureLocation', true)}
                                    />
                                  )}
                                  {errors.otherDepartureLocation &&
                                    touched.otherDepartureLocation && (
                                      <FormHelperText
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                          marginTop: '-14px',
                                          marginBottom: '13px'
                                        }}
                                        error
                                      >
                                        {errors.otherDepartureLocation}
                                      </FormHelperText>
                                    )}
                                  {errors.departLocation && touched.departLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.departLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.departAndDest && (
                                    <div
                                      style={{ color: 'red', marginTop: '-14px', fontSize: '12px' }}
                                    >
                                      {errors.departAndDest}
                                    </div>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label} id="destLocation-label">
                                  Destination <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.destLocation && touched.destLocation}
                                >
                                  {' '}
                                  {/* Destination Dropdown */}
                                  <Autocomplete
                                    freeSolo
                                    filterOptions={(options) => options}
                                    options={destLocations}
                                    getOptionLabel={(option) =>
                                      typeof option === 'string' ? option : option.label
                                    }
                                    onChange={(e, value) => {
                                      const selectedValue =
                                        typeof value === 'string' ? value : value?.value || '';
                                      setErrors({});
                                      setSelectedDestination(selectedValue);
                                      setIsOtherDestSelected(selectedValue === 'others');
                                      setValues((prev) => ({
                                        ...prev,
                                        destLocation: selectedValue,
                                        otherDestLocation: ''
                                      }));
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="destLocation-label"
                                        error={errors.destLocation && touched.destLocation}
                                        label="Select Destination"
                                        // placeholder="Select Destination"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setQueryDest(e.target.value);
                                        }}
                                      />
                                    )}
                                    // we reset locations on blur if query is blank and in case of out of focus
                                    onBlur={() => {
                                      setFieldTouched('destLocation', true);
                                      if (!queryDest) setQueryDest(defaultLocations);
                                    }}
                                    onFocus={() => {
                                      setDestLocations(defaultLocations);
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            viewportWidth <= 300
                                              ? '30vh'
                                              : viewportHeight <= 667
                                              ? '30vh'
                                              : '209px',
                                          overflowY: 'auto'
                                        }
                                      }
                                    }}
                                  />
                                  {isOtherDestSelected && (
                                    <TextField
                                      label="Other Destination Location"
                                      variant="outlined"
                                      fullWidth
                                      name="otherDestLocation"
                                      value={values.otherDestLocation}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={() => setFieldTouched('otherDestLocation', true)}
                                    />
                                  )}
                                  {errors.otherDestLocation && touched.otherDestLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.otherDestLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.destLocation && touched.destLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.destLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.departAndDest && (
                                    <div
                                      style={{ color: 'red', marginTop: '-14px', fontSize: '12px' }}
                                    >
                                      {errors.departAndDest}
                                    </div>
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>
                            <Grid container spacing={0} style={{ gap: '3rem', marginTop: '7px' }}>
                              <Grid item xs={3}>
                                <InputLabel className={classes.label}>
                                  Journey Date & Time <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <TextField
                                  autoComplete="off"
                                  name="departDateTime"
                                  value={values.departDateTime}
                                  id="departDateTime"
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(e) => {
                                    setFieldTouched('departDateTime', true); // Marked field as touched on blur
                                  }}
                                  type="datetime-local"
                                  variant="outlined"
                                  InputProps={{
                                    style: {
                                      cursor: 'text',
                                      borderRadius: '6px'
                                    }
                                  }}
                                  inputProps={{ min: getTodayISOString() }}
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.departDateTime && touched.departDateTime}
                                />
                                {errors.departDateTime && touched.departDateTime && (
                                  <FormHelperText
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginTop: '-14px',
                                      marginBottom: '13px'
                                    }}
                                    error
                                  >
                                    {errors.departDateTime.includes('Invalid')
                                      ? 'Invalid Date, Please select a valid date'
                                      : errors.departDateTime}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <InputLabel className={classes.label}>
                                  Price <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <TextField
                                  name="costPerSeat"
                                  value={values.costPerSeat}
                                  id="costPerSeat"
                                  placeholder="₹999"
                                  onChange={(e) => handleChange(e)}
                                  onBlur={() => {
                                    setFieldTouched('costPerSeat', true);
                                  }}
                                  type="number"
                                  variant="outlined"
                                  inputProps={{ min: new Date().toISOString().slice(0, 16) }}
                                  InputProps={{
                                    style: {
                                      cursor: 'text',
                                      paddingLeft: '10px',
                                      borderRadius: '6px'
                                    }
                                  }}
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.costPerSeat && touched.costPerSeat}
                                />
                                {errors.costPerSeat && touched.costPerSeat && (
                                  <FormHelperText
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginTop: '-14px',
                                      marginBottom: '13px'
                                    }}
                                    error
                                  >
                                    {errors.costPerSeat}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={5}>
                                <InputLabel className={classes.label}>
                                  Available Seat <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <div style={{ display: 'flex' }}>
                                  {[1, 2, 3, 4, 5].map((seat) => (
                                    <Button
                                      key={seat}
                                      variant={selectedSeat === seat ? 'contained' : 'outlined'}
                                      color="primary"
                                      style={{
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        borderRadius: '15px',
                                        minWidth: '20px',
                                        background: selectedSeat === seat ? '#38A3A5' : 'white',
                                        color: selectedSeat === seat ? 'white' : 'black'
                                      }}
                                      onClick={() => {
                                        handleChange({
                                          target: { name: 'totalSeats', value: seat }
                                        });
                                        setSelectedSeat(seat);
                                      }}
                                    >
                                      {seat}
                                    </Button>
                                  ))}
                                </div>
                                <ErrorMessage
                                  name="totalSeats"
                                  component="div"
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    marginTop: '-7px',
                                    marginBottom: '13px'
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label}>
                                  Vehicle Details <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <TextField
                                  name="vehicleInfo"
                                  value={values.vehicleInfo}
                                  id="vehicleInfo"
                                  placeholder="e.g., Hyundai creta, Jeep Compass, Maruti Swift"
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(e) => {
                                    setFieldTouched('vehicleInfo', true);
                                  }}
                                  margin="normal"
                                  variant="outlined"
                                  InputProps={{
                                    style: {
                                      cursor: 'text',
                                      paddingLeft: '10px',
                                      borderRadius: '6px'
                                    }
                                  }}
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field} ${classes.details}`}
                                  error={errors.vehicleInfo && touched.vehicleInfo}
                                />
                                {errors.vehicleInfo && touched.vehicleInfo && (
                                  <FormHelperText
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginTop: '-14px',
                                      marginBottom: '13px'
                                    }}
                                    error
                                  >
                                    {errors.vehicleInfo}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label}>Other Details</InputLabel>
                                <TextField
                                  name="additionalInfo"
                                  value={values.additionalInfo ? values.additionalInfo : ''}
                                  placeholder="Enter"
                                  onChange={(e) => handleChange(e)}
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  InputProps={{
                                    style: {
                                      cursor: 'text',
                                      paddingLeft: '10px',
                                      borderRadius: '6px'
                                    }
                                  }}
                                />
                                <ErrorMessage
                                  name="additionalInfo"
                                  component="div"
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    marginTop: '-14px',
                                    marginBottom: '13px'
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        {step === 3 && (
                          <div className="text">
                            <InputLabel className={`${classes.label} ${s.inputLabel}`}>
                              Post Title <span style={{ color: 'red' }}>*</span>
                            </InputLabel>
                            <TextField
                              name="title"
                              value={values.title}
                              id="title"
                              onChange={handleChange}
                              onBlur={(e) => {
                                setFieldTouched('title', true);
                              }}
                              placeholder="Capture Attention for your ride"
                              variant="outlined"
                              fullWidth
                              className={` ${classes.textFields} ${s.fieldMargin} ${s.field} `}
                              InputProps={{
                                style: { cursor: 'text', paddingLeft: '10px', borderRadius: '6px' }
                              }}
                              error={errors.title && touched.title}
                            />

                            {errors.title && touched.title && (
                              <FormHelperText
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '-14px',
                                  marginBottom: '13px'
                                }}
                                error
                              >
                                {errors.title}
                              </FormHelperText>
                            )}
                            <Grid container spacing={3}>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label} id="departLocation-label">
                                  Departure <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.departLocation && touched.departLocation}
                                >
                                  {' '}
                                  <Autocomplete
                                    freeSolo
                                    filterOptions={(options) => options}
                                    options={departureLocations}
                                    getOptionLabel={(option) =>
                                      typeof option === 'string' ? option : option.label
                                    }
                                    onChange={(e, value) => {
                                      const selectedValue =
                                        typeof value === 'string' ? value : value?.value || '';
                                      setErrors({});
                                      setSelectedDeparture(selectedValue);
                                      setIsOtherDepartureSelected(selectedValue === 'others');
                                      setValues((prev) => ({
                                        ...prev,
                                        departLocation: selectedValue,
                                        otherDepartureLocation: ''
                                      }));
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="departLocation-label"
                                        error={errors.departLocation && touched.departLocation}
                                        label="Select Departure"
                                        // placeholder="Select Departure"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setQueryDeparture(e.target.value);
                                        }}
                                      />
                                    )}
                                    onBlur={() => {
                                      setFieldTouched('departLocation', true);
                                      if (!queryDeparture) setDepartureLocations(defaultLocations);
                                    }}
                                    onFocus={() => {
                                      setDepartureLocations(defaultLocations);
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            viewportWidth <= 300
                                              ? '30vh'
                                              : viewportHeight <= 667
                                              ? '30vh'
                                              : '209px',
                                          overflowY: 'auto'
                                        }
                                      }
                                    }}
                                  />
                                  {isOtherDepartureSelected && (
                                    <TextField
                                      label="Other Departure Location"
                                      variant="outlined"
                                      fullWidth
                                      name="otherDepartureLocation"
                                      value={values.otherDepartureLocation}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={() => setFieldTouched('otherDepartureLocation', true)}
                                    />
                                  )}
                                  {errors.otherDepartureLocation &&
                                    touched.otherDepartureLocation && (
                                      <FormHelperText
                                        style={{
                                          color: 'red',
                                          fontSize: '12px',
                                          marginTop: '-14px',
                                          marginBottom: '13px'
                                        }}
                                        error
                                      >
                                        {errors.otherDepartureLocation}
                                      </FormHelperText>
                                    )}
                                  {errors.departLocation && touched.departLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.departLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.departAndDest && (
                                    <div
                                      style={{ color: 'red', marginTop: '-14px', fontSize: '12px' }}
                                    >
                                      {errors.departAndDest}
                                    </div>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={6} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label} id="destLocation-label">
                                  Destination <span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <FormControl
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.destLocation && touched.destLocation}
                                >
                                  {' '}
                                  <Autocomplete
                                    freeSolo
                                    filterOptions={(options) => options}
                                    options={destLocations}
                                    getOptionLabel={(option) =>
                                      typeof option === 'string' ? option : option.label
                                    }
                                    onChange={(e, value) => {
                                      const selectedValue =
                                        typeof value === 'string' ? value : value?.value || '';
                                      setErrors({});
                                      setSelectedDestination(selectedValue);
                                      setIsOtherDestSelected(selectedValue === 'others');
                                      setValues((prev) => ({
                                        ...prev,
                                        destLocation: selectedValue,
                                        otherDestLocation: ''
                                      }));
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        id="destLocation-label"
                                        error={errors.destLocation && touched.destLocation}
                                        label="Select Destination"
                                        // placeholder="Select Destination"
                                        variant="outlined"
                                        onChange={(e) => {
                                          setQueryDest(e.target.value);
                                        }}
                                      />
                                    )}
                                    // we reset locations on blur if query is blank and in case of out of focus
                                    onBlur={() => {
                                      setFieldTouched('destLocation', true);
                                      if (!queryDest) setQueryDest(defaultLocations);
                                    }}
                                    onFocus={() => {
                                      setDestLocations(defaultLocations);
                                    }}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight:
                                            viewportWidth <= 300
                                              ? '30vh'
                                              : viewportHeight <= 667
                                              ? '30vh'
                                              : '209px',
                                          overflowY: 'auto'
                                        }
                                      }
                                    }}
                                  />
                                  {isOtherDestSelected && (
                                    <TextField
                                      label="Other Destination Location"
                                      variant="outlined"
                                      fullWidth
                                      name="otherDestLocation"
                                      value={values.otherDestLocation}
                                      onChange={(e) => {
                                        handleChange(e);
                                      }}
                                      onBlur={() => setFieldTouched('otherDestLocation', true)}
                                    />
                                  )}
                                  {errors.otherDestLocation && touched.otherDestLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.otherDestLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.destLocation && touched.destLocation && (
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginTop: '-14px',
                                        marginBottom: '13px'
                                      }}
                                      error
                                    >
                                      {errors.destLocation}
                                    </FormHelperText>
                                  )}
                                  {errors.departAndDest && (
                                    <div
                                      style={{ color: 'red', marginTop: '-14px', fontSize: '12px' }}
                                    >
                                      {errors.departAndDest}
                                    </div>
                                  )}
                                </FormControl>
                              </Grid>
                            </Grid>

                            <Grid container style={{ marginTop: '7px' }}>
                              <Grid item xs={3} style={{ marginRight: 'auto' }}>
                                <InputLabel className={classes.label}>
                                  Journey Date & Time<span style={{ color: 'red' }}>*</span>
                                </InputLabel>
                                <TextField
                                  name="departDateTime"
                                  value={values.departDateTime}
                                  onChange={(e) => handleChange(e)}
                                  onBlur={(e) => {
                                    setFieldTouched('departDateTime', true);
                                  }}
                                  placeholder="DD/MM/YYY"
                                  type="datetime-local"
                                  InputProps={{
                                    style: {
                                      cursor: 'text',
                                      paddingLeft: '10px',
                                      borderRadius: '6px'
                                    }
                                  }}
                                  inputProps={{ min: getTodayISOString() }}
                                  variant="outlined"
                                  fullWidth
                                  className={` ${classes.textFields} ${s.fieldMargin} ${s.field}`}
                                  error={errors.departDateTime && touched.departDateTime}
                                />
                                {errors.departDateTime && touched.departDateTime && (
                                  <FormHelperText
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginTop: '-14px',
                                      marginBottom: '13px'
                                    }}
                                    error
                                  >
                                    {errors.departDateTime}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                        <div className="mt-2">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{
                              marginRight: '10px',
                              backgroundColor: step === 1 ? 'rgb(218, 218, 218)' : '#38A3A5',
                              color: 'white',
                              textTransform: 'capitalize'
                            }}
                            disabled={step === 1 && !isEditingMode}
                          >
                            {step === 1
                              ? postUrl
                                ? 'Update'
                                : 'Continue'
                              : postUrl
                              ? 'Update'
                              : 'Save'}
                          </Button>

                          <Button
                            variant="contained"
                            color="default"
                            style={{
                              boxShadow: 'none',
                              background: 'none',
                              textTransform: 'capitalize'
                            }}
                            onClick={handleHomeClick}
                          >
                            Back
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default AddCarPoolForm;
