import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import s from './searchbar.module.scss';
import PeopleIcon from '../../../assets/icons/PeopleSearchIcon.svg';
import PostIcon from '../../../assets/icons/postSearchIcon.svg';
import PeopleOutline from '../../../assets/icons/PeopleOutline.svg';
import PostOutline from '../../../assets/icons/PostOutline.svg';
import arrowBackward from '../../../assets/icons/arrowBackward.svg';
import { Tooltip } from '@material-ui/core';

//Helper
import { useMediaQuery } from 'react-responsive';

export default function SearchBar({ inputVal, onChangeVal, setInputVal, setSearchMobile }) {
  const [isPost, setIsPost] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isSearch, setIsSearch] = useState(true);
  const wrapper = useRef();
  const location = useLocation(); // Get current location
  const isMobile = useMediaQuery({ maxWidth: 992 });
  
  // Determine if the current page is Marketplace
  const isMarketplace = location.pathname.includes('/marketplace');

  useEffect(() => {
    const handler = (event) => {
      if (!wrapper.current.contains(event.target)) {
        setIsSearch(true);
      }
    };
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  const onInputChange = (event) => {
    let timer;
    const searchValue = event.target.value.trimStart();
    clearTimeout(timer);
    if (event.target.value.length === 0) {
      onChangeVal('', false);
      setIsSearch(true);
    } else if (event.target.value.length > 3) {
      timer = setTimeout(() => onChangeVal(searchValue, isPost), 300); // Set a delay of 500 milliseconds
    }
    setInputValue(searchValue);
    setInputVal(searchValue);
  };

  function changePostValue(post) {
    setIsPost(post);
    if (inputValue.length) {
      onChangeVal(inputValue, post);
    }
  }

  const clearInput = () => {
    setInputValue('');
    setInputVal('');
  };

  const Desktop = () => {
    return (
      <div ref={wrapper}>
        {isSearch ? (
          <input
            type="text"
            id="searchInput"
            className={s.searchInput}
            placeholder={isMarketplace ? "Search in marketplace" : "Search"}
            onClick={() => {
              setIsSearch(false);
            }}
            defaultValue={inputVal}
          />
        ) : (
          <div className={s.searchBar}>
            <div>
              <input
                autoFocus={true}
                type="search"
                id="searchInput"
                placeholder={isMarketplace ? "Search in marketplace" : "Search"}
                value={inputVal}
                onChange={onInputChange}
                className={s.inputBar}
              />
            </div>
            {isMarketplace ? (
              <div className={s.searchParams}>
                <div className={s.filter}>FILTERS</div>
                <Tooltip title="Search for posts">
                  <button
                    className={isPost ? s.active : s.inactive}
                    onClick={() => changePostValue(true)}
                  >
                    <img alt="post Icon" src={isPost ? PostIcon : PostOutline} />
                    Posts
                  </button>
                </Tooltip>
              </div>
            ) : (
              <div className={s.searchParams}>
                <div className={s.filter}>FILTERS</div>
                <Tooltip title="Search for people">
                  <button
                    className={!isPost ? s.active : s.inactive}
                    onClick={() => changePostValue(false)}
                  >
                    <img alt="people Icon" src={isPost ? PeopleOutline : PeopleIcon} />
                    People
                  </button>
                </Tooltip>
                <Tooltip title="Search for posts">
                  <button
                    className={isPost ? s.active : s.inactive}
                    onClick={() => changePostValue(true)}
                  >
                    <img alt="post Icon" src={isPost ? PostIcon : PostOutline} />
                    Post
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const Mobile = () => {
    return (
      <div ref={wrapper} className={s.outerBox}>
        <img
          src={arrowBackward}
          alt="arrow icon"
          className={s.arrowMobile}
          onClick={() => setSearchMobile(false)}
        />
        <div className={s.searchBarMobile}>
          <div>
            <input
              autoFocus={true}
              type="search"
              id="searchInput"
              placeholder={isMarketplace ? "Search in marketplace" : "Search Here"}
              value={inputVal}
              onChange={onInputChange}
              className={s.inputBarMobile}
            />
            {inputValue.length > 0 ? (
              <button onClick={clearInput} className={s.searchButton}></button>
            ) : null}
          </div>
          {isMarketplace ? (
            <div className={s.searchParamsMobile}>
              <div className={s.filterMobile}>FILTERS</div>
              <Tooltip title="Search for Rent posts">
                <button
                  className={isPost ? s.activeMobile : s.inactiveMobile}
                  onClick={() => changePostValue(true)}
                >
                  <img
                    alt="post Icon"
                    height={14}
                    width={14}
                    src={isPost ? PostIcon : PostOutline}
                  />
                  Posts
                </button>
              </Tooltip>
            </div>
          ) : (
            <div className={s.searchParamsMobile}>
              <div className={s.filterMobile}>FILTERS</div>
              <Tooltip title="Search for people">
                <button
                  className={!isPost ? s.activeMobile : s.inactiveMobile}
                  onClick={() => changePostValue(false)}
                >
                  <img
                    alt="people Icon"
                    height={20}
                    width={20}
                    src={isPost ? PeopleOutline : PeopleIcon}
                  ></img>
                  People
                </button>
              </Tooltip>
              <Tooltip title="Search for posts">
                <button
                  className={isPost ? s.activeMobile : s.inactiveMobile}
                  onClick={() => changePostValue(true)}
                >
                  <img
                    alt="post Icon"
                    height={14}
                    width={14}
                    src={isPost ? PostIcon : PostOutline}
                  />
                  Posts
                </button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  };

  return <>{isMobile ? <Mobile /> : <Desktop />}</>;
}
