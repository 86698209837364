import React from 'react';
import Slider from 'react-slick';

// Components
import Login from './login';

// Images
import Logo from '../../assets/img/logo.png';
import CoverImg from '../../assets/img/left.svg';
import Geminilogo from '../../assets/img/Gemini logo.png';
import Gembooklogo from '../../assets/img/Gembooklogo.png';
import GembookFlyer1 from '../../assets/img/Gembook Flyer 1.svg';
import GembookFlyer2 from '../../assets/img/Gembook Flyer2.svg';
import GembookFlyer3 from '../../assets/img/Gembook Flyer3.svg';
import Line1 from '../../assets/img/Line 1.png';

const carouselSettings = {
  infinite: true,
  speed: 750,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  arrows: false
};

const Auth = ({ mail, setMail }) => {
  const getCurrentyear = () => {
    return new Date().getFullYear();
  };

  return (
    <div className="container-fluid d-flex auth">
      <div className="w-50 cover-imgwrap" style={{ position: 'relative' }}>
        <Slider {...carouselSettings}>
        <div>
            <img className="cover-img" src={CoverImg} alt="cover" />
          </div>

          <div>
            <img className="cover-img" src={GembookFlyer1} alt="flyer" />
          </div>
          <div>
            <img className="cover-img" src={GembookFlyer2} alt="flyer" />
          </div>
          <div>
            <img className="cover-img" src={GembookFlyer3} alt="flyer" />
          </div>
         
        </Slider>
      </div>

      <div className="w-50 ml-auto d-flex flex-column login-s" id="login_control">
        <div className="my-auto ">
          <div className="logo d-flex justify-content-center pt-5  ">
            <div style={{ position: 'relative', top: '60px', left: '-10px' }}>
              <img src={Gembooklogo} height="70" width="200" alt="Gembook logo" />
            </div>
          </div>
          <p className="info-2 text-gem">Gemini’s very own social media</p>

          <div className="d-flex justify-content-center">
            <Login display={true} mail={mail} setMail={setMail} />
          </div>
          <div
            className="d-flex align-items-center justify-content-center mt-5 "
            style={{ position: 'relative', top: '50px' }}
          >
            <img src={Line1} alt="line" className="line1" />
            <img src={Geminilogo} height="40" alt="new logo" className="mx-3" />
            <img src={Line1} alt="line" className="line1" />
          </div>
        </div>

        <div className="mt-auto text-center footer">
          Copyright © {getCurrentyear()} Gemini Solutions. All Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Auth;
