import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import cns from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import jwtDecode from 'jwt-decode'; // Import jwt-decode for decoding the access token

// ActionCreators
import { getToolLinks } from '../../../redux/actionCreators/toolLinks.actionCreator';
import { getPostTypes } from '../../../redux/actionCreators/postTypes.actionCreator';

// Icons
import ideaIcon from '../../../assets/icons/idea.svg';
import sideArrow from '../../../assets/icons/Sidearrow.svg';
import feedIcon from '../../../assets/icons/news feed.png';
import frameIcon from '../../../assets/icons/Frame.svg';
import clubIcon from '../../../assets/icons/clubs.svg';
import dashboardIcon from '../../../assets/icons/dashboard.png';
import publicationsIcon from '../../../assets/icons/publicationsIcon.svg';

// SCSS
import s from './sidebar.module.scss';
import { withRouter } from 'react-router-dom';
import { Business, Storefront } from '@material-ui/icons';

class Menu extends Component {
  state = {
    activeMenu: 2,
    clicked: window.location.hash !== '#/dashboard' ? false : true,
    clubs: window.location.hash !== '#/clubs' ? false : true,
    marketplace: window.location.hash !== '#/marketplace/market-place-buy-sell-post' ? false : true,
    publications: window.location.hash !== '#/publications/pollux' ? false : true,
    hrdashboard: window.location.hash !== '#/hr-dashboard' ? false : true,
    className: '',
    reload: false
  };

  // Allowed groups for HR Dashboard
  allowedGroups = ['Sec_Gembook_Devs', 'Sec_Prod_ATS_DC_Chairs', 'Sec_Prod_ATS_EC_Chairs'];

  componentDidMount() {
    this.props.getToolLinks();
  }

  setActiveMenu = (idx) => {
    this.setState({
      activeMenu: this.state.activeMenu === idx ? null : idx
    });
  };

  handleNavigation = (path) => {
    if (window.location.hash !== `#/${path}`) {
      this.props.history.push(`/${path}`);
    } else {
      window.location.reload();
    }
  };

 // Decode token and check group membership or department
canAccessHRDashboard = () => {
  const token = localStorage.getItem('accessToken'); 
  if (token) {
    try {
      const decodedToken = jwtDecode(token); 
      const userGroups = decodedToken.groups || []; 
      const department = decodedToken.department || ''; 

      const isHRDepartment = department === 'Human Resource';
      const isAllowedGroup = this.allowedGroups.some((group) => userGroups.includes(group));

      // Return true if the user is in HR department or belongs to allowed groups
      return isHRDepartment || isAllowedGroup;
    } catch (error) {
      console.error('Failed to decode token:', error);
      return false;
    }
  }
  return false;
};


  render() {
    const showProfileSection =
      window.location.hash !== '#/profile' || this.props?.inputVal?.length > 0;

    const canAccessHRDashboard = this.canAccessHRDashboard();

    return (
      <ul className={cns(s.menu, showProfileSection ? '' : 'mt-4')}>
        {showProfileSection && (
          <div className={s.profileInfo}>
            <img
              onClick={() => this.handleNavigation('profile')}
              src={this.props.profileDetails?.imageURL}
            />
            <p className={s.name} onClick={() => this.handleNavigation('profile')}>
              {this.props.profileDetails?.details?.name}
            </p>
            <p className={s.desig}>{this.props.profileDetails?.details?.designation}</p>
          </div>
        )}
        <li
          className={this.state.clicked ? s.menuItem : ''}
          onClick={() => this.handleNavigation('dashboard')}
        >
          <div className="menu-item-detail d-flex align-items-center">
            <img src={feedIcon} className={cns(s.icon)} alt="feed icon" height={24} width={24} />
            <span className={s.title}>News Feeds</span>
          </div>
        </li>
        <li
          className={this.state.clubs ? s.menuItem : ''}
          onClick={() => this.handleNavigation('clubs')}
        >
          <div className="menu-item-detail d-flex align-items-center">
            <img src={clubIcon} className={cns(s.icon)} alt="club icon" height={24} width={24} />
            <span className={s.title}>Clubs</span>
          </div>
        </li>

        <li
          className={this.state.marketplace ? s.menuItem : ''}
          onClick={() => this.handleNavigation('marketplace/market-place-buy-sell-post')}
        >
          <div className="menu-item-detail d-flex align-items-center">
            <Storefront style={{ marginRight: '0.5rem', fontSize: 25 }} />
            <span className={s.title}> Market Place</span>
          </div>
        </li>

        {/* Conditionally render the HR Dashboard menu item */}
        {canAccessHRDashboard && (
          <li
            className={this.state.hrdashboard ? s.menuItem : ''}
            onClick={() => this.handleNavigation('hr-dashboard')}
          >
            <div className="menu-item-detail d-flex align-items-center">
              <img
                src={dashboardIcon}
                className={cns(s.icon)}
                alt="dashboard icon"
                height={24}
                width={24}
              />
              <span className={s.title}>HR Dashboard</span>
            </div>
          </li>
        )}

        {/* ------------------Publications-------------------------- */}
        <li
          className={this.state.publications ? s.menuItem : ''}
          onClick={() => this.handleNavigation('publications/pollux')}
        >
          <div className="menu-item-detail d-flex align-items-center">
            <img
              src={publicationsIcon}
              className={cns(s.icon, 'ml-1')}
              alt="publications icon"
              width={18}
              style={{ marginRight: '10px' }}
            />
            <span className={cns(s.title)}>Publications</span>
          </div>
          {/* <div className="expansion-icon">
            <img
              src={sideArrow}
              className={(s.arrow, this.state.activeMenu === 3 ? 'rotate-90' : '')}
              alt="arrow"
              height={15}
              width={15}
            />
          </div> */}
        </li>

        {/* <div>
          {this.state.activeMenu === 3 && (
            <li className={s.pl60} onClick={() => this.handlePollux()}>
              <Tooltip title={'Pollux'}>
                <a className={s.toolLink} rel="noopener noreferrer">
                  <span className={cns(s.title, 'd-flex align-items-center')}>
                    <Business />

                    <span className="mx-2">{'Pollux'}</span>
                  </span>
                </a>
              </Tooltip>
            </li>
          )}
        </div> */}

        <li onClick={() => this.setActiveMenu(2)}>
          <div className="menu-item-detail d-flex align-items-center">
            <img src={frameIcon} alt="frame icon" className={s.icon} height={24} width={24} />
            <span className={s.title}>Other Portals</span>
          </div>
          <div className="expansion-icon ">
            <img
              src={sideArrow}
              className={(s.arrow, this.state.activeMenu === 2 ? 'rotate-90' : '')}
              alt="arrow"
              height={10}
              width={10}
            />
          </div>
        </li>
        <div className={s.toolWrapper}>
          {this.state.activeMenu === 2
            ? [...this.props.toolLinks].reverse().map((toolLink, idx) => {
                let name = toolLink.toolName.split(' ');
                let vars =
                  name.length > 1 ? toolLink.toolName.replace(' ', '') + 'Icon' : name[0] + 'Icon';
                return (
                  <li key={idx} className={s.pl60}>
                    <Tooltip title={toolLink.toolDesc}>
                      <a
                        href={toolLink.toolLink}
                        className={s.toolLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={cns(s.title, 'd-flex align-items-center')}>
                          <img
                            alt="menu icon"
                            src={toolLink.iconFileName}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = ideaIcon;
                            }}
                            width={24}
                            height={24}
                            className="mr-2"
                          />

                          {toolLink.toolName}
                        </span>
                      </a>
                    </Tooltip>
                  </li>
                );
              })
            : null}
        </div>
      </ul>
    );
  }
}

function mapStateToProps(state) {
  return {
    toolLinks: state.toolLinksReducer.links,
    postTypes: state.postTypesReducer.postTypes,
    profileDetails: state.storageReducer.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getToolLinks,
      getPostTypes
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));
