import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GET_HRDASHBOARD_LOADING,
  GET_HRDASHBOARD_SUCCESS,
  GET_HRDASHBOARD_FAILURE,
  GET_REPORT_LOADING,
  GET_REPORT_FAILURE,
  GET_REPORT_SUCCESS,
  GET_SEARCH_REPORT_SUCCESS,
  GET_SEARCH_REPORT_LOADING,
  GET_SEARCH_REPORT_FAILURE,
  GET_TOTAL_COUNTS_SUCCESS,
  RESET_REPORT
} from '../actions/actions';

// Fetch council list based on council type
export const getCouncilMap = (councilType) => {
  return (dispatch) => {
    dispatch({ type: GET_HRDASHBOARD_LOADING });

    axios
      .get(
        `https://devapi.geminisolutions.com/gembook/reports/getCouncilMap`, // using localhost
        // 'https://devapi.geminisolutions.com/gembook/reports/getCouncilMap',
        {
          headers: { accept: '*/*' }
        }
      )
      .then((response) => {
        // Convert the response object to an array of objects
        //   const councilArray = Object.entries(response.data).map(([id, name]) => ({
        //     id: Number(id), // Convert the ID to a number
        //     name: name
        //   }));

        dispatch({
          type: GET_HRDASHBOARD_SUCCESS,
          payload: { details: response.data }
        });
      })
      .catch((error) => {
        toast.error('Could not fetch data');
        dispatch({
          type: GET_HRDASHBOARD_FAILURE,
          payload: { error: error.message }
        });
      });
  };
};
export const getDownloadReport = (
  // councilType,
  councils = [],
  startDate,
  endDate,
  reportType,
  techType,
  // email = 'shanu.chauhan@geminisolutions.com',
  isActive,
  isDownload = true
) => {
  return (dispatch) => {
    dispatch({ type: GET_REPORT_LOADING });

    const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : null;
    const formattedEndDate = endDate ? new Date(endDate)?.toISOString().split('T')[0] : null;
    // const formattedStartDate = '2023-11-10'
    // const formattedEndDate ='2024-11-02'

    const url = `https://devapi.geminisolutions.com/gembook/reports/generateReport`;
    // const url = `https://devapi.geminisolutions.com/gembook/reports?pageNum=${pageNum}&email=${email}&councilType=${councilType}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&reportTypes=${reportType}&isReportRequired=${isDownload}`;
   
    // The request body contains the selected councils
    const requestBody = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      councils: councils,
      reportTypes: reportType, //multiple report types for download report 
      techTypes: techType ,
      isActive: isActive
    }

    return axios
      .post(url, requestBody, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        },
        responseType: isDownload ? 'blob' : 'json' // 'blob' for download, 'json' for search
      })
      .then((response) => {
        if (isDownload) {
          // Handle downloading the report file
          const blob = new Blob([response.data], { type: response.data.type });
          const downloadUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = `report_${reportType}.xlsx`; // Dynamic filename
          link.click();
          window.URL.revokeObjectURL(downloadUrl);
        } else {
          // Handle search results for table (update Redux store)
          dispatch({
            type: GET_REPORT_SUCCESS,
            payload: { details: response.data } // Assuming response.data contains table data
          });
          // const { totalEc, totalDc, totalEmp } = response.data.content[0] || {};

          // dispatch({
          //   type: GET_TOTAL_COUNTS_SUCCESS,
          //   payload: { totalEc, totalDc, totalEmp } // Store counts in the Redux store
          // });
        }
        return response.data; // Return data to allow chaining
      })
      .catch((error) => {
        dispatch({
          type: GET_REPORT_FAILURE,
          payload: { error: error.message }
        });
        
        if (error.response?.status === 400) {
          toast.warn('No data found');
        } else {
          toast.error('An error occurred while fetching the report data.');
        }

        throw error; // Throw error to allow chaining
      });
  };
};

export const getSearchReport = (councils = [], startDate, endDate, reportType, techType, isActive) => {
  return (dispatch) => {
    dispatch({ type: GET_SEARCH_REPORT_LOADING });

    const formattedStartDate = startDate ? new Date(startDate).toISOString().split('T')[0] : null;
    const formattedEndDate = endDate ? new Date(endDate).toISOString().split('T')[0] : null;

    const url = `https://devapi.geminisolutions.com/gembook/reports/getData`;
    // const url = `http://localhost:7000/gembook/reports/getData`;
    // Construct the URL for the search request
   

    // Request body for techType
    const requestBody = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      councils: councils,
      reportTypes: reportType,  
      techTypes: techType ,
      isActive: isActive
    }
  
    return axios
      .post(url, requestBody, {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json'
        },
       
        
        responseType: 'json' // Only handling JSON for search results
      })
      .then((response) => {
        // Log the response to check its structure
        dispatch({
          type: RESET_REPORT
        });
        dispatch({
          type: GET_SEARCH_REPORT_SUCCESS,
          payload: { details: response.data }
        });
      })

      .catch((error) => {
        dispatch({
          type: GET_SEARCH_REPORT_FAILURE,
          payload: { error: error.message }
        });
        toast.error('An error occurred while fetching the report data.');
        throw error;
      });
  };
};


export const getResetReport = () => {
  return (dispatch) => {
    dispatch({ type:RESET_REPORT});

  }}   
    

