export const GET_POST_SUCCESS = 'GET_POST_SUCCESS';
export const GET_POST_LOADING = 'GET_POST_LOADING';
export const GET_POST_FAILURE = 'GET_POST_FAILURE';
export const GET_POST_SSE_SUCCESS = 'GET_POST_SSE_SUCCESS';
export const GET_POST_SSE_LOADING = 'GET_POST_SSE_LOADING';
export const GET_POST_SSE_FAILURE = 'GET_POST_SSE_FAILURE';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
export const ADD_POST_LOADING = 'ADD_POST_LOADING';
export const ADD_POST_FAILURE = 'ADD_POST_FAILURE';

export const GET_POST_BY_ID_SUCCESS = 'GET_POST_BY_ID_SUCCESS';
export const GET_POST_BY_ID_LOADING = 'GET_POST_BY_ID_LOADING';
export const GET_POST_BY_ID_FAILURE = 'GET_POST_BY_ID_FAILURE';

export const GET_MARKET_POST_BY_ID_LOADING = 'GET_MARKET_POST_BY_ID_LOADING';

export const GET_POST_BY_TYPE_ID_SUCCESS = 'GET_POST_BY_TYPE_ID_SUCCESS';
export const GET_POST_BY_TYPE_ID_LOADING = 'GET_POST_BY_TYPE_ID_LOADING';
export const GET_POST_BY_TYPE_ID_FAILURE = 'GET_POST_BY_TYPE_ID_FAILURE';

export const GET_ORGANISERS_SUCCESS = 'GET_ORGANISERS_SUCCESS';
export const GET_ORGANISERS_LOADING = 'GET_ORGANISERS_LOADING';
export const GET_ORGANISERS_FAILURE = 'GET_ORGANISERS_FAILURE';

export const GET_PAST_EVENTS_SUCCESS = 'GET_PAST_EVENTS_SUCCESS';
export const GET_PAST_EVENTS_LOADING = 'GET_PAST_EVENTS_LOADING';
export const GET_PAST_EVENTS_FAILURE = 'GET_PAST_EVENTS_FAILURE';

export const REFRESH_POST_BY_ID_SUCCESS = 'REFRESH_POST_BY_ID_SUCCESS';
export const DELETE_POST_SUCCESS_HASHTAG = 'DELETE_POST_SUCCESS_HASHTAG';
export const REFRESH_MARKET_POST_BY_ID_SUCCESS = 'REFRESH_MARKET_POST_BY_ID_SUCCESS';
export const REFRESH_MARKET_POST_BY_ID = 'REFRESH_MARKET_POST_BY_ID';

export const UPDATE_POST_BY_ID_SUCCESS = 'UPDATE_POST_BY_ID_SUCCESS';
export const UPDATE_POST_BY_ID_LOADING = 'UPDATE_POST_BY_ID_LOADING';

export const GET_TOOLS_SUCCESS = 'GET_TOOLS_SUCCESS';
export const GET_TOOLS_LOADING = 'GET_TOOLS_LOADING';
export const GET_TOOLS_FAILURE = 'GET_TOOLS_FAILURE';

export const GET_POST_TYPE_SUCCESS = 'GET_POST_TYPE_SUCCESS';
export const GET_POST_TYPE_LOADING = 'GET_POST_TYPE_LOADING';
export const GET_POST_TYPE_FAILURE = 'GET_POST_TYPE_FAILURE';

export const GET_FILTERED_POSTS_SUCCESS = 'GET_FILTERED_POSTS_SUCCESS';
export const GET_FILTERED_POSTS_LOADING = 'GET_FILTERED_POSTS_LOADING';
export const GET_FILTERED_POSTS_FAILURE = 'GET_FILTERED_POSTS_FAILURE';

export const SAVE_LIKE_FOR_POST_LOADING = 'SAVE_LIKE_FOR_POST_LOADING';
export const SAVE_LIKE_FOR_POST_SUCCESS = 'SAVE_LIKE_FOR_POST_SUCCESS';
export const SAVE_LIKE_FOR_POST_FAILURE = 'SAVE_LIKE_FOR_POST_FAILURE';

export const GET_LIKE_FOR_POST_LOADING = 'GET_LIKE_FOR_POST_LOADING';
export const GET_LIKE_FOR_POST_SUCCESS = 'GET_LIKE_FOR_POST_SUCCESS';
export const GET_LIKE_FOR_POST_FAILURE = 'GET_LIKE_FOR_POST_FAILURE';
export const DELETE_LIKE_FOR_POST_LOADING = 'DELETE_LIKE_FOR_POST_LOADING';
export const DELETE_LIKE_FOR_POST_SUCCESS = 'DELETE_LIKE_FOR_POST_SUCCESS';
export const DELETE_LIKE_FOR_POST_FAILURE = 'DELETE_LIKE_FOR_POST_FAILURE';

export const SAVE_LIKE_FOR_MARKET_POST_LOADING = 'SAVE_LIKE_FOR_MARKET_POST_LOADING';
export const SAVE_LIKE_FOR_MARKET_POST_SUCCESS = 'SAVE_LIKE_FOR_MARKET_POST_SUCCESS';
export const SAVE_LIKE_FOR_MARKET_POST_FAILURE = 'SAVE_LIKE_FOR_MARKET_POST_FAILURE';
export const GET_LIKE_FOR_MARKET_POST_LOADING = 'GET_LIKE_FOR_MARKET_POST_LOADING';
export const GET_LIKE_FOR_MARKET_POST_SUCCESS = 'GET_LIKE_FOR_MARKET_POST_SUCCESS';
export const GET_LIKE_FOR_MARKET_POST_FAILURE = 'GET_LIKE_FOR_MARKET_POST_FAILURE';
export const DELETE_LIKE_FOR_MARKET_POST_LOADING = 'DELETE_LIKE_FOR_MARKET_POST_LOADING';
export const DELETE_LIKE_FOR_MARKET_POST_SUCCESS = 'DELETE_LIKE_FOR_MARKET_POST_SUCCESS';
export const DELETE_LIKE_FOR_MARKET_POST_FAILURE = 'DELETE_LIKE_FOR_MARKET_POST_FAILURE';

export const UPDATE_LIKE_FOR_MARKET_POST_LOADING = 'UPDATE_LIKE_FOR_MARKET_POST_LOADING';
export const UPDATE_LIKE_FOR_MARKET_POST_SUCCESS = 'UPDATE_LIKE_FOR_MARKET_POST_SUCCESS';
export const UPDATE_LIKE_FOR_MARKET_POST_FAILURE = 'UPDATE_LIKE_FOR_MARKET_POST_FAILURE';

export const UPDATE_LIKE_FOR_POST_LOADING = 'UPDATE_LIKE_FOR_POST_LOADING';
export const UPDATE_LIKE_FOR_POST_SUCCESS = 'UPDATE_LIKE_FOR_POST_SUCCESS';
export const UPDATE_LIKE_FOR_POST_FAILURE = 'UPDATE_LIKE_FOR_POST_FAILURE';

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_LOADING = 'ADD_COMMENT_LOADING';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_LOADING = 'DELETE_COMMENT_LOADING';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
export const LIKE_COMMENT_SUCCESS = 'LIKE_COMMENT_SUCCESS';
export const LIKE_COMMENT_LOADING = 'LIKE_COMMENT_LOADING';
export const LIKE_COMMENT_FAILURE = 'LIKE_COMMENT_FAILURE';

export const ADD_MARKET_COMMENT_SUCCESS = 'ADD_MARKET_COMMENT_SUCCESS';
export const ADD_MARKET_COMMENT_LOADING = 'ADD_MARKET_COMMENT_LOADING';
export const ADD_MARKET_COMMENT_FAILURE = 'ADD_MARKET_COMMENT_FAILURE';
export const DELETE_MARKET_COMMENT_SUCCESS = 'DELETE_MARKET_COMMENT_SUCCESS';
export const DELETE_MARKET_COMMENT_LOADING = 'DELETE_MARKET_COMMENT_LOADING';
export const DELETE_MARKET_COMMENT_FAILURE = 'DELETE_MARKET_COMMENT_FAILURE';
export const LIKE_MARKET_COMMENT_SUCCESS = 'LIKE_MARKET_COMMENT_SUCCESS';
export const LIKE_MARKET_COMMENT_LOADING = 'LIKE_MARKET_COMMENT_LOADING';
export const LIKE_MARKET_COMMENT_FAILURE = 'LIKE_MARKET_COMMENT_FAILURE';

export const UNLIKE_COMMENT_SUCCESS = 'UNLIKE_COMMENT_SUCCESS';
export const UNLIKE_COMMENT_LOADING = 'UNLIKE_COMMENT_LOADING';
export const UNLIKE_COMMENT_FAILURE = 'UNLIKE_COMMENT_FAILURE';

export const GET_EVENT_LOADING = 'GET_EVENT_LOADING';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE';

export const GET_SUBCHANNELS_LOADING = 'GET_SUBCHANNELS_LOADING';
export const GET_SUBCHANNELS_SUCCESS = 'GET_SUBCHANNELS_SUCCESS';
export const GET_SUBCHANNELS_FAILURE = 'GET_SUBCHANNELS_FAILURE';

export const ADD_EVENT_LOADING = 'ADD_EVENT_LOADING';
export const ADD_EVENT_SUCCESS = 'ADD_EVENT_SUCCESS';
export const ADD_EVENT_FAILURE = 'ADD_EVENT_FAILURE';

export const EDIT_EVENT_LOADING = 'EDIT_EVENT_LOADING';
export const EDIT_EVENT_SUCCESS = 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_FAILURE = 'EDIT_EVENT_FAILURE';

export const DELETE_EVENT_LOADING = 'DELETE_EVENT_LOADING';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const GET_EVENT_BYID_LOADING = 'GET_EVENT_BYID_LOADING';
export const GET_EVENT_BYID_SUCCESS = 'GET_EVENT_BYID_SUCCESS';
export const GET_EVENT_BYID_FAILURE = 'GET_EVENT_BYID_FAILURE';

export const GET_ALL_POLLS_LOADING = 'GET_ALL_POLLS_LOADING';
export const GET_ALL_POLLS_SUCCESS = 'GET_ALL_POLLS_SUCCESS';
export const GET_ALL_POLLS_FAILURE = 'GET_ALL_POLLS_FAILURE';

export const GET_RESPONSE_LOADING = 'GET_RESPONSE_LOADING';
export const GET_RESPONSE_SUCCESS = 'GET_RESPONSE_SUCCESS';
export const GET_RESPONSE_FAILURE = 'GET_RESPONSE_FAILURE';

export const GET_POLL_BY_ID_LOADING = 'GET_POLL_BY_ID_LOADING';
export const GET_POLL_BY_ID_SUCCESS = 'GET_POLL_BY_ID_SUCCESS';
export const GET_POLL_BY_ID_FAILURE = 'GET_POLL_BY_ID_FAILURE';

export const ADD_VOTES_LOADING = 'ADD_VOTES_LOADING';
export const ADD_VOTES_SUCCESS = 'ADD_VOTES_SUCCESS';
export const ADD_VOTES_FAILURE = 'ADD_VOTES_FAILURE';
export const DELETE_POLL_LOADING = 'DELETE_POLLS_LOADING';
export const DELETE_POLL_SUCCESS = 'DELETE_POLLS_SUCCESS';
export const DELETE_POLL_FAILURE = 'DELETE_POLLS_FAILURE';
export const EDIT_POLL_LOADING = 'EDIT_POLLS_LOADING';
export const EDIT_POLL_SUCCESS = 'EDIT_POLLS_SUCCESS';
export const EDIT_POLL_FAILURE = 'EDIT_POLLS_FAILURE';

export const GET_AWARDS_LOADING = 'GET_AWARDS_LOADING';
export const GET_AWARDS_SUCCESS = 'GET_AWARDS_SUCCESS';
export const GET_AWARDS_FAILURE = 'GET_AWARDS_FAILURE';
export const GET_AWARDS_WINNER_LOADING = 'GET_AWARDS_WINNER_LOADING';
export const GET_AWARDS_WINNER_SUCCESS = 'GET_AWARDS_WINNER_SUCCESS';
export const GET_AWARDS_WINNER_FAILURE = 'GET_AWARDS_WINNER_FAILURE';
export const GET_AWARDS_WIN_LOADING = 'GET_AWARDS_WIN_LOADING';
export const GET_AWARDS_WIN_SUCCESS = 'GET_AWARDS_WIN_SUCCESS';
export const GET_AWARDS_WIN_FAILURE = 'GET_AWARDS_WIN_FAILURE';
export const ADD_AWARD_LOADING = 'ADD_AWARD_LOADING';
export const ADD_AWARD_SUCCESS = 'ADD_AWARD_SUCCESS';
export const ADD_AWARD_FAILURE = 'ADD_AWARD_FAILURE';
export const DELETE_AWARD_LOADING = 'DELETE_AWARD_LOADING';
export const DELETE_AWARD_SUCCESS = 'DELETE_AWARD_SUCCESS';
export const DELETE_AWARD_FAILURE = 'DELETE_AWARD_FAILURE';
//Get awards by email
export const GET_AWARDS_BY_EMAIL_LOADING = 'GET_AWARDS_BY_EMAIL_LOADING';
export const GET_AWARDS_BY_EMAIL_SUCCESS = 'GET_AWARDS_BY_EMAIL_SUCCESS';
export const GET_AWARDS_BY_EMAIL_FAILURE = 'GET_AWARDS_BY_EMAIL_FAILURE';

// Profile GET Actions
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';

export const GET_PROFILE_BY_EMPID_SUCCESS = 'GET_PROFILE_BY_EMPID_SUCCESS ';
export const GET_PROFILE_BY_EMPID_LOADING = 'GET_PROFILE_BY_EMPID_LOADING ';
export const GET_PROFILE_BY_EMPID_FAILURE = 'GET_PROFILE_BY_EMPID_FAILURE ';

export const GET_SEARCHED_PROFILES_DETAIL_SUCCESS = 'GET_SEARCHED_PROFILES_DETAIL_SUCCESS';
export const GET_SEARCHED_PROFILES_DETAIL_LOADING = 'GET_SEARCHED_PROFILES_DETAIL_LOADING';
export const GET_SEARCHED_PROFILES_DETAIL_FAILURE = 'GET_SEARCHED_PROFILES_DETAIL_FAILURE';

export const GET_SEARCHED_POST_SUCCESS = 'GET_SEARCHED_POST_SUCCESS';
export const GET_SEARCHED_POST_LOADING = 'GET_SEARCHED_POST_LOADING';
export const GET_SEARCHED_POST_FAILURE = 'GET_SEARCHED_POST_FAILURE';

// Profile Edit HOBBIES
export const EDIT_HOBBIES_LOADING = 'EDIT_HOBBIES_LOADING';
export const EDIT_HOBBIES_SUCCESS = 'EDIT_HOBBIES_SUCCESS';
export const EDIT_HOBBIES_FAILURE = 'EDIT_HOBBIES_FAILURE';

// Profile Edit SKILL
export const EDIT_SKILL_LOADING = 'EDIT_SKILL_LOADING';
export const EDIT_SKILL_SUCCESS = 'EDIT_SKILL_SUCCESS';
export const EDIT_SKILL_FAILURE = 'EDIT_SKILL_FAILURE';

// Profile Edit ABOUT
export const EDIT_ABOUT_LOADING = 'EDIT_ABOUT_LOADING';
export const EDIT_ABOUT_SUCCESS = 'EDIT_ABOUT_SUCCESS';
export const EDIT_ABOUT_FAILURE = 'EDIT_ABOUT_FAILURE';

// export const EDIT_SKILL_IN_PROFILE = "EDIT_SKILL_IN_PROFILE";

// Profile Edit Certification
export const EDIT_CERTIFICATION_LOADING = 'EDIT_CERTIFICATION_LOADING';
export const EDIT_CERTIFICATION_SUCCESS = 'EDIT_CERTIFICATION_SUCCESS';
export const EDIT_CERTIFICATION_FAILURE = 'EDIT_CERTIFICATION_FAILURE';

export const GET_CERTIFICATIONS_LOADING = 'GET_CERTIFICATIONS_LOADING';
export const GET_CERTIFICATIONS_SUCCESS = 'GET_CERTIFICATIONS_SUCCESS';
export const GET_CERTIFICATIONS_FAILURE = 'GET_CERTIFICATIONS_FAILURE';

export const ADD_CERTIFICATION_LOADING = 'ADD_CERTIFICATION_LOADING';
export const ADD_CERTIFICATION_SUCCESS = 'ADD_CERTIFICATION_SUCCESS';
export const ADD_CERTIFICATION_FAILURE = 'ADD_CERTIFICATION_FAILURE';
export const DELETE_CERTIFICATION = 'DELETE_CERTIFICATION';

export const GET_TECH_TYPES_LOADING = 'GET_TECH_TYPES_LOADING';
export const GET_TECH_TYPES_SUCCESS = 'GET_TECH_TYPES_SUCCESS';
export const GET_TECH_TYPES_FAILURE = 'GET_TECH_TYPES_FAILURE';

// Profile Edit Achivement
export const EDIT_ACHIEVEMENT_LOADING = 'EDIT_ACHIEVEMENT_LOADING';
export const EDIT_ACHIEVEMENT_SUCCESS = 'EDIT_ACHIEVEMENT_SUCCESS';
export const EDIT_ACHIEVEMENT_FAILURE = 'EDIT_ACHIEVEMENT_FAILURE';

// Profile Edit Training
export const EDIT_TRAINING_LOADING = 'EDIT_TRAINING_LOADING';
export const EDIT_TRAINING_SUCCESS = 'EDIT_TRAINING_SUCCESS';
export const EDIT_TRAINING_FAILURE = 'EDIT_TRAINING_FAILURE';

// Profile Edit Project
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const EDIT_PROJECT_LOADING = 'EDIT_PROJECT_LOADING';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';
export const GET_PROJECTS_LOADING = 'GET_INTERNALPROJECT_LOADING';
export const GET_PROJECTS_SUCCESS = 'GET_INTERNALPROJECT_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_INTERNALPROJECT_FAILURE';

export const ADD_PROJECT_LOADING = 'ADD_PROJECT_LOADING';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';
export const DELETE_PROJECT_LOADING = 'DELETE_PROJECT_LOADING';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

//Education
export const GET_EDUCATIONDETAILS_LOADING = 'GET_EDUCATIONDETAILS_LOADING';
export const GET_EDUCATIONDETAILS_SUCCESS = 'GET_EDUCATIONDETAILS_SUCCESS';
export const GET_EDUCATIONDETAILS_FAILURE = 'GET_EDUCATIONDETAILS_FAILURE';

export const ADD_EDUCATIONDETAILS_LOADING = 'ADD_EDUCATIONDETAILS_LOADING';

export const UPDATE_EDUCATIONDETAILS_LOADING = 'UPDATE_EDUCATIONDETAILS_LOADING';

export const DELETE_EDUCATIONDETAILS_LOADING = 'DELETE_EDUCATIONDETAILS_LOADING';
export const DELETE_EDUCATIONDETAILS_SUCCESS = 'DELETE_EDUCATIONDETAILS_SUCCESS';
export const DELETE_EDUCATIONDETAILS_FAILURE = 'DELETE_EDUCATIONDETAILS_FAILURE';

// LogIn Auth

export const GOOGLE_OAUTH2_ERROR = 'GOOGLE_OAUTH2_ERROR';
export const GOOGLE_OAUTH2_SUCCESS = 'GOOGLE_OAUTH2_SUCCESS';

// Delete Post

export const DELETE_POST_LOADING = 'DELETE_POST_LOADING';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';

//DELETE MARKT POST

export const DELETE_MARKET_POST_LOADING = 'DELETE_MARKET_POST_LOADING';
export const DELETE_MARKET_POST_SUCCESS = 'DELETE_MARKET_POST_SUCCESS';
export const DELETE_MARKET_POST_FAILURE = 'DELETE_MARKET_POST_FAILURE';

// EDIT SKILL

export const GET_EMPID_BY_EMAIL_SUCCESS = 'GET_EMPID_BY_EMAIL_SUCCESS';
export const GET_EMPID_BY_EMAIL_LOADING = 'GET_EMPID_BY_EMAIL_LOADING';
export const GET_EMPID_BY_EMAIL_FAILURE = 'GET_EMPID_BY_EMAIL_FAILURE';

// User Details

export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_LOADING = 'GET_USER_BY_ID_LOADING';
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE';

export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_LOADING = 'GET_ALL_USERS_LOADING';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_ALL_EMPLOYEES_SUCCESS = 'GET_ALL_EMPLOYEES_SUCCESS';
export const GET_ALL_EMPLOYEES_LOADING = 'GET_ALL_EMPLOYEES_LOADING';
export const GET_ALL_EMPLOYEES_FAILURE = 'GET_ALL_EMPLOYEES_FAILURE';
//Marketplace users
export const GET_ALL_MARKET_USERS_SUCCESS = 'GET_ALL_MARKET_USERS_SUCCESS';
export const GET_ALL_MARKET_USERS_LOADING = 'GET_ALL_MARKET_USERS_LOADING';
export const GET_ALL_MARKET_USERS_FAILURE = 'GET_ALL_MARKET_USERS_FAILURE';

// HR USERS
export const GET_HR_USER_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_HR_USER_LOADING = 'GET_USER_BY_ID_LOADING';
export const GET_HR_USER_FAILURE = 'GET_USER_BY_ID_FAILURE';
//Anniversary
export const GET_WORKANNIVERSARY_SUCCESS = 'GET_WORKANNIVERSARY_SUCCESS';
export const GET_WORKANNIVERSARY_LOADING = 'GET_WORKANNIVERSARY_LOADING';
export const GET_WORKANNIVERSARY_FAILURE = 'GET_WORKANNIVERSARY_FAILURE';

//Birthdays
export const GET_BIRTHDAYS_SUCCESS = 'GET_BIRTHDAYS_SUCCESS';
export const GET_BIRTHDAYS_LOADING = 'GET_BIRTHDAYS_LOADING';
export const GET_BIRTHDAYS_FAILURE = 'GET_BIRTHDAYS_FAILURE';

//New Members
export const GET_NEWMEMBERS_SUCCESS = 'GET_NEWMEMBERS_SUCCESS';
export const GET_NEWMEMBERS_LOADING = 'GET_NEWMEMBERS_LOADING';
export const GET_NEWMEMBERS_FAILURE = 'GET_NEWMEMBERS_FAILURE';

// Get manager by ID
export const GET_MANAGER_DETAIL_BY_ID_SUCCESS = 'GET_MANAGER_DETAIL_BY_ID_SUCCESS';
export const GET_MANAGER_DETAIL_BY_ID_LOADING = 'GET_MANAGER_DETAIL_BY_ID_LOADING';
export const GET_MANAGER_DETAIL_BY_ID_FAILURE = 'GET_MANAGER_DETAIL_BY_ID_FAILURE';

// Get All Clubs
export const GET_ALLCLUBS_SUCCESS = 'GET_ALLCLUBS_SUCCESS';
export const GET_ALLCLUBS_LOADING = 'GET_ALLCLUBS_LOADING';
export const GET_ALLCLUBS_FAILURE = 'GET_ALLCLUBS_FAILURE';

// Get Club Details
export const GET_CLUB_DETAILS_SUCCESS = 'GET_CLUB_DETAILS_SUCCESS';
export const GET_CLUB_DETAILS_LOADING = 'GET_CLUB_DETAILS_LOADING';
export const GET_CLUB_DETAILS_FAILURE = 'GET_CLUB_DETAILS_FAILURE';

// Get Club Members
export const GET_CLUB_MEMBERS_SUCCESS = 'GET_CLUB_MEMBERS_SUCCESS';
export const GET_CLUB_MEMBERS_LOADING = 'GET_CLUB_MEMBERS_LOADING';
export const GET_CLUB_MEMBERS_FAILURE = 'GET_CLUB_MEMBERS_FAILURE';

// Join Club
export const JOIN_CLUB_SUCCESS = 'JOIN_CLUB_SUCCESS';
export const JOIN_CLUB_LOADING = 'JOIN_CLUB_LOADING';
export const JOIN_CLUB_FAILURE = 'JOIN_CLUB_FAILURE';

//Get members who requested to join club
export const GET_REQUEST_SUCCESS = 'GET_REQUEST_SUCCESS';
export const GET_REQUEST_LOADING = 'GET_REQUEST_LOADING';
export const GET_REQUEST_FAILURE = 'GET_REQUEST_FAILURE';

//Accept/reject request
export const UPDATE_JOINREQUEST_SUCCESS = 'UPDATE_JOINREQUEST_SUCCESS';
export const UPDATE_JOINREQUEST_LOADING = 'UPDATE_JOINREQUEST_LOADING';
export const UPDATE_JOINREQUEST_FAILURE = 'UPDATE_JOINREQUEST_FAILURE';

// Join Request Status
export const JOIN_STATUS_SUCCESS = 'JOIN_STATUS_SUCCESS';
export const JOIN_STATUS_LOADING = 'JOIN_STATUS_LOADING';
export const JOIN_STATUS_FAILURE = 'JOIN_STATUS_FAILURE';

// Leave Club
export const LEAVE_CLUB_SUCCESS = 'LEAVE_CLUB_SUCCESS';
export const LEAVE_CLUB_LOADING = 'LEAVE_CLUB_LOADING';
export const LEAVE_CLUB_FAILURE = 'LEAVE_CLUB_FAILURE';

// Leave Request Status
export const LEAVE_STATUS_SUCCESS = 'LEAVE_STATUS_SUCCESS';
export const LEAVE_STATUS_LOADING = 'LEAVE_STATUS_LOADING';
export const LEAVE_STATUS_FAILURE = 'LEAVE_STATUS_FAILURE';

//Update Role
export const UPDATE_MEMBER_ROLE_SUCCESS = 'UPDATE_MEMBER_ROLE_SUCCESS';
export const UPDATE_MEMBER_ROLE_LOADING = 'UPDATE_MEMBER_ROLE_LOADING';
export const UPDATE_MEMBER_ROLE_FAILURE = 'UPDATE_MEMBER_ROLE_FAILURE';

// Edit Club Description
export const EDIT_CLUB_DESCRIPTION_SUCCESS = 'EDIT_CLUB_DESCRIPTION_SUCCESS';
export const EDIT_CLUB_DESCRIPTION_LOADING = 'EDIT_CLUB_DESCRIPTION_LOADING';
export const EDIT_CLUB_DESCRIPTION_FAILURE = 'EDIT_CLUB_DESCRIPTION_FAILURE';

// Edit Club Cover Image
export const EDIT_COVERIMAGE_SUCCESS = 'EDIT_COVERIMAGE_SUCCESS';
export const EDIT_COVERIMAGE_LOADING = 'EDIT_COVERIMAGE_LOADING';
export const EDIT_COVERIMAGE_FAILURE = 'EDIT_COVERIMAGE_FAILURE';

// Edit Club Profile Image
export const EDIT_PROFILEIMAGE_SUCCESS = 'EDIT_PROFILEIMAGE_SUCCESS';
export const EDIT_PROFILEIMAGE_LOADING = 'EDIT_PROFILEIMAGE_LOADING';
export const EDIT_PROFILEIMAGE_FAILURE = 'EDIT_PROFILEIMAGE_FAILURE';

// Get Employee Address
export const GET_EMPLOYEE_ADDRESS_SUCCESS = 'GET_EMPLOYEE_ADDRESS_SUCCESS';
export const GET_EMPLOYEE_ADDRESS_LOADING = 'GET_EMPLOYEE_ADDRESS_LOADING';
export const GET_EMPLOYEE_ADDRESS_FAILURE = 'GET_EMPLOYEE_ADDRESS_FAILURE';

// Get Notification of being tagged in a post
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_LOADING = 'GET_NOTIFICATIONS_LOADING';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

// Refresh Post
export const GET_REFRESH_POST_SUCCESS = 'GET_REFRESH_POST_SUCCESS';
export const GET_REFRESH_POST_LOADING = 'GET_REFRESH_POST_LOADING';
export const GET_REFRESH_POST_FAILURE = 'GET_REFRESH_POST_FAILURE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const REFRESH_MARKET_POST_BY_ID_DESTINATION = 'REFRESH_MARKET_POST_BY_ID_DESTINATION';

// GET HASHTAGS
export const GET_HASHTAG_SUCCESS = 'GET_HASHTAG_SUCCESS';
export const GET_HASHTAG_LOADING = 'GET_HASHTAG_LOADING';
export const GET_HASHTAG_FAILURE = 'GET_HASHTAG_FAILURE';
export const GET_TRENDING_HASHTAG_SUCCESS = 'GET_TRENDING_HASHTAG_SUCCESS';
export const GET_TRENDING_HASHTAG_LOADING = 'GET_TRENDING_HASHTAG_LOADING';
export const GET_TRENDING_HASHTAG_FAILURE = 'GET_TRENDING_HASHTAG_FAILURE';

//Get all custom tags
export const GET_CUSTOM_TAGS_SUCCESS = 'GET_CUSTOM_TAGS_SUCCESS';
export const GET_CUSTOM_TAGS_LOADING = 'GET_CUSTOM_TAGS_LOADING';
export const GET_CUSTOM_TAGS_FAILURE = 'GET_CUSTOM_TAGS_FAILURE';

//Get custom tag data by tag id
export const GET_CUSTOM_TAG_DATA_SUCCESS = 'GET_CUSTOM_TAG_DATA_SUCCESS';
export const GET_CUSTOM_TAG_DATA_LOADING = 'GET_CUSTOM_TAG_DATA_LOADING';
export const GET_CUSTOM_TAG_DATA_FAILURE = 'GET_CUSTOM_TAG_DATA_FAILURE';

//Create custom tags
export const CREATE_CUSTOM_TAGS_SUCCESS = 'CREATE_CUSTOM_TAGS_SUCCESS';
export const CREATE_CUSTOM_TAGS_LOADING = 'CREATE_CUSTOM_TAGS_LOADING';
export const CREATE_CUSTOM_TAGS_FAILURE = 'CREATE_CUSTOM_TAGS_FAILURE';

//Delete custom tags
export const DELETE_CUSTOM_TAGS_SUCCESS = 'DELETE_CUSTOM_TAGS_SUCCESS';
export const DELETE_CUSTOM_TAGS_LOADING = 'DELETE_CUSTOM_TAGS_LOADING';
export const DELETE_CUSTOM_TAGS_FAILURE = 'DELETE_CUSTOM_TAGS_FAILURE';

//market place
export const ADD_MARKETPLACE_POST_SUCCESS = 'ADD_MARKETPLACE_POST_SUCCESS';
export const ADD_MARKETPLACE_POST_LOADING = 'ADD_MARKETPLACE_POST_LOADING';
export const ADD_MARKETPLACE_POST_FAILURE = 'ADD_MARKETPLACE_POST_FAILURE';
export const SET_FINAL_DATA = 'SET_FINAL_DATA';
export const SET_POST = 'SET_POST';

//carpool
export const ADD_CARPOOL_POST_SUCCESS = 'ADD_CARPOOL_POST_SUCCESS';
export const ADD_CARPOOL_POST_LOADING = 'ADD_CARPOOL_POST_LOADING';
export const ADD_CARPOOL_POST_FAILURE = 'ADD_CARPOOL_POST_FAILURE';

export const GET_ALL_POST_SUCCESS = 'GET_ALL_POST_SUCCESS';
export const GET_ALL_POST_LOADING = 'GET_ALL_POST_LOADING';
export const GET_ALL_POST_FAILURE = 'GET_ALL_POST_FAILURE';

export const GET_FLATRENTPOST_SUCCESS = 'GET_FLATRENTPOST_SUCCESS';
export const GET_FLATRENTPOST_LOADING = 'GET_FLATRENTPOST_LOADING';
export const GET_FLATRENTPOST_FAILURE = 'GET_FLATRENTPOST_FAILURE';

export const GET_ALL_LOCALITIES_SUCCESS = 'GET_ALL_LOCALITIES_SUCCESS';
export const GET_ALL_LOCALITIES_LOADING = 'GET_ALL_LOCALITIES_LOADING';
export const GET_ALL_LOCALITIES_FAILURE = 'GET_ALL_LOCALITIES_FAILURE';

export const GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING = '  GET_CLUB_DETAILS_WITH_CLUB_ID_LOADING';
export const GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE = '  GET_CLUB_DETAILS_WITH_CLUB_ID_FAILURE';
export const GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS = '  GET_CLUB_DETAILS_WITH_CLUB_ID_SUCCESS';

export const GET_TOTAL_PAGES_LOADING = 'GET_TOTAL_PAGES_LOADING';
export const GET_TOTAL_PAGES_SUCCESS = 'GET_TOTAL_PAGES_SUCCESS';
export const GET_TOTAL_PAGES_FAILURE = 'GET_TOTAL_PAGES_FAILURE';

export const GET_INACTIVE_ACTIVE_POST_LOADING = 'GET_INACTIVE_ACTIVE_POST_LOADING';
export const GET_INACTIVE_ACTIVE_POST_SUCCESS = 'GET_INACTIVE_ACTIVE_POST_SUCCESS';
export const GET_INACTIVE_ACTIVE_POST_FAILURE = 'GET_INACTIVE_ACTIVE_POST_FAILURE';

export const GET_CARPOOLPOST_LOADING = 'GET_CARPOOLPOST_LOADING';
export const GET_CARPOOLPOST_FAILURE = 'GET_CARPOOLPOST_FAILURE';
export const GET_CARPOOLPOST_SUCCESS = 'GET_CARPOOLPOST_SUCCESS';

export const GET_ALL_DEPT_LOCALITIES_LOADING = '  GET_ALL_DEPT_LOCALITIES_LOADING';
export const GET_ALL_DEPT_LOCALITIES_SUCCESS = '  GET_ALL_DEPT_LOCALITIES_SUCCESS';
export const GET_ALL_DEPT_LOCALITIES_FAILURE = '  GET_ALL_DEPT_LOCALITIES_FAILURE';

//Pollux
export const GET_POLLUX_LOADING = 'GET_POLLUX_LOADING';
export const GET_POLLUX_FAILURE = 'GET_POLLUX_FAILURE';
export const GET_POLLUX_SUCCESS = 'GET_POLLUX_SUCCESS';

//HrDashboard
export const GET_HRDASHBOARD_LOADING = 'GET_HRDASHBOARD_LOADING';
export const GET_HRDASHBOARD_FAILURE = 'GET_HRDASHBOARD_FAILURE';
export const GET_HRDASHBOARD_SUCCESS = 'GET_HRDASHBOARD_SUCCESS';

export const GET_REPORT_LOADING = 'GET_REPORT_LOADING';
export const GET_REPORT_FAILURE = 'GET_REPORT_FAILURE';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';

export const GET_AWARDS_REPORT_LOADING = 'GET_AWARDS_REPORT_LOADING';
export const GET_AWARDS_REPORT_SUCCESS = 'GET_AWARDS_REPORT_SUCCESS';
export const GET_AWARDS_REPORT_FAILURE = 'GET_AWARDS_REPORT_FAILURE';

export const GET_TOTAL_COUNTS_SUCCESS = 'GET_TOTAL_COUNTS_SUCCESS';

export const GET_SEARCH_REPORT_SUCCESS = 'GET_SEARCH_REPORT_SUCCESS';
export const GET_SEARCH_REPORT_LOADING = 'GET_SEARCH_REPORT_LOADING';
export const GET_SEARCH_REPORT_FAILURE = 'GET_SEARCH_REPORT_FAILURE';
export const RESET_REPORT = 'RESET_REPORT';

// Skills
export const GET_ALL_SKILLS_LOADING = 'GET_ALL_SKILLS_LOADING'
export const GET_ALL_SKILLS_SUCCESS = 'GET_ALL_SKILLS_SUCCESS'

export const GET_ALL_KEYTECH_LOADING ='GET_ALL_KEYTECH_LOADING'
export const GET_ALL_KEYTECH_SUCCESS= 'GET_ALL_KEYTECH_SUCCESS'