

import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';

//scss
import s from '../dashboard/dashboard.module.scss';
import postClass from '../dashboard/components/home/postContainer/postContainer.module.scss';
import peopleClass from '../dashboard/components/home/home.module.scss';

//layouts
import RightSidebar from '../dashboard/components/home/sidebar';
import Navbar from '../../layouts/layout1/navbar';
import Sidebar from '../../layouts/layout1/sidebar';
import Footer from '../../layouts/layout1/footer';
import SearchedPeople from '../dashboard/components/home/searched-people';
import Posts from '../dashboard/components/home/postContainer/posts';
import HRDashboardFile from './hrDashboardFile';

import {
  getCouncilMap,
} from '../../redux/actionCreators/hrdashboard.actionCreator';


// import { getCertiTechTypes } from '../../redux/actionCreators/profile/certification.actionCreator';

function HRDashboard(props) {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [inputChangeData, setInputChangeData] = useState({
    searchValue: '',
    isPost: false
  });
  const [inputVal, setInputVal] = useState('');
  const [toggle, setToggle] = useState(!isMobile);
  const [editOnePost, setEditOnePost] = useState(false);
  const [currentEditedPost, setCurrentEditedPost] = useState(0);
  const onChange = (value, isPost) => {
    setInputChangeData({ searchValue: value, isPost: isPost });
  };

  const debouncedOnChange = useCallback(_.debounce(onChange, 500), [onChange]);
  const activeUsers = props.totalUsers.filter(user => user.isActive);
  useEffect(() => {
    setToggle(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    props.getCouncilMap();
    // props.getCertiTechTypes();
  }, []);

  return (
    <div className={s.dashboard}>
      <section className={s.profileSection}>
        <Navbar
          setToggle={setToggle}
          toggle={toggle}
          profileDetails={props.profileDetails}
          onChange={debouncedOnChange}
          inputVal={inputVal}
          setInputVal={setInputVal}
        />
        <div className={s.clubsWrapper}>
          <Sidebar
            toggle={toggle}
            setToggle={setToggle}
            onChange={debouncedOnChange}
            inputVal={inputVal}
            setInputVal={setInputVal}
          />
          <div
            className={peopleClass.home}
            style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}
          >
            <div
              className={peopleClass.containerWrapper}
              style={{ flexGrow: 1, marginLeft: isMobile ? '0' : '200px' , padding: '20px' }}
             >
              {inputChangeData.searchValue.length ? (
                !inputChangeData.isPost ? (
                  <SearchedPeople searchValue={inputChangeData.searchValue} />
                ) : (
                  <div className={postClass.postContainer}>
                    <Posts
                      searchValue={inputChangeData.searchValue}
                      editOnePost={editOnePost}
                      setEditOnePost={(val) => setEditOnePost(val)}
                      currentEditedPost={currentEditedPost}
                      setCurrentEditedPost={(val) => setCurrentEditedPost(val)}
                    />
                  </div>
                )
              ) : (
                <HRDashboardFile
                  councilMap={props.councilMap}
                  totalUsers={activeUsers?.length || 0}
                  isMobile = {isMobile}
                  // techTypes={props.techTypes}
                />
              )}
            </div>
            {/* <div className={peopleClass.sidebarWrapper}>
              <RightSidebar />
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profileDetails: state.profileReducer.details,
    loading: state.clubsReducer.loading,
    error: state.clubsReducer.error,
    councilMap: state.hrDashboardReducer.councilMap,
    totalUsers: state.userReducer.users
    // techTypes: state.certificationReducer.techTypes
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getCouncilMap }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HRDashboard);
