import {
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAILURE,
  GET_PROJECTS_FAILURE,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_LOADING,
  GET_ALL_KEYTECH_LOADING,
  GET_ALL_KEYTECH_SUCCESS,
} from '../../actions/actions';

const initialState = {
  editLoading: false,
  editError: '',
  projects: [],
  internalProjects: [],
  getLoading: false,
  getError: '',
  addLoading: false,
  addError: '',
  allKeyTech:null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        getLoading: false,
        internalProjects: action.payload.internalProjects,
        getError: ''
      };
    case GET_PROJECTS_LOADING:
      return {
        ...state,
        getLoading: true
      };
    case GET_PROJECTS_FAILURE:
      return {
        ...state,
        getLoading: false,
        getError: action.payload.error
      };

    case EDIT_PROJECT_LOADING:
      return {
        ...state,
        editLoading: true
      };

    case EDIT_PROJECT_SUCCESS:
      let projectsCopy = {
        ...state.projects
      };
      projectsCopy.filter((project) => {
        if (project.projectId === action.payload.projects.projectId) {
          return action.payload.projects;
        }
        return project;
      });
      return {
        ...state,
        editLoading: false,
        projects: projectsCopy
      };

    case EDIT_PROJECT_FAILURE:
      return {
        ...state,
        editLoading: false,
        editError: action.payload.error
      };
      case GET_ALL_KEYTECH_LOADING:
        return {
          ...state,
          editLoading: true,
        };
      case GET_ALL_KEYTECH_SUCCESS:
        return {
          ...state,
          editLoading: false,
          allKeyTech: action.payload.allKeyTech,
        };
      
    default:
      return state;
  }
};

export default projectReducer;
