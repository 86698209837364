import axios from 'axios';
import {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE,
  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAILURE,
  ADD_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAILURE,
  GET_ALL_KEYTECH_LOADING,
  GET_ALL_KEYTECH_SUCCESS,
} from '../../actions/actions';
import { toast } from 'react-toastify';

export const getProjects = () => {
  return (dispatch) => {
    dispatch({
      type: GET_PROJECTS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/projects/by-empId', {
        headers: {
          accept: '*/*'
        },
        params: {
          empId: localStorage.getItem('id')
        }
      })
      .then((res) => {
        dispatch({
          type: GET_PROJECTS_SUCCESS,
          payload: {
            internalProjects: res.data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: GET_PROJECTS_FAILURE,
          payload: {
            err: 'Error'
          }
        });
      });
  };
};

export const editProject = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_PROJECT_LOADING
    });
    axios
      .put(
        process.env.REACT_APP_GEMBOOK_SVC_URL + '/projects',
        {
          projectDescription: data.projectDescription,
          projectName: data.projectName,
          projectStatus: data.projectStatus,
          projectType: data.projectType,
          keyTech: data.keyTech
        },
        {
          headers: {
            accept: '*/*'
          },
          params: {
            projectId: parseInt(data.projectId)
          }
        }
      )
      .then((res) => {
        dispatch({
          type: EDIT_PROJECT_SUCCESS,
          payload: {
            project: res.data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: EDIT_PROJECT_FAILURE,
          payload: {
            error: 'Error'
          }
        });
      });
  };
};

export const addProject = (data) => {
  const newData = [data];
  return (dispatch) => {
    dispatch({
      type: ADD_PROJECT_LOADING
    });
    return axios
      .post(process.env.REACT_APP_GEMBOOK_SVC_URL + '/projects', newData, {
        headers: {
          accept: '*/*'
        },
        params: {
          employeeId: localStorage.getItem('id')
        }
      })
      .then((response) => {
        if (response.status === 201) {
          dispatch({
            type: ADD_PROJECT_SUCCESS
          });
          toast.success('Project Saved Successfully!');
          getProjects()(dispatch);
        }
        return response;
      })
      .catch((error) => {
        dispatch({
          type: ADD_PROJECT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const deleteProject = (id) => {
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_GEMBOOK_SVC_URL + '/projects', {
        headers: {
          accept: '*/*'
        },
        params: {
          projectId: id
        }
      })
      .then((response) => {
        dispatch({
          type: DELETE_PROJECT_SUCCESS
        });
        toast.success(response.data);
        dispatch(getProjects());
      })
      .catch((error) => {
        dispatch({
          type: DELETE_PROJECT_FAILURE,
          payload: {
            error: error.message
          }
        });
        toast.error('Error: ' + error.message);
      });
  };
};

export const getAllKeyTech= () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_KEYTECH_LOADING
   
    });
   
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/projects/key-tech', {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        
        dispatch({
          type: GET_ALL_KEYTECH_SUCCESS,
          payload: {
            allKeyTech: res.data
          }
        });
      });
  };
};
