import {
  GET_ALL_USERS_FAILURE,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_LOADING,
  GET_ALL_MARKET_USERS_FAILURE,
  GET_ALL_MARKET_USERS_SUCCESS,
  GET_ALL_MARKET_USERS_LOADING
} from '../actions/actions';
import axios from 'axios';

export const getAllUsers = () => {

  return (dispatch) => {
    dispatch({
      type: GET_ALL_USERS_LOADING
    });
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile', {
        headers: {
          accept: '*/*'
        },
       
      })
      .then((res) => {
        const users = res.data;
        setTimeout(() => {
          dispatch({
            type: GET_ALL_USERS_SUCCESS,
            payload: {
              users: users
            }
          });
        }, 1000);
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_USERS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};

export const getMarketPlaceUsers = (email) => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_MARKET_USERS_LOADING
    });
    axios
      .get(
        `${
          process.env.REACT_APP_MARKETPLACE_URL
        }/getProfileByEmail/by-email?email=${encodeURIComponent(email)}`,
        {
          headers: {
            accept: '*/*'
          }
        }
      )
      .then((res) => {
        const usersProfile = res.data;
        setTimeout(() => {
          dispatch({
            type: GET_ALL_MARKET_USERS_SUCCESS,
            payload: {
              usersProfile: usersProfile
            }
          });
        }, 1000);
      })
      .catch((error) => {
        dispatch({
          type: GET_ALL_MARKET_USERS_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  };
};
