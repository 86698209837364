import React, { useState, useRef } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

// icons
import edit from '../../../../../assets/icons/editpost.svg';
import save from '../../../../../assets/icons/saveDetails.png';
import close from '../../../../../assets/icons/close.svg';
import addIcon from '../../../../../assets/icons/addIcon.svg';

// CSS
import s from './components.module.scss';
import cns from 'classnames';

//Helper
import { useDispatch, useSelector } from 'react-redux';
import { TOOLTIP_MSSG } from '../../../../../helpers';
import { Skeleton } from '@material-ui/lab';
import { editAboutme } from '../../../../../redux/actionCreators/profile/AboutMe.actionCreator';
import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';

const AboutMe = () => {
  const dispatch = useDispatch();
  const aboutMe = useSelector((state) => state?.profileReducer?.details?.aboutMe);
  const loading = useSelector((state) => state?.profileReducer?.loading);
  // const { isMobile } = Viewport();
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const [editFlag, setEditFlag] = useState(false);
  const [about, setAbout] = useState(aboutMe);
  const [error, setError] = useState('');
  const wrapper = useRef();

  const handleChange = (e) => {
    const value = e.target.value;
    if (value.length <= 1000) {
      setAbout(value);
      setError('');
    } else {
      setError('Word limit cannot be more than 1000');
    }
  };

  const handleSave = () => {
    if (about?.length > 1000) {
      toast.warning('Word limit cannot more than 1000');
      return;
    }
    const data = {
      aboutMe: about
    };
    editAboutme(data)(dispatch);
    setEditFlag(!editFlag);
  };

  const handleClose = () => {
    setAbout(aboutMe);
    setEditFlag(!editFlag);
  };

  const getHighlightedText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const validText = text.slice(0, maxLength);
    const excessText = text.slice(maxLength);
    return (
      <span>
        {validText}
        <span style={{ color: 'red' }}>{excessText}</span>
      </span>
    );
  };

  return (
    <div className={cns(s.aboutmeWrapper, isMobile ? s.skillResponsive : '')} ref={wrapper}>
      {editFlag ? (
        <div className={s.labelWrapper}>
          <div className={s.heading}>
            <h5> About</h5>
            <div className={s.buttonContainer}>
              <Tooltip title={TOOLTIP_MSSG.SAVE}>
                <button onClick={handleSave}>
                  <img src={save} alt="save Icon" />
                </button>
              </Tooltip>
              <Tooltip title={TOOLTIP_MSSG.CLOSE}>
                <button onClick={handleClose}>
                  <img alt="close Icon" src={close} />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className={s.description}>
            <textarea
              maxLength={1001}
              rows="4"
              cols="50"
              style={{
                resize: 'none', // Disables resizing
                height: '150px',
                overflowY: 'auto',
                padding: '10px',
                borderRadius: '6px'
              }}
              className={cns(s.textarea, s.editInput)}
              name="aboutMe"
              onChange={handleChange}
              value={about}
              type="text"
              id="aboutMe"
            />

            {error && (
              <div style={{ color: 'red', marginTop: '5px', fontsize: '11px' }}>{error}</div>
            )}
          </div>
          <div style={{textAlign:'right', width:'100%', paddingTop:'2px'}}>{about?.length}/1000</div>
        </div>
      ) : (
        <>
          <div className={cns(s.aboutmeWrapper, isMobile ? s.skillResponsive : '')}>
            <div className={s.heading}>
              <h5>About</h5>
              {about?.length ? (
                <Tooltip title={TOOLTIP_MSSG.EDIT}>
                  <div className={s.editIcon}>
                    <button onClick={() => setEditFlag(!editFlag)}>
                      <img src={edit} alt="edit Icon" width={18} height={18} />
                    </button>
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title={TOOLTIP_MSSG.ADD}>
                  <div className={s.editIcon}>
                    <button className={s.addButton} onClick={() => setEditFlag(!editFlag)}>
                      <img src={addIcon} alt="add Icon" />
                    </button>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className={s.description}>
              {loading ? (
                <>
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                  <Skeleton variant="text" className={s.textLoader} />
                </>
              ) : about?.length ? (
                <React.Fragment>
                  <div className={s.textContainer}>{getHighlightedText(about, 1000)}</div>
                </React.Fragment>
              ) : (
                <i className="text-secondary">
                  You can write about your years of experience, industry, or skills. People also
                  talk about their achievements or previous job experiences.
                </i>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AboutMe;
