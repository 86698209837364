import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// ActionCreators
import {
  getEvents,
  getOrganisers,
  getPastEvents
} from '../../../../../../redux/actionCreators/event.actionCreator';

// Components
import Details from './details';
import s from '../sidebar.module.scss';
import { Skeleton } from '@material-ui/lab';

const eventDuration = [
  { id: 1, name: 'Ongoing & Upcoming' },
  { id: 2, name: 'Past' }
];
// const e=this.props.events
class Display extends Component {
  constructor(props) {
    super(props);
    this.state = {
      togglePast: false,
      toggleToday: true,
      toggleDetailsUpcoming: null,
      toggleDetailsPast: null,
      events: [],
      reload: false,
      activeTab: eventDuration[0],  // Default to "Ongoing & Upcoming"
      editEventToggle: false,
      organisers: [],
      pageNum: 0,
      scrollPosition: 0
    };
    this.divRef = React.createRef();
  }

  componentDidMount() {
    this.props.getOrganisers();
    this.props.getEvents();  // Load Ongoing & Upcoming events
    this.props.getPastEvents();  // Load Past events

    // Checking if there are no "Ongoing & Upcoming" events and switch to "Past"
    setTimeout(() => {
      if (!this.props.events.length && this.props.pastEvents.length) {
        this.setState({ activeTab: eventDuration[1] });  // Switch to "Past" tab
      }
    }, 1000);  // Adding a slight delay to ensure events are loaded before the check
  }

  componentDidUpdate(prevProps) {
    // Check if new past events length conditions are met
    if (
      this.props.newPastEventsLength === 0 ||
      (prevProps.newPastEventsLength !== -1 && prevProps.newPastEventsLength < 5)
    ) {
      if (this.divRef.current) {
        this.divRef.current.scrollTop = this.state.scrollPosition;
        this.divRef.current.removeEventListener('scroll', this.trackScrolling);
      }
      return;
    }

    // Check if past events have changed
    if (prevProps.pastEvents !== this.props.pastEvents) {
      setTimeout(() => {
        if (this.divRef.current && this.state.activeTab.name === 'Past') {
          this.divRef.current.scrollTop = this.state.scrollPosition;
        }
      }, 0);
    }

    // Safely add the scroll event listener
    if (this.divRef.current && this.state.activeTab.name === 'Past') {
      this.divRef.current.removeEventListener('scroll', this.trackScrolling); // Remove existing listener
      this.divRef.current.addEventListener('scroll', this.trackScrolling); // Add new listener
    }
  }

  componentWillUnmount() {
    // Remove the scroll event listener on unmount
    if (this.divRef.current) {
      this.divRef.current.removeEventListener('scroll', this.trackScrolling);
    }
  }

  trackScrolling = () => {
    const eventsContainer = this.divRef.current;
    if (eventsContainer && !this.props.loading && this.isBottom(eventsContainer)) {
      this.setState({ scrollPosition: eventsContainer.scrollTop }, () => {
        this.handleloadPastEvents();
      });
    }
  };

  // Helper function to check if the user scrolled to the bottom
  isBottom(el) {
    const scrollableHeight = el.scrollHeight - el.clientHeight;
    const currentScrollPosition = el.scrollTop;
    // Check if we are within 30px of the bottom
    return currentScrollPosition >= scrollableHeight - 30;
  }

  refreshPage = () => {
    this.setState({ reload: true }, () => this.setState({ reload: false }));
  };

  handleTogglePast() {
    this.setState({ togglePast: !this.state.togglePast });
  }

  handleToggleToday() {
    this.setState({ toggleToday: !this.state.toggleToday });
  }

  changeTab(name) {
    this.setState({ activeTab: name });
  }

  handleloadPastEvents = () => {
    this.props.getPastEvents(this.state.pageNum + 1);
    this.setState({ pageNum: this.state.pageNum + 1 });
  };

  render() {
    const Output = ({ text }) => (
      <p style={{ fontSize: '0.75rem' }} className="text-center text-secondary pt-2">
        {text}
      </p>
    );

    const Heading = () => (
      <div className={s.eventTabs}>
        {eventDuration.map((duration, key) => {
          return (
            <div
              className={this.state.activeTab.name === duration.name ? s.activeTab : ''}
              key={key}
              onClick={() => this.changeTab(duration)}
            >
              {duration.name}
            </div>
          );
        })}
      </div>
    );

    const Events = () => {
      const { activeTab } = this.state;
      const { events, pastEvents, hrArr } = this.props;

      return (
        <div className="event-list" ref={this.divRef}>
          {activeTab.id === eventDuration[0].id && events.length ? (
            events.reverse().map((event, key) => {
              return <Details key={key} activeTab={activeTab} event={event} hrArr={hrArr} />;
            })
          ) : activeTab.id === eventDuration[1].id && pastEvents.length ? (
            pastEvents.map((event, key) => {
              return (
                <Details
                  key={key}
                  activeTab={activeTab}
                  event={event}
                  hrArr={hrArr}
                  isPastEvent={true}
                />
              );
            })
          ) : (
            <Output text={`No ${activeTab.name} Events available`}></Output>
          )}
        </div>
      );
    };

    return (
      <>
        <Heading />
        {this.props.loading &&
        ((this.state.activeTab.name === 'Past' && !this.props.pastEvents.length) ||
          (this.state.activeTab.name === 'Ongoing & Upcoming' && !this.props.events.length)) ? (
          <>
            {['', ''].map((args, index) => (
              <div key={index} className="d-flex align-items-center px-4 py-2">
                <Skeleton variant="circle" width={44} height={44} />
                <div className="w-75">
                  <Skeleton variant="rect" width="50%" height="10px" className="m-2" />
                  <Skeleton variant="rect" width="100%" height="10px" className="m-2" />
                  <Skeleton variant="rect" width="100%" height="10px" className="m-2" />
                </div>
              </div>
            ))}
          </>
        ) : (
          <Events />
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    events: state.eventReducer.events,
    loading: state.eventReducer.getLoading,
    error: state.eventReducer.getError,
    fetchAgain: state.eventReducer.fetchAgain,
    pastEvents: state.eventReducer.pastEvents,
    newPastEventsLength: state.eventReducer.newPastEventsLength,
    organisers: state.eventReducer.organisers
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvents: getEvents,
      getPastEvents: getPastEvents,
      getOrganisers: getOrganisers
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Display);
