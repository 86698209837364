import axios from 'axios';
import { toast } from 'react-toastify';
import {
  EDIT_SKILL_LOADING,
  EDIT_SKILL_SUCCESS,
  GET_ALL_SKILLS_LOADING,
  GET_ALL_SKILLS_SUCCESS
} from '../../actions/actions';

export const editSkill = (data) => {
  return (dispatch) => {
    dispatch({
      type: EDIT_SKILL_LOADING
    });
    axios
      .put(
        process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile',
        {
          skills: data.skills
        },
        {
          headers: {
            accept: '*/*'
          },
          params: {
            employeeId: localStorage.getItem('id')
          }
        }
      )
      .then((res) => {
        const details = res.data.userAddedSkills;
        toast.success('Skills updated successfully!');
        dispatch({
          type: EDIT_SKILL_SUCCESS,
          payload: {
            details: details
          }
        });
      });
  };
};

export const getAllSkills = () => {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_SKILLS_LOADING
   
    });
   
    axios
      .get(process.env.REACT_APP_GEMBOOK_SVC_URL + '/profile/skills', {
        headers: {
          accept: '*/*'
        }
      })
      .then((res) => {
        
        dispatch({
          type: GET_ALL_SKILLS_SUCCESS,
          payload: {
            allSkills: res.data
          }
        });
      });
  };
};
