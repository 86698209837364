import React, { useState, useEffect } from 'react';
import cns from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Components
import Logout from '../../../pages/auth/logout';

// Scss
import s from './profileCard.module.scss';

const ProfileCard = ({ details, onChange, users }) => {
  const [imageURL, setImageURL] = useState('');
  const history = useHistory();
  const userDetails = useSelector((state) => state.storageReducer.user);

  useEffect(() => {
    setImageURL(localStorage.getItem('imageURL'));
  }, [userDetails]);

  const profileRouting = () => {
    if (window.location.hash !== '#/profile') {
      history.push('/profile');
    }
    onChange('');
  };

  return (
    <div className={s.profileCard}>
      <div className={s.profile}>
        <div className={s.profileInfo}>
          <img
            referrerPolicy="no-referrer"
            className={s.profileImage}
            src={imageURL}
            alt="profile-img"
          />
          <div className="pl-2">
            <p className={s.name}>
              <b>{userDetails?.details?.name}</b>
            </p>
            <p className={s.designation}>{userDetails?.details?.designation}</p>
          </div>
        </div>
        <div className={cns('btn', s.viewProfileBtn)} onClick={profileRouting}>
          View Profile
        </div>
      </div>
      <hr className={s.hr} />
      <ul className={cns(s.dropdown)}>
        <li>
          <Logout />
        </li>
      </ul>
    </div>
  );
};
export default ProfileCard;
